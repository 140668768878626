import React, { useEffect, useState } from "react";
import "./Inventory.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
import InventoryForm from "./Inventoryform";
const InventoryTable = ({ garageList, load, fetchGarageList,count ,param}) => {
  console.log(garageList)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);
  const [id, setId] = useState("");
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList?.filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.chooseDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.chooseDate) <= new Date(filterCriteria.toDate);

        

        const searchMatch =
          !filterCriteria.search ||
          item.partName.toLowerCase().includes(filterCriteria.search.toLowerCase());

        return fromDateMatch && toDateMatch  && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.chooseDate) - new Date(a.chooseDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };


  // Calculate start and end index for visible rows
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  // Populate visibleRows array with data for the current page
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const modalV=(index)=>{
    setModal(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);

  }
  console.log(selectedGarage)
  const cancelV=()=>{
    setModal(false)
  }
  const modalX=(index)=>{
    setModalXV(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const cancelx=()=>{
    setModalXV(false)
  }


  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };
  


  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item?.chooseDate).format("DD-MM-YYYY");
      return [
        index + 1,
        item.garageName || "",

        item.partName || "",
        item.brandName || "",
        item.partNumber || "",
        item.rackNumber || "",
        item.vehicleType || "",
        item.sac_hsn || "",
        item.minimumNumber || "",
        item.maximumNumber || "",

        item.quantity|| "",
        item.rate || "",
        item.taxType || "",
        item.subTotal || "",
        item.tax || "",
        item.discount || "",
        item.totalAmounts || "",
      ];
    });
  
    // Add header row
    formattedData.unshift(["S.No","Garage Name", "Part Name", "Brand Name", "Part Number", "Rack Number","Vehicle Type","Sac_hsn","Min_Stocks","Max_Stocks","Quantity","Rate","Tax_type","SubTotal","Tax%","Discount%","Total Amount"]);
  
    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);
  
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Save the file
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };
  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };

  function cancel(e) {}

 console.log(count)
 console.log(filteredGarageList)

  return (
    <>

    {/* <Modal visible={modal} onCancel={cancelV}>
      <h1>ssdsds</h1>

    </Modal> */}
      

      <Spin spinning={load}>
        <InventoryForm onSearch={handleFilterChange} Csv={handleExportExcel} param={param} fetchGarageListed={fetchGarageList} />
        {!load && (
          <div className="inventory-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">GarageName</th>

                  <th className="th2">PartName</th>
                  <th className="th3">BrandName</th>
                  <th className="th4">PartNumber</th>
                  <th className="th4">RackNumber</th>


                  <th className="th5">VehicleType</th>
                  <th className="th6">Sac_hsn </th>
                  <th className="th4">Min Stocks</th>

                  <th className="th4">Max Stocks</th>

                  <th className="th7">Quantity </th>

                  <th className="th8">Rate &#x20b9; </th>

                  <th className="th9">TaxType</th>
                  <th className="th9">Subtotal &#x20b9;</th>

                  <th className="th9">Tax %</th>
                  <th className="th9">Discount %</th>
                  <th className="th9">TotalAmount</th>









                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Inventory List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.chooseDate).format(
                      "DD-MM-YYYY"
                    );

                    console.log(visibleRows)
                    

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        <td>{item.garageName?item.garageName:"-"}</td>

                        <td>{item.partName?item.partName:"-"}</td>
                        <td>{item.brandName?item.brandName:"-"}</td>

                        <td>{item.partNumber?item.partNumber:"-"}</td>

                        <td>{item.rackNumber}</td>

                        <td>{item.vehicleType===1?"Car":"Bike"}</td>

                        <td>{item.sac_hsn}</td>

                        <td>{item.minimumNumber}</td>

                        <td>{item.maximumNumber}</td>

                        <td>
                          {item.quantity}
                        </td>

                        <td>{item.rate}</td>

                        <td>{item.taxType}</td>

                        <td>{item.subTotal}</td>
                        <td>{item.tax}</td>

                        <td>{item.discount}</td>

                        <td>{item.totalAmount}</td>

                        
                        





             

                        
                   
                       
                        
                        
                  

                      </tr>

                      
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modal && (
                 <div className="add-inventory-manage-modal-overlay">
                 <div className="add-inventory-manage-modal-contents">
                   <div className="add-inventory-manage-modal-content-header">
                     <span
                       className="add-inventory-manage-close-button"
                       onClick={cancelV}
                     >
                       &times;
                     </span>
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"white" }}>Customer Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-inventory-manage-modal-forms"
                     style={{ width: 500,display:"flex",flexDirection:"row",}}
                   >
                    <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                     

                  
              
                   <div style={{textAlign:"",padding:"0px 20px 0px 20px"}}>
                  
                <div>

                <p style={{paddingLeft:27,fontWeight:"bold",marginBottom:"0.5em"}}>Booking Id:<span style={{fontSize:15,padding:20}}>{selectedGarage.bookingId}</span></p>

                        <p style={{paddingLeft:32,fontWeight:"bold",marginBottom:"0.5em"}}>Full Name:<span style={{fontSize:15,padding:20}} >{selectedGarage.customerName}</span></p>
      

      <p style={{paddingLeft:0,fontWeight:"bold",marginBottom:"0.5em"}}>Phone Number: <span style={{fontSize:15,padding:18}}>{selectedGarage.phone?selectedGarage.phone:"-"}</span></p>

      <p style={{paddingLeft:60 ,fontWeight:"bold",marginBottom:"0.5em"}}>Email: <span style={{fontSize:15,padding:17}}>{selectedGarage.email?selectedGarage.email:"-"}</span></p>

      
                 </div>
                 </div>

                 </div>
                 </div>
                 </div>
              
              )}
            </div>
            <div>
              {modalXV && (
                 <div className="add-inventory-manage-modal-overlay">
                 <div className="add-inventory-manage-modal-contents">
                   <div className="add-inventory-manage-modal-content-header">
                     <span
                       className="add-inventory-manage-close-button"
                       onClick={cancelx}
                     >
                       &times;
                     </span>
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"white" }}>vehicle Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-inventory-manage-modal-forms"
                     style={{ width: 500,padding:15}}
                   >
                    {/* <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                      */}

                  
                   </div>
                   <div style={{textAlign:"",padding:"0px 30px 0px 30px"}}>
                  
                <div>

                <p style={{paddingLeft:20,fontWeight:"bold"}}>Vehicle Number:<span style={{fontSize:15,padding:20}}>{selectedGarage?.vehicleNumber}</span></p>

                        <p style={{paddingLeft:83,fontWeight:"bold"}}>Brand:<span style={{fontSize:15,padding:20}} >{selectedGarage?.brand}</span></p>
      

      <p style={{paddingLeft:83,fontWeight:"bold"}}>Model: <span style={{fontSize:15,padding:18}}>Duke 200</span></p>

      <p style={{paddingLeft:93 ,fontWeight:"bold"}}>Year: <span style={{fontSize:15,padding:17}}>2024</span></p>
      <p style={{paddingLeft:58,fontWeight:"bold"}}>Fuel Type: <span style={{fontSize:15,padding:17}}>Petrol</span></p>

      
                 </div>
                 </div>

                 
                 </div>
                 </div>
              
              )}
            </div>

          
           
          </div>
          
        )}
         
         <Pagination
          onChange={(page) => onChange(page)}
          simple
          total={filteredGarageList.length}
          pageSize={itemsPerPage}
          current={currentPage}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default InventoryTable;
