import React, { useState, useEffect } from "react";
import "./category.css";
import { FaEdit, FaLock, FaLockOpen, FaPencilAlt } from "react-icons/fa";
import { Avatar, Popconfirm, Spin, message, Pagination } from "antd";
import CategoryForm from "./Categoryform";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";

const CategoryTable = ({ garageList, fetchGarageList, load }) => {
  console.log(garageList)
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [status, setStatus] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };
  console.log(garageList.length)


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList, currentPage, pageSize]);

  const applyFilters = () => {
    const filteredList = garageList
      .filter((item) => {
       
        const statusMatch =
          !filterCriteria.status || item.status === filterCriteria.status;

        const searchMatch =
          !filterCriteria.search ||
          item.stateName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase());

        return  statusMatch && searchMatch;
      })
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedList = filteredList.slice(startIndex, endIndex);

    setFilteredGarageList(paginatedList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };


  


  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
  };
console.log(garageList.length)
  return (
    <Spin spinning={load}>
      <div className="category-card">
        <CategoryForm onSearch={handleFilterChange} />

        <div className="category-table" onClick={handleOverlayClick}>
          {filteredGarageList.length === 0 ? (
            <p style={{ textAlign: "center" }}>No categories available.</p>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>State </th>
                    <th>Sub Name</th>

                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredGarageList.map((item, index) => {
                    
                    console.log(item)

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        
                        <td>{item?.stateName}</td>
                        <td>{item?.iso2}</td>


                        <td>
                          <span
                            className={`badge badge-${
                              !item.status ? "success" : "danger"
                            }`}
                          >
                            {!item.status ? "Active" : "Inactive"}
                          </span>
                        </td>
                        
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{ marginTop: "20px", float: "right" }}>
                <Pagination 
                simple
                  current={currentPage}
                  pageSize={pageSize}
                  total={garageList.length}
                  onChange={onPageChange}
                  onShowSizeChange={onPageSizeChange}
                />
              </div>
            </>
          )}

         
        </div>
      </div>
    </Spin>
  );
};

export default CategoryTable;
