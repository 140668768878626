import React, { useState } from "react";
import { Form, Input, Select, DatePicker, message, Modal } from "antd";
import "./feature.css";
import ApiDomain from "../../../api/ApiDomain";
const Option = Select.Option;

const AddPromoCode = ({ handleCloseModal, ListPromo,open }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    console.log(values)
    setLoading(true);
    const accessToken = localStorage.getItem("admin");

    // Extract only the date part
  
    try {
      // Make an API call
      const response = await fetch(`${ApiDomain.Domain}/adminFeatures`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({
          ...values,
          
        
        }),
      });

      if (response.ok) {
        // Handle successful API response
        console.log("API call successful");
        ListPromo();
        message.success("Feature added successfully");
        handleCloseModal();
      } else {
        // Handle API error
        console.error("API call failed");
        message.error("Error adding Feature");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };

  const discountValidationRules = {
    validator(_, value) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Discount  0% between 99%"));
    },
  };

 // Add other validation rules if needed for the "value" type

  const handlePromoCodeTypeChange = (value) => {

  };

  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal}>

      <div className="">
        <div className="">
          <div className="add-feature-modal-content-header">
           
            <div>
              <h3 style={{color:"#b11226",textAlign:"center",fontWeight:"bold"}}>Add Feature</h3>
            </div>
          </div>
          <div className="add-feature-modal-formed">
            <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
              
              
              <Form.Item name="featureName" label="Feature" required>
                <Input  />
              </Form.Item>
              <Form.Item name="endPoint" label="Endpoint" required>
                <Input  />

              </Form.Item>
              <p style={{textAlign:"center"}}>
                  <b>Note:</b>Spaces not use
                </p>
              <Form.Item name="source" label="Portal" required>
                <Select placeholder="Select Portal"
                  className="gx-mr-3 gx-mb-3"
                  onChange={handlePromoCodeTypeChange}
                >
                  <Option value="vrgarage">Vr Garage</Option>
                  <Option value="vecrep">Vecrep.com</Option>
                </Select>
              </Form.Item>
              <Form.Item name="icon" label="Icon" required>
                <Select placeholder="Select Icon"
                  className="gx-mr-3 gx-mb-3"
                  
                  onChange={handlePromoCodeTypeChange}
                >
                  <Option value="auth-screen">auth-screen</Option>
                  <Option value="dasbhoard">Dashboard</Option>
                  <Option value="crypto">Crypto</Option>
                  <Option value="listing-dbrd">listing-dbrd</Option>
                 




                </Select>
              </Form.Item>
              
           
            

              <div className="add-feature-popup-footer">
                <button
                  className="cancel-button"
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button className="submit-button" htmlType="submit" loading={loading}>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddPromoCode;
