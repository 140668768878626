import React, { useState } from "react";
import "./Auth.css";
import { Link, useHistory } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import ApiDomain from "../api/ApiDomain";

const SignIn = ({ setToken }) => {
  const [email, setEmail] = useState("");
  const [passWord, setPassWord] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email, passWord);
    fetch(`${ApiDomain.Domain}/adminSignIn`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email,
        passWord,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "userLogin");
        if (data.message === "give admin registation email") {
          setErrMsg("give admin registation email");
        } else if (data.message === "require passWord") {
          setErrMsg("require passWord");
        } else if (data.message === "give the correct passWord") {
          setErrMsg("Incorrect Password or Email ID");
        } else if (data.message === "admin signin successfully") {
          localStorage.setItem("admin", data.token);
          history.push("/admin/dashboard");
          localStorage.setItem("tab1", 1);
          setisLoggedIn(true);
        } else {
          alert("Something went wrong");
        }
      });
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <p className={errMsg ? "errMSG" : "offscreen"} aria-live="assertive">
          {" "}
          {errMsg}
        </p>
        <div className=".login-content-logo">
          <img
            src="https://admin.vecrep.com/assets/images/login-logo.png"
            alt="Logo-image"
          />
        </div>
        <div className="login-content-header">
          <h1>Login</h1>
        </div>
        <div className="login-form-container">
          <form onSubmit={handleSubmit} className="form-control">
            <div className="login-input">
              <label htmlFor="email">Email id</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email Address"
                className="login-input-email"
                required
              />
            </div>
            <div className="login-input">
              <label htmlFor="password">Password</label>
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={passWord}
                  onChange={(e) => setPassWord(e.target.value)}
                  placeholder="Enter Password"
                  className="login-input-email"
                  required
                />
                {showPassword ? (
                  <EyeInvisibleOutlined
                    className="password-toggle-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <EyeOutlined
                    className="password-toggle-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>

            <div>
              <div className="login-forgot">
                <Link to="/forgotpassword" className="forgot-btn">
                  Forgot Password ?{" "}
                </Link>
              </div>
              <button type="submit" className="login-submit-button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
