import React, { useEffect, useState } from "react";
import "./Subadmin.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import EditCategory from "./EditCategory";
import moment from "moment";
import CategoryForm from "./Categoryform";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
const CategoryTable = ({ garageList, load, fetchGarageList }) => {
  console.log(garageList)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState([]); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 5; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: false,
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList
      .filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.registationDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.registationDate) <= new Date(filterCriteria.toDate);

        const statusMatch =
          !filterCriteria.status || (item.status ? "active" : "inactive") === filterCriteria.status;

        const searchMatch =
          !filterCriteria.search ||
          item.name.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          (item.email &&
            item.email.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
            (item.subAdminId &&
              item.subAdminId.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
              (item.role &&
                item.role.toLowerCase().includes(filterCriteria.search.toLowerCase()))
        console.log(statusMatch);

        return fromDateMatch && toDateMatch && statusMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.registationDate) - new Date(a.registationDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const handleOpenModal = (index) => {
    console.log(index)
    setIsModalVisible(true);
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    console.log("datsggg",visibleRows[index])

  };
  console.log(selectedGarage)

  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };


  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item?.createdAt).format(
        "DD-MM-YYYY"
      );
      return [
        index + 1,
        formattedDate || "-",
        item.name || "-",
        item.userId || "-",
        item.email || "-",
        item.role || "-",
        item.status ? "Active" : "Inactive" || "-", 
      ];
    });
  
    // Add header row
    formattedData.unshift(["S.No", "RegisterDate", "SubAdmin Name", "SubAdminID", "Email","Role","Status"]);
  
    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);
  
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Save the file
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };
  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };
  function cancel(e) {}

  async function confirm(e,  newStatus,subAdminId) {
    console.log(newStatus)
    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/subAdmin/changeStatus`,
        { subAdminId,newStatus},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchGarageList();
        message.success("Status Change Success");
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      {modal && (
        <div className="add-garage-manage-modal-overlay">
          <div className="add-garage-manage-modal-contents">
            <div className="add-garage-manage-modal-content-header">
              <div>
                <h3 style={{ color: "white" }}>SubAdmin Status</h3>
              </div>
            </div>
            <div className="add-category-modal-form">
              <Spin spinning={""}>
             
              </Spin>
            </div>
          </div>
        </div>
      )}

      <Spin spinning={load}>
        <CategoryForm onSearch={handleFilterChange} Csv={handleExportExcel} />
        {!load && (
          <div className="garage-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th5">SubAdmin Name</th>
                  <th className="th3">Register Date</th>
                  <th className="th4">SubAdmin ID</th>
                  <th className="th6">Email</th>
                  <th className="th5">Role</th>


                  <th className="th10">Status</th>
                  <th className="th11">Action</th>
                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No garage found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.createdAt).format(
                      "DD-MM-YYYY"
                    );
                    

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        
                        <td>{item.name}</td>

                        <td>{formattedDate}</td>
                        <td>{item.userId}</td>


                        <td>{item.email}</td>
                        <td>{item.role}</td>

                        
                       
                        <td>
                          <span
                          className={`badge badge-${ item.status ? 'success' : 'danger'}`}
                          // onClick={() => activeStatus(item)}
                          >
                            {item.status ? "Active" : "InActive"}
                          </span>
                        </td>
                        <td>
                          <div className="action-cell">
                            <div
                              className="edit-cell"
                              onClick={() => handleOpenModal(index)}
                            >
                              <FaPencilAlt />
                            </div>
                          </div>
                        </td>

                        <td>

<Popconfirm
 title={`Are you sure ${
  !item.status ? 'Active' : 'InActive'
 } this SubAdmin?`}    onConfirm={(e) => confirm(e, (!item.status), item.id)}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
  <div className="action-cell">
    <div className="edit-cell">
   {item.status? <FaLockOpen />: <FaLock />}
    </div>
  </div>
  </Popconfirm>
</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            <div>
              {isModalVisible && (
                <EditCategory

                  handleCloseModal={handleCloseModal}
                  open={true}
                  selectedGarage={selectedGarage}
                  fetchGarageList={fetchGarageList} // Pass the selected garage details as props
                />
              )}
            </div>
           
          </div>
          
        )}
         <Pagination
              onChange={onChange}
              simple
              current={currentPage}
              total={filteredGarageList.length}
              pageSize={itemsPerPage}
              style={{ marginTop: 10, float: "right" }}
            />
      </Spin>
    </>
  );
};

export default CategoryTable;
