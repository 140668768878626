import React, { useEffect, useState } from "react";
import "./Notifications.css";
import NotificationForm from "./NotificationForm";
import NotificationTable from "./NotificationTable";
import SendNotification from "./SendNotification";

const Notifications = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiData, setapiData] = useState([]);
  const [error, setError] = useState("");

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-notifications-modal-overlay")) {
      handleCloseModal();
    }
  };

  return (
    <div className='notifications-container' onClick={handleOverlayClick}>
      <div className='notifications-content'>
        <div className='notifications-header'>
          <h3 className='notifications-head'>Notifications</h3>
          <div>
            <button className='add-notifications' onClick={handleOpenModal}>
              Send Notification
            </button>
          </div>
        </div>
        <div className='notifications-card'>
          <NotificationForm />
          <NotificationTable />
        </div>
      </div>
      <div>
        {isModalVisible && (
          <SendNotification handleCloseModal={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default Notifications;
