import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import configureStore, { history } from "./appRedux/store/index";
import App from "./containers/App/index";
import { AuthProvider } from "./authentication";
require("dotenv").config();

const store = configureStore(/* provide initial state if any */);

const NextApp = () => (
  <>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AuthProvider>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </AuthProvider>
      </ConnectedRouter>
    </Provider>
  </>
);

export default NextApp;
