import React, { useState, useEffect } from "react";
import "./Garage.css";
import { FaSearch, FaTimes} from "react-icons/fa";
import { Popover } from "antd";
import { TbFileExport } from "react-icons/tb";
import { MdClear } from "react-icons/md";
const CategoryForm = ({ onSearch, ExportCsv }) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const [minimumToDate, setMinimumToDate] = useState(null);
  const role=localStorage.getItem("role")
console.log(role)
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedFromDate(selectedDate);
    setDisableToDate(false);
    setMinimumToDate(selectedDate);
    const filterCriteria = {
      fromDate: selectedDate,
      toDate: selectedToDate,
      status: status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleToDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedToDate(selectedDate);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedDate,
      status: status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status: e.target.value,
      search: searchTerm,
    };
    onSearch(filterCriteria);

  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status: status,
      search: e.target.value,
    };
    onSearch(filterCriteria);
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm(""); // Clear the search term
    setDisableToDate(true);
    setMinimumToDate(null);
    console.log("Cleared search term:", searchTerm); // Log searchTerm after clearing

    const filterCriteria = {
      fromDate: "",
      toDate: "",
      status: "",
      search: "",
    };
    onSearch(filterCriteria);
  };
  useEffect(() => {
    // Your effect logic here
    setSearchTerm("")
    console.log("Search term changed:", searchTerm);
  }, [searchTerm]); // Include searchTerm in the dependency array
  



  return (
    <div>
      <div className="garage-manage-card-header">
        <form className="garage-manage-form-control">
          <div className="garage-manage-input garage-man">
            {/* <label htmlFor="fromdate" style={{ fontWeight: "bold" }}>
              From Date
            </label> */}
            <input 
              type="date"
              id="fromdate"
              name="fromdate"
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder="From Date"
              className="garage-manage-input-cal "
            />
          </div>
          <div className="garage-manage-input garage-man">
            {/* <label htmlFor="todate" style={{ fontWeight: "bold" }}>
              To Date
            </label> */}
            <input
              type="date"
              id="todate"
              name="todate"
              value={selectedToDate}
              onChange={handleToDateChange}
              min={minimumToDate}
              placeholder="To Date"
              className="garage-manage-input-cal"
              disabled={disableToDate}
              title={disableToDate ? "Please select From Date first" : ""}
            />
          </div>
          <div className="garage-manage-input garage-man">
            {/* <label htmlFor="status" style={{ fontWeight: "bold" }}>
              Garage Status
            </label> */}
            <select
              value={status}
              name="status"
              onChange={handleStatusChange}
              className="garage-manage-input-cal"
            >
              <option value="" disabled>
                Choose an option
              </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="garage-manage-input garage-man1">
            {/* <label htmlFor="search" style={{ fontWeight: "bold" }}>
              Search
            </label> */}
            <input 
              type="search"
              name="search"
              placeholder="Search..."
              className="search-input"
              onChange={handleSearchChange}
            />
          </div>
          {/* <div>
            <Popover content={"Search"} trigger="hover">
              <button
                type="button"
                className="garage-manage-form-button"
                onClick={handleSubmit}
              >
                <SearchOutlined />
              </button>
            </Popover>
          </div> */}
          <div>
            <Popover content={"Clear Filters"} trigger="hover">
              <button
                type="button"
                className="garage-manage-form-button"
                onClick={handleClear}
              >
                <MdClear />
              </button>
            </Popover>
          </div>
          {role ==="admin" &&(
          <div onClick={ExportCsv} >
            <Popover content={"Export CSV"} trigger="hover">
              <button type="button" className="garage-manage-form-excel">
                <TbFileExport />
              </button>
            </Popover>
          </div>
          )
}
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
