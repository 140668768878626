import React, { useEffect, useState } from "react";
import "./feature.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CategoryForm from "./Categoryform";
import CategoryTable from "./Categorytable";
import AddCategory from "./AddPromoCode";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";

const Features = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const ListPromo = () => {
    const apiUrl = `${ApiDomain.Domain}/adminFeatures`;
    const authToken = localStorage.getItem("admin"); // Replace with your actual authentication token

    // Make a GET request using the Fetch API with the token in the headers
    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        // Other headers if needed
      },
    })
      .then((response) => {
        // Check if the request was successful (status code 200)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON in the response
        return response.json();
      })
      .then((data) => {
        // Handle the data from the API
        setData(data.data);
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        // setError(error.message);
      });
  };

  useEffect(() => {
    ListPromo();
  }, []);
  return (
    <div className='feature-container' onClick={handleOverlayClick}>
      <div className='feature-content'>
        <div className='feature-header'>
          <h3 className='feature-head' style={{fontWeight:"bold"}}>Features</h3>
          <ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>ListPromo()} />

          <button className='add-feature ' onClick={handleOpenModal}>
            Add Feature
          </button>
        </div>
        <div className='feature-card'>
          <CategoryTable data={data} ListPromo={ListPromo}/>
        </div>
      </div>
      <div>
        {isModalVisible && (
          <AddCategory open={isModalVisible}
            handleCloseModal={handleCloseModal}
            data={data}
            ListPromo={ListPromo}
          />
        )}
      </div>
    </div>
  );
};

export default Features;
