import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Form, Col, Row, Button, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./category.css";

const { Option } = Select;

const AddCategory = ({ handleCloseModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loadingBike, setLoadingBike] = useState(false);
  const [imageUrlBike, setImageUrlBike] = useState();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChangeBike = (info) => {
    if (info.file.status === "uploading") {
      setLoadingBike(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoadingBike(false);
        setImageUrlBike(url);
      });
    }
  };

  const handleChangeCar = (info) => {
    if (info.file.status === "uploading") {
      setLoadingCar(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoadingCar(false);
        setImageUrlCar(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted!");
  };

  const handleCancel = () => {
    // Handle cancel logic here
    console.log("Form cancelled!");
    handleCloseModal();
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <>
      <div className="add-category-modal-overlay">
        <div className="add-category-modal-content">
          <div className="add-category-modal-content-header">
            <span
              className="add-category-close-button"
              onClick={handleCloseModal}
            >
              &times;
            </span>
            <div>
              <h3>Add Category</h3>
            </div>
          </div>
          <div className="add-category-modal-form">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              style={{
                minWidth: 600,
              }}
              scrollToFirstError
              onFinish={handleSubmit}
            >
              <Form.Item name="carImage" label="Car Category Image : ">
                <Upload
                  name="car-avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  beforeUpload={beforeUpload}
                  onChange={handleChangeCar}
                >
                  {imageUrlCar ? (
                    <img
                      src={imageUrlCar}
                      alt="car-avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item name="bikeImage" label="Bike Category Image : ">
                <Upload
                  name="bike-avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  beforeUpload={beforeUpload}
                  onChange={handleChangeBike}
                >
                  {imageUrlBike ? (
                    <img
                      src={imageUrlBike}
                      alt="bike-avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>

              <Form.Item name="mainCategoryName" label="Main Category Name">
                <TextArea placeholder="Enter Main Category Name" rows={3} />
              </Form.Item>

              <Form.Item
                name="mainCategoryDescription"
                label="Main Category Description"
              >
                <Input.TextArea
                  placeholder="Enter Main Category Description"
                  rows={4}
                />
              </Form.Item>
              <div className="add-category-popup-footer">
                <button
                  className="cancel-button"
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </button>
                <button className="submit-button" htmlType="submit">
                  Submit
                </button>
              </div>
            </Form>
          </div>

          {/* <Upload
            name='avatar'
            listType='picture-card'
            className='avatar-uploader'
            showUploadList={false}
            action='https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188'
            beforeUpload={beforeUpload}
            onChange={handleChange}>
            {imageUrl ? (
              <img
                src={imageUrl}
                alt='avatar'
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload> */}
        </div>
      </div>
    </>
  );
};

export default AddCategory;
