import React, { useState } from "react";
import "./category.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CategoryForm from "./Categoryform";
import CategoryTable from "./Categorytable";
import AddCategory from "./Addcategory"

const CustomerManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-category-modal-overlay")) {
      handleCloseModal();
    }
  };
  return (
    // <div className='category-container' onClick={handleOverlayClick}>
    //   <div className='category-content'>
    //     <div className='category-header'>
    //       <h3 className='category-head'>Category Management</h3>
    //       <button className='add-category' onClick={handleOpenModal}>
    //         Add Category
    //       </button>
    //     </div>
    //     <div className='category-card'>
    //       <CategoryForm />
    //       <CategoryTable />
    //     </div>
    //   </div>
    //   <div>
    //     {isModalVisible && <AddCategory handleCloseModal={handleCloseModal} />}
    //   </div>
    // </div>
    <h1>Under Working</h1>
  );
};

export default CustomerManagement;