import React, { useEffect, useState } from "react";
import "./SubCategory.css";
import {
  FaEdit,
  FaLock,
  FaLockOpen,
  FaPencilAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { Table, Tag, Pagination, Avatar, Spin, message, Popconfirm } from "antd";
import { DeleteOutlined, EditFilled, EditOutlined } from "@ant-design/icons";
import EditSubCategory from "./EditSubCategory";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import SubCategoryForm from "./SubCategoryForm";
import moment from "moment";

const SubCategoryTable = ({ apiData, fetchData, load }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const pageSize = 10; // Number of rows per page
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [carCategoryImage, setCarCategoryImage] = useState("");
  const [included, setIncluded] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [garageList, setGarageList] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [status,setStatus]=useState(null)
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
    selectedVehicle: "",
  });

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const fetchServices = () => {
    fetchData();
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const handleOpenModal2 = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal2 = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick2 = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("Delete-subcategory-modal-overlay")) {
      handleCloseModal2();
    }
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  const token = localStorage.getItem("admin");
  console.log(token)

  const fetchGarageList = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/CategoryAdminList`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data);
      setGarageList(response?.data?.categories);
    } catch (error) {
      console.log(error.message);
    }

  };

  useEffect(() => {
    fetchGarageList();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, apiData]);

  const applyFilters = () => {
    const filteredList = apiData?.filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.createdAt) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.createdAt) <= new Date(filterCriteria.toDate);

        const statusMatch =
          !filterCriteria.status || item.status === filterCriteria.status;

        const vehicleMatch =
          !filterCriteria.selectedVehicle ||
          item.vehicleType == filterCriteria.selectedVehicle;

        console.log(vehicleMatch);

        const searchMatch =
          !filterCriteria.search ||
          item.serviceName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase()) || item.categoryName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase())  ;

        return (
          fromDateMatch &&
          toDateMatch &&
          statusMatch &&
          searchMatch &&
          vehicleMatch
        );
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    setFilteredGarageList(filteredList);
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  // Columns configuration
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const rows =
    filteredGarageList && filteredGarageList?.length > 0 ? (
      filteredGarageList.slice(startIndex, endIndex)?.map((row, index) => {
        const categoryName = garageList.find(
          (item) => item._id === row.categoryId
        )?.categoryName;
        const formattedDate = moment(row.createdAt.slice(0, 10)).format(
          "DD-MM-YYYY"
        );
        const words = row.serviceName.split("");

        
  async function confirm(e, id, newStatus) {
    if (e) {
      e.preventDefault();
    }

    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/ChangeServiceAdminStatus`,
        { serviceId:id, status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchData()
        message.success(`Service is now ${newStatus ? 'Active' : 'Inactive'}`);
        setStatus(newStatus);
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  async function confirm1(e, id, newStatus) {
    if (e) {
      e.preventDefault();
    }

    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/deleteServiceAdmin`,
        { serviceId:id, deleteStatus: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchData()
        message.success(`Service is Delete Successfully`);
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  function cancel(e) {}

        return (
          <tr key={row._id}>
            <td id="serial">{index + 1}</td>
            <td>
              <div className="subcategory-table-image">
                {row.serviceImage ? (
                  <img src={row.serviceImage} alt="" />
                ) : (
                  <Avatar
                    shape="square"
                    size={40}
                    style={{
                      backgroundColor: "#b11226",
                      fontWeight: 600,
                      fontSize: 17,
                    }}
                  >
                    {words.length > 1
                      ? words[0]?.charAt(0)?.toUpperCase() +
                        words[1]?.charAt(0)?.toUpperCase()
                      : words[0]?.charAt(0)?.toUpperCase()}
                  </Avatar>
                )}
              </div>
            </td>
            <td>{formattedDate}</td>
            <td>{row?.categoryName}</td>
            {/* <td>{row?.subCategoryName ? row.subCategoryName : "-"}</td> */}
            <td>{row?.serviceName}</td>
            <td>{row?.vehicleType === 1 ? "Car" : "Bike"}</td>
           
            <td>
                     
                    
                        <span
                          className={`badge badge-${ row.status ? 'success' : 'danger'}`}
                        >
                          { row.status ? 'Active' : 'Inactive'}
                        </span>
                    </td>
            <td>
              <div className="action-cell">
                <div
                  className="edit-cell"
                  onClick={() => {
                    setCategoryName(categoryName);
                    setCarCategoryImage(row?.serviceImage);
                    setServiceName(row?.serviceName);
                    setVehicleType(row?.vehicleType);
                    setCategoryId(row?.categoryId);
                    setServiceId(row?._id);
                    handleEdit();
                  }}
                >
                  <FaPencilAlt />
                </div>
                <div                   className="edit-cell">

                <Popconfirm
                        title={`Are you sure ${
                          !row.status ? 'Activate' : 'Deactivate'
                        } this Service?`}
                        onConfirm={(e) => confirm(e, row._id,  !row.status)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No">
   {row.status?<FaLockOpen />: <FaLock />}
                          </Popconfirm>
                          </div>
{/*                
                <div
                  className="edit-cell">
                  <Popconfirm
                  title={`Are you Delete this Service`}
                  onConfirm={(e) => confirm1(e, row._id,  !row.deleteStatus)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No">
              
<DeleteOutlined />
</Popconfirm> 
               </div> */}
              </div>
            </td>
          </tr>
          
        );
      })
    ) : (
      <div style={{marginTop:10,textAlign:"center"}}>
        <p colSpan="10" style={{ textAlign: "center" }}>
          No Services available.
        </p>
      </div>
    );


console.log(garageList)

  return (
    <Spin spinning={load}>
      <div className="subcategory-card">
        <SubCategoryForm onSearch={handleFilterChange} />
        <div className="subcategory-table" onClick={handleOverlayClick}>
          <table>
          <thead>
              <tr>
                <th>S.No</th>
                <th>Service Image</th>
                <th>Created</th>
                <th>Category Name</th>
                <th>Service Name</th>
                <th>Vehicle Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>

          
        </div>

        {isModalVisible && (
          <EditSubCategory
            handleCloseModal={handleCloseModal}
            open={isModalVisible}
            vehicle={vehicleType}
            categoryName={categoryName}
            serviceImage={carCategoryImage}
            categoryId={categoryId}
            serviceName={serviceName}
            serviceId={serviceId}
            fetchServices={fetchServices}
          />
        )}
      </div>
      <Pagination
            onChange={onChange}
            simple
            total={apiData.length}
            current={currentPage}
            pageSize={pageSize}
            style={{ marginTop: 10, float: "right" }}
          />
    </Spin>
  );
};

export default SubCategoryTable;
