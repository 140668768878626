import React, { useEffect, useState } from "react";
import { CloudUploadOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Form, Col, Row, Button, Input, Select ,Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./category.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import Item from "antd/lib/list/Item";

const AddCategory = ({ handleCloseModal, fetchGarageList,open }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loadingBike, setLoadingBike] = useState(false);
  const [imageUrlBike, setImageUrlBike] = useState();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [error, setError] = useState("");
  const [state,setState]=useState([])
  const [city,setCity]=useState([])
  const[id,setId]=useState("")
const token=localStorage.getItem("admin")
  const { Option } = Select;
  const fetchServiceStates = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfStates`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data)
      setState(response?.data?.data)
    } catch (error) {
      console.log(error.message);
    }
  };
  const fetchCities = async (id) => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfCities/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data)
      setCity(response?.data?.data)
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchServiceStates();
  }, []);



 



  const handleSubmit = async (values) => {
    

    const token = localStorage.getItem("admin");
    const requestData = {
      ...values,
      country:"IN",
      latitude:"40.7128",
      longitude:"-74.0060",
    };
    try {
      const response = await fetch(
        `${ApiDomain.Domain}/addCity`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );
        const data = await response.json();
console.log(data.responseCode)
      if (data.responseCode===200) {
        console.log("API response:", data);
        message.success("City Added Successfully");
        handleCloseModal();
      } else {
        console.error("Error fetching data from the API");
        message.warning(data.message)
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error);
    }
  };

  const handleCancel = () => {
    // Handle cancel logic here
    console.log("Form cancelled!");
    handleCloseModal();
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const handleVehicleChange = (id) => {
    fetchCities(id)
    setId(id)
    // You can perform additional actions based on the selected value
  };

  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal} width={"auto"}>
      <div className=''>
        <div className=''>
          <div className='add-category-modal-content-header'>
            
            <div>
              <h3 style={{ color: "#b11226" }}>Add City</h3>
            </div>
          </div>
          <div className='add-category-modal-form'>
            <Form
              {...formItemLayout}
              form={form}
              name='register'
              style={{
                minWidth: 600,
              }}
              scrollToFirstError
              onFinish={handleSubmit}>
              <Form.Item
                name='stateId'
                label='State Name'
                rules={[
                  {
                    required: true,
                    message: "Please Select State",
                  },
                ]}>
                <Select
                  showSearch
                  style={{ height: 40 }}
                  placeholder='Select State'
                  optionFilterProp='children'
                  onChange={handleVehicleChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                    { state.map((item,index)=>(
                  <Option value={item._id}>{item.stateName}</Option>
                    ))}
                </Select>
              </Form.Item>
              
             < Form.Item
                name='cityName'
                label='City'
                rules={[
                  {
                    required: true,
                    message: "Please Select City",
                  },
                ]}>
                  <Input placeholder="Enter City Name"/>
               
              </Form.Item>
              
              <div className='add-category-popup-footer'>
                <button
                  className='cancel-button'
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}>
                  Cancel
                </button>
                <button className='submit-button' htmlType='submit'>
                  Submit
                </button>
              </div>
            </Form>
          </div>

        
        </div>
      </div>
    </Modal>
  );
};

export default AddCategory;
