import React, { useEffect, useState } from "react";
import "./servicePage.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import EditCategory from "./servicePageedit";
import moment from "moment";
import CategoryForm from "./servicePageform";
import {
  Avatar,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Spin,
  message,
  Table,
  Select,
  Space,
  Button
} from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CategoryTable = ({ load,fetchGarageList,garageList,loads,list }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details
  const [form] = Form.useForm();

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [active, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const[date,setDate]=useState("")
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [count,setCount]=useState(1)
  const [apiData,setapiData]=useState([])
  const [location,setLocation]=useState([])
  const [area,setArea]=useState([])

  const [inital,setInitial]=useState(false)
  const [inital1,setInitial1]=useState(false)


  const [serviceList,setServiceList]=useState([])
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });
  const {Option}=Select;
  const history=useHistory();

 

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList?.filter((item) => {

        const formattedDate = moment(item?.registationDate).format(
          "DD-MM-YYYY"
        );
      
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.registationDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.registationDate) <= new Date(filterCriteria.toDate);

        const statusMatch =
          !filterCriteria.status ||
          (item.status ? "active" : "inactive") === filterCriteria.status;

        const searchMatch =
          !filterCriteria.search ||
          item.title
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase());
        console.log(statusMatch);
        setDate(formattedDate)


        return fromDateMatch && toDateMatch && statusMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.registationDate) - new Date(a.registationDate);
      });
     


    setFilteredGarageList(filteredList);


  };


  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList?.slice(startIndex, endIndex);
  console.log(visibleRows)
  const handleOpenModal = (index) => {
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  };

  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
    setInitial(false)
    setInitial1(false)

  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };
  const Intial=(item)=>{
    setInitial(true)
    setSelectedGarage(item)
    console.log(item)
  }
  const Intial1=(item)=>{
    setInitial1(true)
    setSelectedGarage(item)
    console.log(item)
  }

  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleCancel = () => {
    setModal(false);
  }; const handleVehicleChange = (value) => {
    console.log(`Selected: ${value}`);
    // You can perform additional actions based on the selected value
  };
  const handleCityChange = (value) => {
    console.log(`Selected: ${value}`);
    fetchLocationArea(value)
    // You can perform additional actions based on the selected value
  };
  const activeStatus = (item) => {
    setId(item.garageId);
    setActive(!item.status);
    setModal(true);
  };
  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item?.registationDate).format("DD-MM-YYYY");
      return [
        index + 1,
        formattedDate || "",
        "-",
        item.garageName || "",
        item.email || "",
        item.phoneNumber || "",
        item.pancardNumber || "",
        "N/A",
      ];
    });
  
    // Add header row
    formattedData.unshift(["S.No", "RegisterDate", "GarageName", "Email", "PhoneNumber", "PAN Number", "GST Number", "Additional Variable"]);
  
    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);
  
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Save the file
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };

  const handleSubmit = async (values) => {
    console.log(values)
    try {
      loads(true)
      const response = await axios.post(`${ApiDomain.Domain}/getSEOdata`,
      {
        categoryType:values.categoryName?values.categoryName:"",
        serviceName:values.serviceName?values.serviceName:"",
        location:values.location?values.location:"",
        area:values.area?values.area:"",
        vehicleType:values.vehicleType?values.vehicleType:"",
          status: true,
          garageId: "",
          page: 0,
          pageCount: 1,
          pageType: "servicePage"


      
      },
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      if (response.status===200){
        loads(false)
        list(response.data.data)
        setCount(response.data.totalRecords)
        
      }
    } catch (error) {
      console.log(error.message);
    }
  };


const singleEdit=(data)=>{
  console.log(data)
  history.push(`/admin/vecrep/editservicepage/${data._id}`);
}
const seoEdit=(data)=>{
  console.log(data)
  history.push(`/admin/vecrep/seoeditservicepage/${data._id}`);
}

 
const fetchCategoryList = async () => {
  try {
    const response = await axios.get(
      `${ApiDomain.Domain}/CategoryAdminList`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response?.data);
    setServiceList(response?.data?.categories);
  } catch (error) {
    console.log(error.message);
  }
};

useEffect(() => {
  fetchCategoryList();
}, []);

const fetchServiceList = async () => {
  try {
    const response = await axios.get(
      `${ApiDomain.Domain}/adminServiceList`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response?.data);
    setapiData(response?.data?.data);
  } catch (error) {
    console.log(error.message);
  }
};
console.log(serviceList);

useEffect(() => {
  fetchServiceList();
}, []);

const fetchLocationList = async () => {
  try {
    const response = await axios.get(
      `${ApiDomain.Domain}/listOf_SEO_Data_cities

      `,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response?.data);
    setLocation(response?.data?.data);
  } catch (error) {
    console.log(error.message);
  }
};
const fetchLocationArea = async (id) => {
  try {
    const response = await axios.post(
      `${ApiDomain.Domain}/listOf_SEO_Data_aries`,
      {

        cityName:id
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response?.data);
    setArea(response?.data?.data);
  } catch (error) {
    console.log(error.message);
  }
};
useEffect(() => {
  fetchLocationList();
}, []);
async function confirm(e,id) {
  console.log(id)
  console.log(token)
 

  try {
    const response = await axios.delete(
      `${ApiDomain.Domain}/deletePage`,
      {
        data: { pageId:id }, // Pass id as part of the request body
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      fetchGarageList()
      message.success("Pages Delete Successfully");
      cancel();
    }
  } catch (error) {
    console.log(error.message);
  }
}

function cancel(e) {}


const [formData, setFormData] = useState({
  Basic: [
    { vehicleType: "Hatchback", price: 0 },
    { vehicleType: "Sedan", price: 0 },
    { vehicleType: "SUV", price: 0 },
    { vehicleType: "Luxury", price: 0 }
  ],
  Premium: [
    { vehicleType: "Hatchback", price: 0 },
    { vehicleType: "Sedan", price: 0 },
    { vehicleType: "SUV", price: 0 },
    { vehicleType: "Luxury", price: 0 }
  ],
  Gold: [
    { vehicleType: "Hatchback", price: 0 },
    { vehicleType: "Sedan", price: 0 },
    { vehicleType: "SUV", price: 0 },
    { vehicleType: "Luxury", price: 0 }
  ]
});





  return (
    <>
      
<Form
                  form={form}
                  layout="vertical"
                  style={{ minWidth: "600px" }}
                  onFinish={handleSubmit}
                >

<Space wrap>
<Form.Item
                              name="location"
                              
                            >
                              <Select
                                showSearch
                                style={{ height: 40,width:160 }}
                                placeholder="Select City"
                                optionFilterProp="children"
                                onChange={handleCityChange}
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                                                                          {location?.map((item,index) => (
    <Option key={index} value={item}>
      {item}
    </Option>
  ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="area"
                              
                            >
                              <Select
                                showSearch
                                style={{ height: 40,width:160 }}
                                placeholder="Select Area"
                                optionFilterProp="children"
                                onChange={handleVehicleChange}
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                                                                          {area?.map((item,index) => (
    <Option key={index} value={item}>
      {item}
    </Option>
  ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="vehicleType"
                            
                            >
                              <Select
                                showSearch
                                style={{ height: 40,width:160 }}
                                placeholder="Select Vehicle Type"
                                optionFilterProp="children"
                                onChange={handleVehicleChange}
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="1">
                                  Car
                                </Option>
                                <Option value="2">
                                  Bike
                                </Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="categoryName"
                              
                            >
                              <Select
                                showSearch
                                style={{ height: 40,width:160 }}
                                placeholder="Select Category"
                                optionFilterProp="children"
                                onChange={handleVehicleChange}
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                                           {serviceList?.map((item,index) => (
    <Option key={index} value={item?.categoryName}>
      {item?.categoryName}
    </Option>
  ))}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="serviceName"
                             
                            >
                              <Select
                                showSearch
                                style={{ height: 40,width:160 }}
                                placeholder="Select Service"
                                optionFilterProp="children"
                                onChange={handleVehicleChange}
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                               {apiData?.map((item,index) => (
    <Option key={index} value={item?.serviceName}>
      {item?.serviceName}
    </Option>
  ))}
                              </Select>
                            </Form.Item>
   <Form.Item>
    <Button htmlType="Submit" style={{marginTop:14}}>
    <SearchOutlined />
    </Button>
   </Form.Item>

   <Form.Item >
    <Input placeholder="Search By Name" type="search" style={{width:220}}        onChange={(e) => handleFilterChange({ ...filterCriteria, search: e.target.value })}
/>
   </Form.Item>

  </Space>






                </Form>
      <Spin spinning={load}>



        
        {!load && (
          <div className="page-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1" style={{}}>S.No</th>
                  <th className="th2" style={{}}>Page Name</th>
                  <th className="th3" style={{}}>Date of Published</th>
                  <th className="th4" style={{}}>Author</th>
                 
                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Pages found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.date).format(
                      "DD-MM-YYYY"
                    );


                    return (
                      <tr key={item.id}>
                        <td style={{}}>{startIndex + index + 1}</td>
                       
                        <td style={{}}>
  <div className="td-custom-div">
    <h1 className="td-custom-div">{item.title}</h1>
    <div>
      <ul style={{display:"flex",gap:10,padding:0}}>
        
         <h5 style={{color:"#55a630", fontWeight:"500",cursor:"pointer"}} onClick={(e)=>singleEdit(item)}> 
          Edit
          </h5>
          |
          <Popconfirm
                        title={`Are you sure Delete this Page?`}
                        onConfirm={(e) => confirm(e,item._id,)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No">
   <h5 style={{color:"#780000",fontWeight:"500",cursor:"pointer"}}> 
        Trash
          </h5>
                          </Popconfirm>
          |
          <h5 style={{ color: "#669bbc", fontWeight: "500" }}>
  <a href={item.url} target="_blank" rel="noopener noreferrer">View</a>
</h5>

          |
          <h5 style={{color:"#55a630",fontWeight:"500",cursor:"pointer"}} onClick={(e)=>seoEdit(item)}> 
          Seo Edit
          </h5>
                
                |
          <h5 style={{color:"blue",fontWeight:"500", cursor:"pointer"}} onClick={(e)=>Intial(item)}> 
          View Service
          </h5>
                |
                
      </ul>
    </div>
  </div>
</td>


                        <td style={{}}>{formattedDate}</td>
                        <td style={{}}>{item.createdBy}</td>
                        
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            

            {inital && (

<Modal footer={null} centered visible={inital} onCancel={handleCloseModal} width={"auto"}>
<div className="">
<div className="add-page-manage-modal-content-header">
<div>
  <h3 style={{ fontWeight: "bold",color:"#b11226" }}>Near Me Values</h3>
</div>
</div>
<div
className="add-page-manage-modal-forms"
style={{ maxHeight: "500px", width: 400 }}
>
 <div className="">
    <Form
      form={form}
      layout="vertical"
      style={{ minWidth: "300px" }}
      onFinish={handleSubmit}
      initialValues={true}

    >
     
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >

<div style={{ width: "100%" }}>
            <div className="select-extra">
              <Col xl={24} lg={24}>
                <Form.Item
                  name="title"
                  label="Title"
                   initialValue={selectedGarage?.title}
                 
                >
                  <Input type="text" placeholder="Page Title" disabled/>
                 
                </Form.Item>
              </Col>
            </div>
          </div>
        
          <div style={{ width: "100%" }}>
            <div className="select-extra">
              <Col xl={24} lg={24}>
                <Form.Item
                  name="vehicleType"
                  label="Vehicle Type "
                  initialValue={selectedGarage.vehicleType === 1 ? "Car" : selectedGarage.vehicleType === 2 ? "Bike" : selectedGarage.vehicleType === 3 ? "Both" : "-"}
                  
                 
                >
                                             <Input type="text" disabled/>

                </Form.Item>
              </Col>
            </div>
          </div>
            
          <div style={{ width: "100%" }}>
            <div className="select-extra">
              <Col xl={24} lg={24}>
                <Form.Item
                  name="categoryType"
                  label="Category"
                  
                >
                                 <Input type="text" defaultValue={selectedGarage.categoryType} disabled/>

                </Form.Item>
              </Col>
            </div>
          </div>
          
          
          <div style={{ width: "100%" }}>
            <div className="select-extra">
              <Col xl={24} lg={24}>
                <Form.Item
                  name="serviceName"
                  label="Service"
                  
                >
                                              <Input type="text" placeholder="Page Title" defaultValue={selectedGarage.serviceName} disabled/>

                </Form.Item>
              </Col>
            </div>
          </div>
          
       
         

          <div style={{ marginTop: '20px' }}>
         <span style={{color:"#b11226",fontWeight:"bold"}}> URL:</span> <a  target="_blank"><span style={{textDecoration:"underline",fontWeight:"bold"}} >{selectedGarage?.url}</span></a>
</div>

          
        </Row>
       
       
     
   
     

      <div className="add-page-manage-popup-footer">
       

      </div>
    </Form>
  </div>

</div>
</div>
</Modal>

)}
  

            <div>
              {isModalVisible && (
                <EditCategory
                  handleCloseModal={handleCloseModal}
                  selectedGarage={selectedGarage}
                />
              )}
            </div>

          </div>

        )}
        <Pagination
          onChange={onChange}
          simple
          total={filteredGarageList?.length}
          current={currentPage}
          pageSize={itemsPerPage}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default CategoryTable;
