import { useEffect, useState, } from "react";
import { myApi } from "../../../util/MyApi";
import { useHistory } from "react-router-dom";


export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
const history=useHistory()
  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError("");
  };





  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    history.push("/signin")
    //httpClient
      // .post("auth/logout")
      // .then(({ data }) => {
      //   if (data.result) {
      //     fetchSuccess();
      //     httpClient.defaults.headers.common["Authorization"] = "";
           

      //     setAuthUser(false);
      //     if (callbackFun) callbackFun();
      //   } else {
      //     fetchError(data.error);
      //   }
      // })
      // .catch(function (error) {
      //   fetchError(error.message);
      // });
  };

  const getAuthUser = (token, user) => {
    fetchStart();
    if (token) {
      fetchSuccess();
      setAuthUser();
    } else {
      console.log("getting getuserAuth error");
      setLoadingUser(false);
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const token = localStorage.getItem("admin");
    if (token) {
      myApi.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    myApi
      .patch("user/login")
      .then(({ data }) => {
        fetchSuccess();
        if (data) {
          setAuthUser();
        }
        setLoadingUser(false);
      })
      .catch(function () {
        myApi.defaults.headers.common["Authorization"] = "";
        setLoadingUser(false);
      });
  }, []);

  

  // Return the user object and auth methods
  return {
    
    
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    //userLogin,
    //userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,

  };
  
};
