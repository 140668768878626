import React from 'react'
import  { useEffect, useState } from "react";
import "./Garage.css";
import moment from "moment";
import CategoryForm from "./Categoryform";
import {
  Avatar,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
const { Option } = Select;

export default function ViewServices({selectedGarage,handleCloseModal,handleCancel,fetchGarageList,load,open}) {
    const [form] = Form.useForm();
    const [category, setCategory] = useState([])
    const [services, setServices] = useState([])
    const [split,setSplit]=useState(false)
    const [catId,setCatId]=useState("")
    console.log(selectedGarage.services)
    const handleChange = (value) => {
        const selectedCategory = category.categories.find((cat) => cat.categoryId === value);
        // setSelectedGarage(selectedCategory);
        fetchServiceLists(value)
        console.log(value)
    
      };
      const handleChanged = (value) => {
        const selectedCategory = category.categories.find((cat) => cat.categoryId === value);
        // setSelectedGarage(selectedCategory);
        console.log(value)
    setSplit(true)
      };


      
  const fetchGarageLists = async () => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/garage_Categories_List`, {
        garageId: selectedGarage?.garageId
      },
        {
          headers: { Authorization: `Bearer ${token}` },
        },

      );
      console.log(((response?.data)?.data));
      setCategory(((response?.data)?.data));
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageLists();
  }, []);
  const fetchServiceLists = async (id) => {
    console.log(id)
    console.log(selectedGarage?.categories[0]?.categoryId)
    
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/category_service_list`, {
        categoryId: id===undefined?selectedGarage?.categories[0]?.categoryId:id
      },
        {
          headers: { Authorization: `Bearer ${token}` },
        },

      );
      console.log(((response?.data)?.data));
      setServices(((response?.data)?.data));
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchServiceLists();
  }, []);

  useEffect(() => {
    setCatId(selectedGarage?.categories[0]?.categoryId)
  }, []);
      const token=localStorage.getItem("admin")
    const handleSubmit1 = async (values) => {
        
    console.log(values.serviceName)

        // const d = {
        //   garageId: selectedGarage.garageId,
        //   // categoryId: values.categoryId,

        //   services: values.serviceName?.map((serviceName) => ({
            
        //     serviceName: serviceName,
        //     quantity: 1,
        //     rate: 0,
        //   }))
        // }
        // console.log(d)
        
        // const startDate = values?.startDate.format("YYYY-MM-DD");
        // const expiryDate = values?.expiryDate.format("YYYY-MM-DD");
    
        try {
            
    
          const response = await fetch(`${ApiDomain.Domain}/add_category_services`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
            body: JSON.stringify({
                garageId:selectedGarage.garageId,
                // categoryId:(values.categoryId===selectedGarage?.categories[0]?.category)?catId:values.categoryId,
                services: values.serviceName?.map(serviceString => {
                  console.log(serviceString)
                  let serviceName, id;

                  if (split) {
                    [serviceName, id] = serviceString?.split(',');
                  } else {
                    [serviceName,id] = serviceString.key?.split(',');
                  }
                  
                  return {
                    _id: id,
                    serviceName: serviceName  ,
                    quantity: 1,
                    rate: 0,
                  }
                })
              // startDate,
              // expiryDate,
            }),
          });
          const data= await response.json()
          console.log(data)
          if (data.responseCode===200) {
            // Handle successful API response
            console.log("API call successful");
            fetchGarageList()
            message.success("Sevices added successfully");
            handleCloseModal();
          }
          else if(data.responseCode===400){
            message.warning("Adding atleast one Service")

          }
          
          
          else {
            // Handle API error
            console.error("API call failed");
            message.error("Error adding  Service Add");
          }
        } catch (error) {
          console.error("An error occurred during the API call:", error);
          message.error("Error addingService add");
        } finally {
        }
      };
  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal}>

        <div className="">
                  <div className="">
                    <div className="add-garage-manage-modal-content-header">
                     
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>Add  Garage Services</h3>
                      </div>
                    </div>
                    <div
                      className="add-garage-manage-modal-forms"
                      style={{ maxHeight: "500px" }}
                    >
                      <Spin spinning={load} >
                        <div className="">
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit1}
                            initialValues={true}
                          
                            
                            >                
                            <div>

                              <Form.Item
                                name='categoryId'
                                label='Category Name'
                                placeholder='Select Category'
initialValue={selectedGarage?.categories[0]?.categoryName}               
                  
                              >
                                <Select
                                  showSearch
                                  style={{ height: 40 }}
                                  placeholder='Select Category'
                                  optionFilterProp='children'
                                  onChange={(value) => handleChange(value)}
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {(category?.categories)?.map((garage) => (
                                    <Option key={garage.categoryId} value={garage.categoryId}>
                                      {garage.categoryName}
                                    </Option>
                                  ))}
                                </Select>


                              </Form.Item>


                              <Form.Item style={{ width:"auto" }}

                                name='serviceName'
                                label='Service Name'
                                placeholder='Select Service '
                                initialValue={selectedGarage?.services?.map(service => ({
                                  key: `${service.serviceName},${service.adminServiceId}`,
                                  label: service.serviceName
                                }))}
                                
                                >

                                <Select mode="multiple"
                                
                                  showSearch
                                  style={{ height: 100, overflowY:"scroll" }}
                                  placeholder='select Service '
                                  optionFilterProp='children'
                                  onChange={handleChanged}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }>
                                  {(services?.services)?.map((garage) => (
                                    <Option key={garage._id} value={`${garage.serviceName},${garage._id}`}>
                                      {garage?.serviceName}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>

                            </div>

                            <div className="add-garage-manage-popup-footer">
                              <button
                                className="cancel-button"
                                onClick={handleCloseModal}
                                style={{ marginRight: 8 }}
                              >
                                Cancel
                              </button>

                              <button className="submit-button" htmlType="submit">
                                Submit
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Spin>
                    </div>
                  </div>
                </div>
    </Modal>
  )
}
