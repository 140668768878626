import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import Category from "./Category/Category";
import SubCategory from "./Services/SubCategory";
import Dashboard from "./Dashboard/Dashboard";
import GarageManagement from "./GarageManagement/Category";
import CustomerManagement from "./Customermanagement/Category";
import PromoCode from "./PromoCode/Category";
import ApproveManagement from "./ApproveGarage/Category";
import Notifications from "./Notifications/Notifications";
import SubAdminManagement from "./SudAdmin/Category";
import Roles from "./Roles/Roles";
import Features from "./Features/Category";
import SaleInvoice from "./SaleInvoice/SaleInvoice";
import PurChase from "./Purchase/Purchase";
import JobCard from "./JobCard/Jobcard";
import Vendor from "./Vendors/Vendor";
import VecrepDashboard from "./VecrepDashboard/Dashboard";
import SinglePage from "../Singlepage";
import Inventory from "./Inventory/Inventory";
import Subscription from "./Subscription/Subscription";
import SeoEditPage from "../SeoEditPage"
import ApproveGarage from "../ApproveGarage/ApproveGarage";
import OurGarage from "../OurGarage/OurGarage";
import Reward from "./Rewards/Reward";
import Rating from "./Ratings/Rating";
import Pushnotification from "./Pushnotifications/Pushnotifications";
import Pagesed from "./Page/Pages";
import NearMeEdit from "../nearme";
import NearMe from "./nearme/nearme";
import MyCustomer from "./MyCustomer/Mycustomer";
import BookingManagement from "./Booking Management/BookingCustomer";
import States from "./States/State";
import Cities from "./Cities/Cities";
import Leads from "./MyLeads/leads";
import Sales from "./Sales/Category";
import SeoEditNearme from "../SeoEditNearme";
import GarageSeo from "../GarageSeo";
import ServicePage from "./servicesPages/servicePage";
import ServicePageEdit from "../servicePageEdit";
import Seoeditservicepage from "../SeoEditServicePage";

const Main = ({ match,act }) => {
const [data,setData]=useState([])
const[load,setLoad]=useState(false)
const token=localStorage.getItem("admin")
  // const fetchGarageList = async () => {

  //   try {
  //     setLoad(true)
  //     const response = await axios.get(`${ApiDomain.Domain}/adminDashboard`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     if (response.status===200){
        
  //       setData(response?.data?.data);
  //       console.log(response?.data?.data)
  //     setLoad(false)
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  // useEffect(() => {
  //   fetchGarageList();
  // }, []);
  const tab= localStorage.getItem("tab1")
  
  return (

    <Switch>
    
      <Route path={`${match.url}/dashboard`} component={Dashboard} />
      <Route path={`${match.url}/Promocodes`} component={PromoCode} />
      <Route path={`${match.url}/states`} component={States} />
      <Route path={`${match.url}/cities`} component={Cities} />

      <Route path={`${match.url}/category`} component={Category} />

      <Route path={`${match.url}/services`} component={SubCategory} />
      <Route
        path={`${match.url}/GarageManagement`} component={GarageManagement}/>
      <Route path={`${match.url}/customerManagement`}component={CustomerManagement}/>
      <Route
        path={`${match.url}/subAdminManagement`} component={SubAdminManagement}/>
          <Route
        path={`${match.url}/sales`} component={Sales}/>
        
      <Route path={`${match.url}/roles`} component={Roles} />
      <Route path={`${match.url}/features`} component={Features} />
      <Route path={`${match.url}/ApproveGarage`} component={ApproveManagement}
      />
      <Route path={`${match.url}/notifications`} component={Notifications} />
      <Route path={`${match.url}/SaleInvoice`} component={SaleInvoice} />

      <Route path={`${match.url}/Purchase`} component={PurChase} />
      <Route path={`${match.url}/JobCard`} component={JobCard} />
      <Route path={`${match.url}/Vendors`} component={Vendor} />
      <Route path={`${match.url}/Inventory`} component={Inventory} />
      <Route path={`${match.url}/Subscription`} component={Subscription} />
      <Route path={`${match.url}/Rewards`} component={Reward} />
      <Route path={`${match.url}/Pushnotifications`} component={Pushnotification} />
      <Route path={`${match.url}/Ratings`} component={Rating} />
      <Route path={`${match.url}/vecrep/dashboard`} component={VecrepDashboard} />
      <Route path={`${match.url}/vecrep/page`} component={Pagesed} />
      <Route path={`${match.url}/vecrep/nearme`} component={NearMe} />
      <Route path={`${match.url}/vecrep/editpage/:pageId`} component={SinglePage} />
      <Route path={`${match.url}/vecrep/seoeditpage/:pageId`} component={SeoEditPage} />
      <Route path={`${match.url}/vecrep/approveGarage`} component={ApproveGarage} />
      <Route path={`${match.url}/vecrep/ourGarage`} component={OurGarage} />
      <Route path={`${match.url}/vecrep/mycustomer`} component={MyCustomer} />
      <Route path={`${match.url}/vecrep/booking`} component={BookingManagement} />
      <Route path={`${match.url}/vecrep/leads`} component={Leads} />
      <Route path={`${match.url}/vecrep/servicePage`} component={ServicePage} />
      <Route path={`${match.url}/vecrep/editpagenear/:pageId`} component={NearMeEdit} />
      <Route path={`${match.url}/vecrep/seoeditnearme/:pageId`} component={SeoEditNearme} />
      <Route path={`${match.url}/vecrep/editservicepage/:pageId`} component={ServicePageEdit} />
      <Route path={`${match.url}/vecrep/seoeditservicepage/:pageId`} component={Seoeditservicepage} />
      <Route path={`${match.url}/vecrep/garageseoedit/:pageId`} component={GarageSeo} />

    </Switch>
  );
};

export default Main;
