import React, { useState, useEffect } from "react";
import "./category.css";
import { FaEdit, FaLock, FaLockOpen, FaPencilAlt } from "react-icons/fa";
import EditCategory from "./EditCategory";
import { Avatar, Popconfirm, Spin, message, Pagination } from "antd";
import CategoryForm from "./Categoryform";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";

const CategoryTable = ({ garageList, fetchGarageList, load }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lock, setLock] = useState(true);
  const [lockStatus, setLockStatus] = useState({});
  const [vehicleType, setVehicleType] = useState(0);
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryDesc, setCategoryDesc] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [status, setStatus] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };
  console.log(garageList.length)

  const handleToggleLock = (index) => {
    setLockStatus((prevStatus) => ({
      ...prevStatus,
      [index]: !prevStatus[index],
    }));
  };

  const handleEdit = () => { // Define handleEdit function
    handleOpenModal();
  };

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList, currentPage, pageSize]);

  const applyFilters = () => {
    const filteredList = garageList
      .filter((item) => {
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.createdAt) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.createdAt) <= new Date(filterCriteria.toDate);

        const statusMatch =
          !filterCriteria.status || item.status === filterCriteria.status;

        const searchMatch =
          !filterCriteria.search ||
          item.categoryName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase());

        return fromDateMatch && toDateMatch && statusMatch && searchMatch;
      })
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedList = filteredList.slice(startIndex, endIndex);

    setFilteredGarageList(paginatedList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  async function confirm1(e, id, newStatus) {
    if (e) {
      e.preventDefault();
    }

    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/deleteCategoryAdmin`,
        { categoryId: id, deleteStatus: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchGarageList();
        message.success(`Category is Delete Successfully`);
        setDeleteStatus(newStatus);
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function confirm(e, id, newStatus) {
    if (e) {
      e.preventDefault();
    }

    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/ChangeCategoryAdminStatus`,
        { categoryId: id, status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchGarageList();
        message.success(`Category is now ${newStatus ? "Active" : "Inactive"}`);
        setStatus(newStatus);
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  function cancel(e) {}

  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
  };
console.log(garageList.length)
  return (
    <Spin spinning={load}>
      <div className="category-card">
        <CategoryForm onSearch={handleFilterChange} />

        <div className="category-table" onClick={handleOverlayClick}>
          {filteredGarageList.length === 0 ? (
            <p style={{ textAlign: "center" }}>No categories available.</p>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Category Image</th>
                    <th>Created</th>
                    <th>Category Name</th>
                    <th>Vehicle Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredGarageList.map((item, index) => {
                    const formattedDate = moment(
                      item?.createdAt?.slice(0, 10)
                    ).format("DD-MM-YYYY");
                    const words = item.categoryName.split(" ");

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="table-image">
                            {item.categoryImage ? (
                              <img src={item.categoryImage} alt="" />
                            ) : (
                              <Avatar
                                shape="square"
                                size={40}
                                style={{
                                  backgroundColor: "#b11226",
                                  fontWeight: 600,
                                  fontSize: 17,
                                }}
                              >
                                {words.length > 1
                                  ? words[0]?.charAt(0)?.toUpperCase() +
                                    words[1]?.charAt(0)?.toUpperCase()
                                  : words[0]?.charAt(0)?.toUpperCase()}
                              </Avatar>
                            )}
                          </div>
                        </td>
                        <td>{formattedDate}</td>
                        <td>{item?.categoryName}</td>
                        <td>{item?.vehicleType === 1 ? "Car" : "Bike"}</td>

                        <td>
                          <span
                            className={`badge badge-${
                              item.status ? "success" : "danger"
                            }`}
                          >
                            {item.status ? "Active" : "Inactive"}
                          </span>
                        </td>
                        <td>
                          <div className="action-cell">
                            <div
                              className="edit-cell"
                              onClick={() => {
                                setVehicleType(item?.vehicleType);
                                setCategoryImage(item?.categoryImage);
                                setCategoryDesc(item?.categoryDescription);
                                setCategoryName(item?.categoryName);
                                setCategoryId(item?._id);
                                handleEdit();
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <FaPencilAlt />
                            </div>
                            <div className="edit-cell">
                              <Popconfirm
                                title={`Are you sure ${
                                  !item.status ? "Activate" : "Deactivate"
                                } this Category?`}
                                onConfirm={(e) =>
                                  confirm(e, item._id, !item.status)
                                }
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                              >
                                {item.status ? (
                                  <FaLockOpen />
                                ) : (
                                  <FaLock />
                                )}
                              </Popconfirm>
                            </div>
                            {/* <div className="edit-cell">
                              <Popconfirm
                                title={`Are you sure Delete this Category?`}
                                onConfirm={(e) =>
                                  confirm1(e, item._id, !item.deleteStatus)
                                }
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                              >
                                <DeleteOutlined />
                              </Popconfirm>
                            </div> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{ marginTop: "20px", float: "right" }}>
                <Pagination 
                simple
                  current={currentPage}
                  pageSize={pageSize}
                  total={garageList.length}
                  onChange={onPageChange}
                  onShowSizeChange={onPageSizeChange}
                />
              </div>
            </>
          )}

          <div>
            {isModalVisible && (
              <EditCategory
              open={isModalVisible}
                handleCloseModal={handleCloseModal}
                vehicle={vehicleType}
                categoryName={categoryName}
                categoryDesc={categoryDesc}
                categoryImage={categoryImage}
                categoryId={categoryId}
                fetchGarages={fetchGarageList}
              />
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CategoryTable;
