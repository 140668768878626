import React, { useEffect, useState } from "react";
import "./Pushnotificarion.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
import PushnotificarionForm from "./Pushnotificationsform";
const PushnotificarionTable = ({ garageList, load, fetchGarageList,count ,param,id}) => {
  console.log(garageList.responseData)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);
  const applyFilters = () => {
    const filteredList = garageList.responseData?.filter((item) => {
        // Parse the date in the expected format
        const formattedDate = moment(item?.Date, ["DD-MM-YYYY", "YYYY-MM-DD"], true);

        // Ensure the date is valid
    

        // Convert filter criteria dates to moment objects for comparison
        const fromDate = filterCriteria.fromDate ? moment(filterCriteria.fromDate, "YYYY-MM-DD") : null;
        const toDate = filterCriteria.toDate ? moment(filterCriteria.toDate, "YYYY-MM-DD") : null;

        // Filtering logic based on fromDate, toDate, and search
        const fromDateMatch = !fromDate || formattedDate.isSameOrAfter(fromDate, 'day');
        const toDateMatch = !toDate || formattedDate.isSameOrBefore(toDate, 'day');
        const searchMatch = !filterCriteria.search || item.customerName.toLowerCase().includes(filterCriteria.search.toLowerCase());
        const statusMatch =
        !filterCriteria.status ||
        (item.status ? "active" : "inactive") == filterCriteria.status;

        return fromDateMatch && toDateMatch && searchMatch && statusMatch;
    }).sort((a, b) => new Date(b.Date) - new Date(a.Date));

    setFilteredGarageList(filteredList);
};


  console.log(filteredGarageList)

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };


  // Calculate start and end index for visible rows
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  // Populate visibleRows array with data for the current page
  const visibleRows = filteredGarageList?.slice(startIndex, endIndex);

  const modalV=(index)=>{
    setModal(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);

  }
  console.log(selectedGarage)
  const cancelV=()=>{
    setModal(false)
  }
  const modalX=(index)=>{
    setModalXV(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const cancelx=()=>{
    setModalXV(false)
  }


  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };
  


  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
        return [
            startIndex + index + 1,
            item.Date || "-",
            item.garageName|| "-",
            item.Content || "-",
            item.Status_of_Business_Type || "-",
            item.vehicle_Type || "-",
            item.Status || "-",
            item.Reads || "-",
        ];
    });

    // Add header row
    formattedData.unshift([
        "S.No",
        "Date",
        "Garage Name",
        "Content",
        "Status of Business Type",
        "Vehicle Type",
        "Status",
        "Reads",
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
};

  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setModal(true);
  };

  function cancel(e) {}

 console.log(count)
 console.log(filteredGarageList)

  return (
    <>

    {/* <Modal visible={modal} onCancel={cancelV}>
      <h1>ssdsds</h1>

    </Modal> */}
      

      <Spin spinning={load}>
        <PushnotificarionForm onSearch={handleFilterChange} Csv={handleExportExcel} param={param} fetchGarageListed={fetchGarageList} />
        {!load && (
          <div className="notify-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Date</th>
                  <th className="th4">Garage Name</th>
                  <th className="th4">Title</th>

                  <th className="th4">Description</th>


                  <th className="th5">BusinessType</th>
                  <th className="th4">Vehicle Type </th>
                  <th className="th4">State </th>
                  <th className="th4">City </th>


                  <th className="th4">Status</th>
                  <th className="th4">Reads</th>
                  <th className="th4">Click</th>



                









                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No notify List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.date).format(
                      "DD-MM-YYYY"
                    );                  

                    

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1 || "-"}</td>
                        <td>{formattedDate || "-"}</td>
                        <td>{item.garageName || "-"}</td>
                        <td>{item.title || "-"}</td>

                        <td>{item.Content || "-"}</td>
 
                        <td>{item.Status_of_Business_Type ||"-"}</td>

                        <td>{item.Vehicle_Type=="1" &&"Car" || item.Vehicle_Type=="2" && "Bike" || item.Vehicle_Type=="3"&& "Both" ||item.Vehicle_Type=="" && "-" }</td>
                        <td>{item.state || "-"}</td>

                        <td>{item.location || "-"}</td>

                        <td>{item.Status?<p style={{color:"green",fontWeight:"bold"}}>Sent Success</p>:<p style={{color:"red",fontWeight:"bold"}}>Send Failed</p>}</td>
                        <td>{item.Reads}</td>

                        <td>{item.Click}</td>

                      

                        
                        





             

                        
                   
                       
                        
                        
                  

                      </tr>

                      
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modal && (
                 <div className="add-notify-manage-modal-overlay">
                 <div className="add-notify-manage-modal-contents">
                   <div className="add-notify-manage-modal-content-header">
                     <span
                       className="add-notify-manage-close-button"
                       onClick={cancelV}
                     >
                       &times;
                     </span>
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"white" }}>Customer Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-notify-manage-modal-forms"
                     style={{ width: 500,display:"flex",flexDirection:"row",}}
                   >
                    <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                     

                  
              
                   <div style={{textAlign:"",padding:"0px 20px 0px 20px"}}>
                  
                <div>

                <p style={{paddingLeft:27,fontWeight:"bold",marginBottom:"0.5em"}}>Booking Id:<span style={{fontSize:15,padding:20}}>{selectedGarage.bookingId}</span></p>

                        <p style={{paddingLeft:32,fontWeight:"bold",marginBottom:"0.5em"}}>Full Name:<span style={{fontSize:15,padding:20}} >{selectedGarage.customerName}</span></p>
      

      <p style={{paddingLeft:0,fontWeight:"bold",marginBottom:"0.5em"}}>Phone Number: <span style={{fontSize:15,padding:18}}>{selectedGarage.phone?selectedGarage.phone:"-"}</span></p>

      <p style={{paddingLeft:60 ,fontWeight:"bold",marginBottom:"0.5em"}}>Email: <span style={{fontSize:15,padding:17}}>{selectedGarage.email?selectedGarage.email:"-"}</span></p>

      
                 </div>
                 </div>

                 </div>
                 </div>
                 </div>
              
              )}
            </div>
            <div>
              {modalXV && (
                 <div className="add-notify-manage-modal-overlay">
                 <div className="add-notify-manage-modal-contents">
                   <div className="add-notify-manage-modal-content-header">
                     <span
                       className="add-notify-manage-close-button"
                       onClick={cancelx}
                     >
                       &times;
                     </span>
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"white" }}>vehicle Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-notify-manage-modal-forms"
                     style={{ width: 500,padding:15}}
                   >
                    {/* <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                      */}

                  
                   </div>
                   <div style={{textAlign:"",padding:"0px 30px 0px 30px"}}>
                  
                <div>

                <p style={{paddingLeft:20,fontWeight:"bold"}}>Vehicle Number:<span style={{fontSize:15,padding:20}}>{selectedGarage?.vehicleNumber}</span></p>

                        <p style={{paddingLeft:83,fontWeight:"bold"}}>Brand:<span style={{fontSize:15,padding:20}} >{selectedGarage?.brand}</span></p>
      

      <p style={{paddingLeft:83,fontWeight:"bold"}}>Model: <span style={{fontSize:15,padding:18}}>Duke 200</span></p>

      <p style={{paddingLeft:93 ,fontWeight:"bold"}}>Year: <span style={{fontSize:15,padding:17}}>2024</span></p>
      <p style={{paddingLeft:58,fontWeight:"bold"}}>Fuel Type: <span style={{fontSize:15,padding:17}}>Petrol</span></p>

      
                 </div>
                 </div>

                 
                 </div>
                 </div>
              
              )}
            </div>

          
           
          </div>
          
        )}
           <Pagination
          onChange={onChange}
          simple
          total={filteredGarageList?.length}
          pageSize={itemsPerPage}
          current={currentPage} // Set the current page to highlight the active page
          style={{ marginTop: 10, float: "right" }}
        />

      </Spin>
    </>
  );
};

export default PushnotificarionTable;
