import React, { useState, useEffect } from "react";
import "./Page.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CategoryTable from "./Pagetable";
import AddCategory from "./Addpages";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";
import {
  CloudUploadOutlined,

} from "@ant-design/icons";


import {
  message,
  Upload,
  Form,
  Col,
  Row,Button,

  Select,
} from "antd";

const Pagesed = () => {
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [reload,setReload]=useState(null)
  const [load,setLoad]=useState(false)
  const fetchstart=()=>{
    setLoad(true)
  }
  const fetchsuccess=()=>{
    setLoad(false)
  }
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const [file, setFile] = useState(null);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    await setFile(selectedFile); // Wait for state update to complete
    console.log("Selected File:", selectedFile);
    handleUpload(selectedFile); // Now call handleUpload after setting the file
  
    // Clear the file input value
    e.target.value = null;
  };
  
  
  
  const handleUpload = async (file) => {
    console.log("File in handleUpload:", file);
    const formData = new FormData();
    formData.append('file', file);
    console.log("FormData:", formData);
  
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/bulkSEOUpload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Correct content type for file upload
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      if (response.status === 200) {
        message.success('File uploaded successfully');
        fetchGarageList()
        // Handle any further actions after successful upload
      } else {
        message.error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('Error uploading file');
    }
  };
  

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-garage-manage-modal-overlay")) {
      handleCloseModal();
    }
  };
  const fetchGarageList = async () => {
    try {
      fetchstart()
      const response = await axios.post(`${ApiDomain.Domain}/getSEOdata`,
      {
        
          status: true,
          garageId: "",
          serviceName: "",
          categoryName: "",
          location: "",
          vehicleType: "",
          page: 0,
          pageCount: 2,
          pageType: "SEOPage"
      },
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      if (response.status===200){
        
        setGarageList(response.data.data);
        fetchsuccess()
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);

  return (
    <>
      <div>
      
      </div>
      <div className='page-manage-container' onClick={handleOverlayClick}>
        <div className='page-manage-content'>
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
          <div className='page-manage-header'>
            <h3 className='page-manage-head'>Pages</h3>

            <button className='add-page-manage' onClick={handleOpenModal} style={{cursor:"pointer"}}>
              Add New Pages
            </button>
          </div>
          <div style={{marginTop:10,display:"flex"}}>
          <div>
      <Form form={form} layout="vertical">
        <Row style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            <Col xl={24} lg={24}>
              <Form.Item
                name="garageImage"
                rules={[
                  {
                    // required: true,
                    message: 'Please upload Garage Image',
                  },
                ]}
              >
                <div className="upload" style={{padding:0}}>
                  <Button type="button" className="btn-warning">
                    <CloudUploadOutlined /> Upload 
                    <input type="file" onChange={handleFileChange} />
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </div>
        </Row>
      </Form>
    </div>

            <div style={{marginTop:5}}>
          <ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>fetchGarageList()} />
</div>
</div>
</div>
          <div className='page-manage-card'>
            <CategoryTable garageList={garageList} list={setGarageList} load={load} loads={setLoad}  fetchGarageList={fetchGarageList}            handleCloseModal={handleCloseModal}
  />
          </div>
        </div>
        {isModalVisible && (
          <AddCategory 
          data={isModalVisible}
            handleCloseModal={handleCloseModal}
            fetchGarageList={fetchGarageList}
          />
        )}
      </div>
    </>
  );
};

export default Pagesed;
