import React, { useState, useEffect } from "react";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";

import {
  message,
  Upload,
  Form,
  Col,
  Row,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Radio,
  AutoComplete,
  Modal,
  Spin,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./Garage.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";
import LocationSearch from "./Location";

const { Option } = Select;

const EditCategory = ({
  handleCloseModal,
  fetchGarageList,
  selectedGarage,open
}) => {
  console.log(selectedGarage, "selectedGarage");
  const [form] = Form.useForm();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [garageList, setGarageList] = useState([]);
  const [area,setArea]=useState("")
  const [autoSuggestions, setAutoSuggestions] = useState([]);
  const [selectedVehicleType, setSelectedVehicleType] = useState(
    selectedGarage?.vehicleType
  );

  const [workingHours, setWorkingHours] = useState([
    { day: "Monday", slots: [], disabled: false },
    { day: "Tuesday", slots: [], disabled: false },
    { day: "Wednesday", slots: [], disabled: false },
    { day: "Thursday", slots: [], disabled: false },
    { day: "Friday", slots: [], disabled: false },
    { day: "Saturday", slots: [], disabled: false },
    { day: "Sunday", slots: [], disabled: false },
  ]);
  const history = useHistory();
  const [isGSTExist, setIsGSTExist] = useState(false);
  const [clockVisible, setClockVisible] = useState(false);
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [load, setLoad] = useState(false);
  const [initialCategoryNames, setInitialCategoryNames] = useState([]);
  const { Option } = Select;
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [lat,setLatitude]=useState(null)
  const [long,setLongitude]=useState(null)
    const [addressed, setAddressed] = useState("");
    const [parentAddress,setParentAddress]=useState(selectedGarage?.address)
  const showClockModal = () => {
    setClockVisible(true);
  };

  const [state,setState]=useState([])
  const [city,setCity]=useState([])
  const [selectedState, setSelectedState] = useState(true);
  const [selectedCity, setSelectedCity] = useState(null);

  const handleStateChange = (value) => {
    setSelectedState(false);
    // Fetch cities for the selected state from the API
    // Replace the API call with your actual API endpoint
    fetchCities(value);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };
  const fetchServiceStates = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfStates`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data)
      setState(response?.data?.data)

    } catch (error) {
      console.log(error.message);
    }
  };
  
  const fetchCities = async (id) => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfCities/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data)
      setCity(response?.data?.data)
    } catch (error) {
      console.log(error.message);
    }
  };
  // const fetchCities1 = async (id) => {
  //   const selectedStateid = state.find((state) => state.stateName === selectedGarage.state);
  //       const stateName = selectedStateid ? selectedStateid._id : ''; // Extract state name
  //       console.log(stateName)
  //   try {
  //     const response = await axios.get(
  //       `${ApiDomain.Domain}/listOfCities/${stateName}`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );
  //     console.log(response.data.data)
  //     setCity(response?.data?.data)
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  useEffect(() => {
    const fetchData = async () => {
      await fetchServiceStates(); // Wait for states data to be fetched
      // fetchCities1(); // Then fetch cities
    };
  
    fetchData(); // Call the fetchData function
  }, []);
  
  // Other state variables...
  const handleChangeCar = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoadingCar(true);

      // Get the image URL from the API response
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        "https://api.vecrep.com/uploadImage",
        formData
      );

      // Assuming the API response contains the image URL
      const imageUrl = response.data.imagePath1;
      // Update the state with the image URL
      setImageUrlCar(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoadingCar(false);
    }
  };

  const uploadButton = (
    <div>
      {loadingCar ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );



  const handleDayToggle = (day) => {
    setWorkingHours((prev) =>
      prev.map((item) =>
        item.day === day ? { ...item, disabled: !item.disabled } : item
      )
    );
  };

  const fetchstart = () => {
    setLoad(true);
  };
  const fetchsuccess = () => {
    setLoad(false);
  };

  const [categoriesData, setCategoriesData] = useState(
    selectedGarage?.categories?.map((category) => ({
      categoryId: category.categoryId,
    })) || []
  );

  // Function to handle input change
  // Function to handle input change
  const handleInputChange = (selectedCategories) => {
    // Map selectedCategories to an array of category objects with categoryId and categoryName
    const newCategories = selectedCategories.map((categoryName) => {
      // Find the category object in filteredGarageList by categoryName
      const category = filteredGarageList.find(
        (item) =>
          item._id === categoryName || item.categoryName === categoryName
      );
      // Return an object with categoryId and categoryName if found, otherwise return null
      return category ? { categoryId: category._id } : null;
    });

    // Filter out any null values (categories not found)
    const validCategories = newCategories.filter(
      (category) => category !== null
    );

    // Update categoriesData state with the new category objects
    setCategoriesData(validCategories);
  };

  console.log(categoriesData);

  console.log(initialCategoryNames);
  const token = localStorage.getItem("admin");
console.log(lat)
console.log(long)

console.log(addressed)
  console.log(area)
  console.log(lat)
  console.log(long)
  const handleSubmit = (values) => {
    const dataId = selectedGarage?.categories?.map(
      (category) => category.category
    );
    console.log(values);
    const selectedState = state.find((state) => state._id===values.state|| state.stateName===values.state);
    console.log(selectedState)
    const stateName = selectedState ? selectedState.stateName : ''; // Extract state name
    form
      .validateFields()
      .then((formValues) => {
      
        const submittedData = workingHours.map((dayObj) => ({
          day: dayObj.day,
          slots: dayObj.slots,
          checked: !dayObj.disabled,
        }));
console.log(lat,long)
        const requestData = {
          ...formValues,
          state: stateName, // Replace state ID with state name
address:addressed?addressed:selectedGarage?.address,
area:area?area:selectedGarage?.area,
          imagePath: imageUrlCar,
          latitude :lat,
          longitude : long,
          garageId: selectedGarage.garageId || selectedGarage._id,
          workingHours: submittedData,
          categories: categoriesData,
          
        
          
          // startTime: selectedStartTime,
          // endTime: selectedEndTime,
        };
        console.log(requestData);

        const headers = {
          Authorization: `Bearer ${token}`,
        };
          fetchstart();

             

              axios
                .put(`${ApiDomain.Domain}/editGarageProfile`, requestData, {
                  headers,
                })
                .then((response) => {
                  console.log("API Response:", response);
                  if (response?.data?.responseCode === 200) {
                    fetchsuccess();
                    handleCloseModal();
                    fetchGarageList();
                    message.success(response?.data?.message);
                  }
                })
                .catch((error) => {
                  console.error("API Error:", error);
                });
          
            
          
        
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };
  const fetchCategoryList = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/CategoryAdminList`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setGarageList(response?.data?.categories);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchCategoryList();
    setSelectedVehicleType(selectedGarage.garageType)
  }, []);

  const handleVehicleTypeChange = (e) => {
    setSelectedVehicleType(e.target.value);
  };
  console.log(selectedVehicleType===1)
  console.log(selectedVehicleType===2)
  console.log(selectedVehicleType)


  const filterGarageSuggestions = () => {
    if (selectedVehicleType === 1) {
      // Filter car details
      const carSuggestions = garageList.filter(
        (garage) => garage.vehicleType === 1
      );
      setFilteredGarageList(carSuggestions);
    } else if (selectedVehicleType === 2) {
      // Filter bike details
      const bikeSuggestions = garageList.filter(
        (garage) => garage.vehicleType === 2
      );
      setFilteredGarageList(bikeSuggestions);
      }

    
    
    else {
      // Pass all data when no vehicle type is selected
      setFilteredGarageList(garageList);
    }
  };
  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    const categories = selectedGarage?.categories?.map(
      (category) => category.categoryId
    );
    setInitialValues(categories);
  }, [selectedGarage]);

  useEffect(() => {
    filterGarageSuggestions();
  }, [selectedVehicleType, garageList]);

  console.log(typeof initialCategoryNames);

  useEffect(() => {
    // Populate initialCategoryNames with the category names from selectedGarage

    ExampleFun();
  }, [selectedGarage]);
  const handleAddressChange = (autocompletedText, address) => {
    setParentAddress(autocompletedText);
  };
 

  const ExampleFun = (category) => {
    // ExampleFun(categories)
    const categories = selectedGarage?.categories?.map(
      (category) => category.category
    );

    setInitialCategoryNames(categories);
  };
  // Log the type of categoryList whenever it changes

  useEffect(() => {
    filterGarageSuggestions();
  }, [selectedVehicleType, garageList]);


  console.log(selectedGarage?.categories?.map((category) => category.category));
  return (
    <Modal visible={open}  centered onCancel={handleCloseModal} footer={null}   width={800} >

    <div className="">
      <div className="">
      
        
          <div>
            <h3 style={{ color: "#b11226", fontWeight: "bold",textAlign:"center" }}>Edit Garage</h3>
          </div>
      
        <div
          className="add-garage-manage-modal-forms"
          style={{ maxHeight: "500px", overflowY: "scroll", width:"auto" }}
        >
          <Spin spinning={load}>
            <Form
              form={form}
              layout="vertical"
              style={{ minWidth: "600px" }}
              onFinish={handleSubmit}
              initialValues={true}
            >
              <div>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        name="garageImage"
                        label="Garage Logo : "
                        rules={[
                          {
                            // required: true,
                            message: "Please upload Garage Image",
                          },
                        ]}
                      >
                        <div class="upload">
                          <button type="button" class="btn-warning">
                            <CloudUploadOutlined /> Upload File
                            <input type="file" onChange={handleChangeCar} />
                          </button>
                        </div>
                      </Form.Item>
                    </Col>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}></Col>
                  </div>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Garage Name : "
                        name="garageName"
                        initialValue={selectedGarage?.garageName}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            required: true,
                            message: "Please input your Garage Name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Your Garage Name"
                          style={{  }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  <div style={{ width: "50%" }}>
                        <Col xl={24} lg={24}>
                          <Form.Item label="Owner Name : " name="ownerName"                         initialValue={selectedGarage?.garageOwnerName}
>
                            <Input
                              placeholder="Enter Your Owner Name"
                              style={{  }}
                            />
                          </Form.Item>
                        </Col>
                      </div>
                 
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Contact No : "
                        name="phoneNumber"
                        initialValue={selectedGarage?.phoneNumber}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            required: true,
                            message: "Please input your Phone number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Mobile Number"
                          style={{  }}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Email : "
                        name="email"
                        initialValue={selectedGarage?.email}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Your Email"
                          style={{ }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  </Row>
                  <Row>
                 
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                    
                    <LocationSearch address={addressed} updateValue={setAddressed} setLatitude={setLatitude} setLongitude={setLongitude}   onLocationChange={handleAddressChange} area={setArea}
                initialValue={parentAddress}
                 />

                    </Col>
                  </div>
                  <div style={{ width: "50%" }}>
          <Col xl={24} lg={24}>
            <Form.Item initialValue={selectedGarage?.area}
              label="Area : "
              name="area"
              rules={[
                {
                  type: "text",
                  message: "The input is not valid!",
                },
                {
                  required: true,
                  message: "Please input your Area!",
                },
              ]}
            >
                <Input 
                          placeholder="Enter Your Area"
                        />
              
            </Form.Item>
          </Col>
        </div>
                  </Row>
                  <Row>
                  <div style={{ width: "50%" }}>
                  <Col xl={24} lg={24}>
            <Form.Item label="State : " name="state" initialValue={selectedGarage?.state}>
              <Select
                placeholder="Select State"
                style={{ width: "100%" }}
                onChange={handleStateChange}
              >
                {/* Assume state is an array of state objects */}
                {state?.map((suggestion) => (
                  <Option
                    key={suggestion._id}
                    value={suggestion._id}
                  >
                    {suggestion.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
                  </div>
                  <div style={{ width: "50%" }}>
                <Col xl={24} lg={24}>
            <Form.Item
              label="City : "
              name="city"
              initialValue={selectedGarage?.city}
              rules={[
                {
                  type: "text",
                  message: "The input is not valid!",
                },
              ]}
            >
              <Select
                placeholder="Select City"
                style={{ width: "100%" }}
                onChange={handleCityChange}
                
              >
                {city?.map((city) => (
                  <Option 
                    key={city.id}
                    value={city.cityName}
                  >
                    {city.cityName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
                 </div>
                 
                
               
               </Row>
                
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="PAN Number : "
                        name="pancardNumber"
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            message: "Please input your Pan Card number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Your PAN Card Number"
                          style={{ }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  <div style={{ width: "50%" }} className="parent-select">
                    <Col xl={24} lg={24}>
                      <Form.Item
                        initialValue={selectedGarage?.businessType}
                        name="businessType"
                        label="Business Type "
                        rules={[
                          {
                            required: true,
                            message: "Please Select Business Type",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ height: 40 }}
                          placeholder="Select Type"
                          optionFilterProp="children"
                          // onChange={handleVehicleChange}
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="Workshops">
                            Workshops/Garages/Modification
                          </Option>
                          <Option value="Accessories">Accessories</Option>
                          <Option value="Washing">Washing/Detailing</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item label="GST Number : " name="gstNumber"                         initialValue={selectedGarage?.gstNumber}
>
                        <Input
                          placeholder="Enter Your gst Card Number"
                          style={{}}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item label="URL: " name="urlLink"                         initialValue={selectedGarage?.urlLink}
>
                        <Input
                          placeholder="Enter Your URL"
                          style={{  }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  
                </Row>
            
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item label="Open and Close time : " name="time">
                        <Radio.Group name="radiogroup">
                          <Radio value={1}>Manual Time</Radio>
                          <Radio value={2}>24*7</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </div>
                </Row>

                <h5
                  style={{
                    color: "inherit",
                    paddingLeft: "8px",
                    paddingBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Working Hours:
                </h5>
                {workingHours?.map((dayObj) => (
                  <Row
                    key={dayObj.day}
                    style={{ paddingBottom: "10px", paddingTop: "10px" }}
                  >
                    <Col xl={24} lg={24}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            width: "150px",
                            textAlign: "right",
                            paddingRight: "20px",
                          }}
                        >
                          <label style={{ textAlign: "center" }}>
                            {dayObj.day}
                          </label>
                        </div>
                        <div>
                          <TimePicker
                            use12Hours
                            disabled={dayObj.disabled}
                            onChange={(time, timeString) =>
                              setSelectedStartTime(timeString[0])
                            }
                            format="hh:mm:ss A"
                            style={{ marginRight: "10px" }}
                          />
                          <TimePicker
                            use12Hours
                            disabled={dayObj.disabled}
                            onChange={(time, timeString) =>
                              setSelectedEndTime(timeString[1])
                            }
                            format="hh:mm:ss A"
                          />
                          <Checkbox
                            onChange={() => handleDayToggle(dayObj.day)}
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))}

                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Vehicle Type : "
                        name="garageType"
                        required
                        initialValue={selectedGarage?.garageType}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            required: true,
                            message: "Please input your Garage Type!",
                          },
                        ]}
                      >
                        <Radio.Group
                          name="radiogroup"
                          onChange={handleVehicleTypeChange}
                        >
                          <Radio value={1}>4W</Radio>
                          <Radio value={2}>2W</Radio>
                          <Radio value={3}>Both</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",

                    flexDirection: "row",

                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Categories:"
                        name="categories"
                        initialValue={ selectedGarage?.categories?.map(
                          (category) => category.categoryId
                        )}
                        required
                      >
                        <Select
                          mode="multiple"
                          showSearch
                          allowClear
                          placeholder="Select categories"
                          style={{ height: "100px", overflowY: "scroll" }}
                          onChange={handleInputChange}

                        >
                          {filteredGarageList?.map((suggestion) => (
                            <Option
                              style={{ padding: 10 }}
                              key={suggestion._id}
                              value={suggestion._id}

                            >
                              {suggestion.categoryName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </div>
                </Row>

                <Row
                  style={{
                    display: "flex",

                    flexDirection: "row",

                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        initialValue={selectedGarage?.aboutGarage}
                        label="Garage Description : "
                        name="aboutGarage"
                      >
                        <TextArea rows={3} placeholder="Enter Description" />
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
              </div>

              <div className="add-garage-manage-popup-footer">
                <button
                  className="cancel-button"
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </button>

                <button className="submit-button" htmlType="submit">
                  Submit
                </button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
    </Modal>
  );
};

export default EditCategory;
