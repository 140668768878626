import React, { useState } from "react";
import "./Notifications.css";

const NotificationForm = () => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");

  const handleDateChange = (e) => {
    setSelectedFromDate(e.target.value);
  };
  const handleToDateChange = (e) => {
    setSelectedToDate(e.target.value);
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSearch = () => {
    console.log("Searching...");
  };

  return (
    <div>
      <div className='notifications-card-header'>
        <form className='notifications-form-control'>
          <div className='notifications-input'>
            <label htmlFor='fromdate'>From Date</label>
            <input
              type='date'
              id='fromdate'
              name='fromdate'
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder='From Date'
              className='notifications-input-cal'
            />
          </div>
          <div className='notifications-input'>
            <label htmlFor='todate'>To Date</label>
            <input
              type='date'
              id='todate'
              name='todate'
              value={selectedToDate}
              onChange={handleToDateChange}
              placeholder='To Date'
              className='notifications-input-cal'
            />
          </div>
          <div className='notifications-input'>
            <label htmlFor='status'>Notification Status</label>
            <select
              value={status}
              name='servicestatus'
              onChange={handleStatusChange}
              className='notifications-input-cal'>
              <option value='' disabled>
                Choose an option
              </option>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>

          <div className='notifications-input-search'>
            <label htmlFor='search'>Search</label>
            <input
              type='search'
              name='search'
              placeholder='Search...'
              className='search-input'
              onChange={handleSearch}
            />
          </div>
          <div>
            <button className='notifications-form-button'>Search</button>
          </div>
          <div>
            <button className='notifications-form-button'>Clear</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NotificationForm;
