import React, { useState, useEffect } from "react";
import "./booking.css";
import { FaSearch, FaTimes} from "react-icons/fa";
import { Popover } from "antd";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const BookingCustomerForm = ({ onSearch,ExportCsv}) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const [minimumToDate, setMinimumToDate] = useState(null);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedFromDate(e.target.value);
    setDisableToDate(false);
    const minimumToDateValue = selectedDate ? selectedDate : null;
    setSelectedToDate("");
    setMinimumToDate(minimumToDateValue);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status:status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };
  const handleToDateChange = (e) => {
    setSelectedToDate(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status:status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };
 

  console.log(status)
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status:status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm(""); // Clear the search term
    setDisableToDate(true);
    setMinimumToDate(null);
  };
  



  

  return (
    <div>
      <div className="booking-manage-card-header">
        <form className="booking-manage-form-control">
          <div className="booking-manage-input booking-man">
            {/* <label htmlFor="fromdate" style={{ fontWeight: "bold" }}>
              From Date
            </label> */}
            <input
              type="date"
              id="fromdate"
              name="fromdate"
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder="From Date"
              className="booking-manage-input-cal"
            />
          </div>
          <div className="booking-manage-input booking-man">
            {/* <label htmlFor="todate" style={{ fontWeight: "bold" }}>
              To Date
            </label> */}
            <input
              type="date"
              id="todate"
              name="todate"
              value={selectedToDate}
              onChange={handleToDateChange}
              min={minimumToDate}
              placeholder="To Date"
              className="booking-manage-input-cal"
              disabled={disableToDate}
              title={disableToDate ? "Please select From Date first" : ""}
            />
          </div>

          <div className="booking-manage-input booking-man1">
            {/* <label htmlFor="search" style={{ fontWeight: "bold" }}>
              Search
            </label> */}
            <input
              type="search"
              name="search"
              placeholder="Search..."
              className="search-input"
              onChange={handleSearchChange}

            />
          </div>
       
          <div>
            <Popover 
            
            content={"Clear Filters"}
      trigger="hover"
            >
            <button type="button" className="booking-manage-form-button"               onClick={handleClear}>

            {/* <FaTimes /> */}
            <CloseOutlined />
            </button>
            </Popover>
          </div>
          <div onClick={ExportCsv}>

          <Popover 
            
            content={"Export CSV"}
      trigger="hover"
            >
            <button type="button" className="booking-manage-form-excel">
            <TbFileExport /> 

                       </button>
                       </Popover>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingCustomerForm;
