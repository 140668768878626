import React, { useState, useEffect } from "react";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";

import {
  message,
  Upload,
  Form,
  Col,
  Row,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Radio,
  AutoComplete,
  Spin,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./servicePage.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";

const { Option } = Select;

const AddCategory = ({ handleCloseModal, fetchGarageList, open }) => {
  const [form] = Form.useForm();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [garageList, setGarageList] = useState([]);
  const [load, setLoad] = useState(false);
  const [autoSuggestions, setAutoSuggestions] = useState([]);
  const [apiData, setApiData] = useState([]);
  const history = useHistory();
  const [isGSTExist, setIsGSTExist] = useState(false);
  const [slot, setSlots] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [generatedUrl, setGeneratedUrl] = useState('');

  const handleFormChange = (changedValues, allValues) => {
    const { title, vehicleType, categoryType, serviceName, location } = allValues;
    const baseURL = `${ApiDomain.SEO_URL}`;
    const Serv = serviceName?.replace(/\s+/g, "-")?.toLowerCase();
    const loc = location?.replace(/\s/g, "");

    const constructedUrl = `${baseURL}${Serv ? Serv : ""}-services`;

    setGeneratedUrl(constructedUrl);
    console.log(constructedUrl);
  };

  const token = localStorage.getItem("admin");

  const fetchCategoryList = async (vehicleType) => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/CategoryAdminList`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const filteredCategories = response?.data?.categories.filter(
        (category) => category.vehicleType === vehicleType
      );
      setServiceList(filteredCategories);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchServiceList = async (id) => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/category_service_list`,
        {
          categoryId: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data);
      setApiData(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCategoryChange = (value) => {
    console.log(`Selected category: ${value}`);
    const selectedCategory = serviceList.find((category) => category.categoryName === value);
    console.log(selectedCategory);
    if (selectedCategory) {
      fetchServiceList(selectedCategory._id);
    }
  };

  const handleVehicleChange = (value) => {
    console.log(`Selected: ${value}`);
    setSelectedVehicleType(value);
    fetchCategoryList(value);
  };

  useEffect(() => {
    if (selectedVehicleType) {
      fetchCategoryList(selectedVehicleType);
    }
  }, [selectedVehicleType]);

  const handleSubmit = async (values) => {
    console.log(values);

    setLoad(true);
    const accessToken = localStorage.getItem("admin");
    const baseURL = `${ApiDomain.SEO_URL}`;
    const location = values.location;
    const vehicleType = values.vehicleType;
    const category = values.categoryName;
    const Service = values.serviceName;
    const Serv = Service?.replace(/\s+/g, "-")?.toLowerCase();
    const url = `${baseURL}${Serv}-near-me`;
    console.log(baseURL);

    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/SEOdata`,
        {
          ...values,
          garageId: "",
          FAQContent: [],
        
          paragraphData: "",
          url: generatedUrl,
          pageType: "servicePage",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 201) {
        console.log(response.data.data);
        history.push(`/admin/vecrep/editservicepage/${(response?.data)?.data?._id}`);
        handleCloseModal();
      } else {
        console.error("API call failed");
        message.error("Error adding Feature");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoad(false);
    }
  };

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };

  const filterGarageSuggestions = () => {
    const suggestions = garageList.filter(
      (garage) => garage.vehicleType == selectedVehicleType
    );
    setFilteredGarageList(suggestions);
  };

  useEffect(() => {
    filterGarageSuggestions();
  }, [selectedVehicleType, garageList]);

  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal} width={"auto"}>
      <div className="">
        <div className="">
          <div className="add-page-manage-modal-content-header">
            <div>
              <h3 style={{ fontWeight: "bold", color: "#b11226" }}>Add NearMe Page</h3>
            </div>
          </div>
          <div
            className="add-page-manage-modal-forms"
            style={{ maxHeight: "500px", overflowY: "scroll", width: 400 }}
          >
            <div className="">
              <Form
                form={form}
                layout="vertical"
                style={{ minWidth: "300px" }}
                onFinish={handleSubmit}
                initialValues={true}
                onValuesChange={handleFormChange}
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="title" label="Title">
                          <Input type="text" placeholder="Page Title" />
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="vehicleType" label="Vehicle Type">
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select Vehicle Type"
                            optionFilterProp="children"
                            onChange={handleVehicleChange}
                            filterOption={(input, option) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value={1}>Car</Option>
                            <Option value={2}>Bike</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="categoryType" label="Category">
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select Category"
                            optionFilterProp="children"
                            onChange={handleCategoryChange}
                            filterOption={(input, option) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {serviceList?.map((item, index) => (
                              <Option key={index} value={item?.categoryName}>
                                {item?.categoryName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="serviceName" label="Service">
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select Service"
                            optionFilterProp="children"
                            onChange={handleVehicleChange}
                            filterOption={(input, option) =>
                              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {apiData.services?.map((item, index) => (
                              <Option key={index} value={item?.serviceName}>
                                {item?.serviceName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div style={{ marginTop: '20px' }}>
                    <span style={{ color: "#b11226", fontWeight: "bold" }}> URL:</span>{" "}
                    <a href={generatedUrl} target="_blank">
                      <span style={{ textDecoration: "underline", fontWeight: "bold" }}>
                        {generatedUrl}
                      </span>
                    </a>
                  </div>
                </Row>

                <div className="add-page-manage-popup-footer">
                  <button className="submit-button" htmlType="submit">
                    Create Page
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCategory;
