import React, { useState } from "react";
import "./category.css";
import { FaSearch, FaTimes } from "react-icons/fa";
import { Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const CategoryForm = ({ onSearch }) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const [minimumToDate, setMinimumToDate] = useState(null);


  
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
    setMinimumToDate(null);
  };


  return (
    <div>
      <div className='category-card-header'>
        <form className='category-form-control'>
         
          <div className='category-input'>
            {/* <label htmlFor='status' style={{fontWeight:"bold"}} >Location Status</label> */}
            <select
              value={status}
              name='status'
              onChange={handleStatusChange}
              className='category-input-cal'>
              <option value='' disabled>
                Choose an option
              </option>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>
          <div className='category-input-search'>
            {/* <label htmlFor='search' style={{fontWeight:"bold"}}>Search</label> */}
            <input
              type='search'
              name='search'
              placeholder='Search...'
              className='search-input'
              onChange={handleSearchChange}
            />
          </div>
          
          <div>
          <Popover content={"Clear Filters"}
      trigger="hover"
    
            >
            <button
              className='category-form-button'
              type='button'
              onClick={handleClear}>
            <FaTimes />
            </button>
            </Popover>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
