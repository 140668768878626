import React, { useEffect } from "react";
import "./Dashboard.css";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import { useState } from "react";
import { Skeleton } from "antd";
import CountUp from "react-countup";


const VecrepDashboard = () => {
  const [data,setData]=useState([])
const[load,setLoad]=useState(false)
const token=localStorage.getItem("admin")
  const fetchGarageList = async () => {

    try {
      setLoad(true)
      const response = await axios.get(`${ApiDomain.Domain}/adminDashboard`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status===200){
        
        setData(response?.data?.data);
        console.log(response?.data?.data)
      setLoad(false)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);
  return (
    <div className="boxes-container">
      
      <div className="box blue-gradient">
      <Skeleton loading={load} paragraph={true}>

        <div className="number">
        <CountUp start={0} end={data.ourgarages || 0} duration={10} />

          </div>
        <div className="text">Our Vecrep Garages</div>
        </Skeleton>
      </div>
      <div className="box orange-gradient">
      <Skeleton loading={load} paragraph={true}>

        <div className="number">        <CountUp start={0} end={data.vecrepApprove || 0} duration={10} />
        </div>
        <div className="text">Approve Vecrep Garage</div>
        </Skeleton>
      </div>
    </div>
  );
};

export default VecrepDashboard;
