import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {useHistory} from "react-router-dom"
const UserProfile = () => {
  const history=useHistory()
  const route=()=>{
    history.push("/login")
    localStorage.removeItem("tab1")
    localStorage.removeItem("admin")
  }
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Profile</li>
      <li>Settings</li>
      <li onClick={route}>Logout
      </li>
    </ul>
  );

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={"https://purepng.com/public/uploads/large/purepng.com-steering-wheelsteering-wheelcar-wheelcar-steeringwheels-17015276150957wz9y.png/170"}
                className="gx-size-40 gx-pointer gx-mr-5 gx-mt-3" alt=""/>
        {/* <span className="gx-avatar-name">Rob Farnandies<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span> */}
      </Popover>
    </div>

  )
};

export default UserProfile;
