import React, { useState } from "react";
import "./approve.css";
import { Popover } from "antd";
import { FaSearch, FaTimes } from "react-icons/fa";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const CategoryForm = ({ onSearch,Csv }) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
const role=localStorage.getItem("role")
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedFromDate(selectedDate);
    setDisableToDate(false);
    setSelectedToDate(""); // Reset "To Date" when "From Date" changes
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleToDateChange = (e) => {
    setSelectedToDate(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleClear = (e) => {
    e.preventDefault()
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
  };

  

  return (
    <div>
      <div className='approve-approve-card-header'>
        <form className='approve-approve-form-control'>
          <div className='approve-approve-input'>
            {/* <label htmlFor='fromdate' style={{ fontWeight: "bold" }}>
              From Date
            </label> */}
            <input
              type='date'
              id='fromdate'
              name='fromdate'
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder='From Date'
              className='approve-approve-input-cal'
            />
          </div>

          <div className='approve-approve-input'>
            {/* <label htmlFor='todate' style={{ fontWeight: "bold" }}>
              To Date
            </label> */}
            <input
              type='date'
              id='todate'
              name='todate'
              value={selectedToDate}
              onChange={handleToDateChange}
              placeholder='To Date'
              className='approve-approve-input-cal'
              disabled={disableToDate}
              min={selectedFromDate} 
            />
          </div>

          <div className='approve-approve-input'>
            {/* <label htmlFor='search' style={{ fontWeight: "bold" }}>
              Search
            </label> */}
            <input
              type='search'
              name='search'
              placeholder='Search...'
              className='search-input'
              onChange={handleSearchChange}
            />
          </div>

          


        
          <div>
            <Popover content={"Clear Filters"}
      trigger="hover"
    
            >
            <button className='approve-approve-form-button' onClick={(e)=>handleClear(e)}>
            <CloseOutlined />
</button>
            </Popover>
          </div>
          {role ==="admin" &&(

          <div onClick={(e)=>Csv(e)}>
          <Popover content={"Export CSV"}
      trigger="hover"
    
            >
            <button className='approve-approve-form-excel'>
              <TbFileExport/>


            </button>
            </Popover>
          </div>
)}
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
