import React, { useEffect } from "react";
import "./Dashboard.css";
import { useState } from "react";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import { Skeleton } from "antd";
import CountUp from "react-countup";
const DigitAnimation = ({ value }) => {
  const digits = value.toString().split("");

  return (
    <div className="digit-container">
      {digits.map((digit, index) => (
        <div key={index} className="digit">
          {[...Array(10).keys()].map((num) => (
            <div
              key={num}
              className={`number ${
                num === parseInt(digit, 10) ? "active" : ""
              }`}
            >
              {num}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const token = localStorage.getItem("admin");
  const fetchGarageList = async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${ApiDomain.Domain}/adminDashboard`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setData(response?.data?.data);
        console.log(response?.data?.data);

        setLoad(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);

  console.log("list ");
  return (
    <div className="boxes-container">
      <div className="box green-gradient">
        <Skeleton loading={load} paragraph={true}>
          <div className="number">
            <CountUp start={0} end={data.allGarages || 0} duration={10} />
          </div>

          <div className="text">All Garage</div>
        </Skeleton>
      </div>

      <div className="box purple-gradient">
        <Skeleton loading={load} paragraph={true}>
          <div className="number">
            <CountUp start={0} end={data.approveGarage || 0} duration={10} />
          </div>
          <div className="text">Approve Garages</div>
        </Skeleton>
      </div>
    </div>
  );
};

export default Dashboard;
