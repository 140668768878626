import React, { useEffect, useState } from "react";
import "./Notifications.css";
import {
  FaEdit,
  FaLock,
  FaLockOpen,
  FaPencilAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { Table, Tag, Pagination } from "antd";
import { EditFilled, EditOutlined } from "@ant-design/icons";

const NotificationTable = ({ apiData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const pageSize = 10; // Number of rows per page
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };
  const handleOpenModal2 = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal2 = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick2 = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("Delete-notifications-modal-overlay")) {
      handleCloseModal2();
    }
  };

  // Check if apiData has no items
  if (apiData?.length === 0) {
    return (
      <div className='notifications-table'>
        <p style={{ textAlign: "center" }}>No Notifications available.</p>
      </div>
    );
  }

  const dummyApiData = Array.from({ length: 20 }, (_, index) => ({
    date: `${index + 1}/01/2023`,
  }));

  // Columns configuration
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleRows =
    dummyApiData?.length > 0 && dummyApiData?.slice(startIndex, endIndex);

  const rows = visibleRows?.map((row, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{row.date}</td>
      <td>{"Customer registered"}</td>
      <td>{"New Customer registerd with social_id"}</td>
      <td>{"NA"}</td>
      <td>
        <span className='badge badge-viewMore'>{" Unread "}</span>
      </td>
      <td>
        <div className='action-cell'>
          <div
            className='edit-cell'
            //    onClick={handleOpenModal}
          >
            Read
          </div>
          <div className='edit-cell' style={{ backgroundColor: "#ff4c52" }}>
            <FaTrashAlt />
          </div>
        </div>
      </td>
    </tr>
  ));

  const onChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='notifications-table' onClick={handleOverlayClick}>
      <table>
        <thead>
          <tr>
            <th style={{ minWidth: "60px" }}>S.No</th>
            <th>Date</th>
            <th style={{ minWidth: "180px" }}>Title</th>
            <th style={{ minWidth: "360px" }}>Message</th>
            <th>Contact</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "baseline",
          gap: "15px",
          marginTop: "1rem",
        }}>
        <div>
          <p>Items per page: {pageSize}</p>
        </div>
        <div>
          <Pagination
            onChange={onChange}
            simple
            total={dummyApiData.length}
            pageSize={pageSize}
            // style={{ marginTop: 10, float: "right" }}
          />
        </div>
      </div>
      <div>
        {isModalVisible &&
          {
            /* <EditSubCategory handleCloseModal={handleCloseModal} /> */
          }}
      </div>
    </div>
  );
};

export default NotificationTable;
