import React, { useState } from "react";
import { CloudUploadOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Form, Col, Row, Button, Input, Select, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./category.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";

const AddCategory = ({ handleCloseModal, fetchGarageList,open }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [loadingBike, setLoadingBike] = useState(false);
  const [imageUrlBike, setImageUrlBike] = useState();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [error, setError] = useState("");

  const { Option } = Select;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChangeBike = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // setLoadingCar(true);

      // Get the image URL from the API response
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("https://api.vecrep.com/uploadImage", formData);

      // Assuming the API response contains the image URL
      const imageUrl = response.data.imagePath1;
      // Update the state with the image URL
      setImageUrlBike(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      // setLoadingCar(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}>
        Upload
      </div>
    </div>
  );

  const handleChangeCar = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoadingCar(true);

      // Get the image URL from the API response
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("https://api.vecrep.com/uploadImage", formData);

      // Assuming the API response contains the image URL
      const imageUrl = response.data.imagePath1;
      // Update the state with the image URL
      setImageUrlCar(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoadingCar(false);
    }
  };

  const handleSubmit = async (values) => {
    // Handle form submission logic here
    console.log("Form submitted!");
    console.log(values);
    const vehicleType =
      values?.vehicletype === "bikes"
        ? 2
        : values?.vehicletype === "cars"
        ? 1
        : 3;

    const token = localStorage.getItem("admin");
    const requestData = {
      categoryImage:imageUrlCar,
      vehicleType: vehicleType,
      categoryName: values.mainCategoryName,
      categoryDescription: values.mainCategoryDescription,
    };
    try {
      const response = await fetch(
        `${ApiDomain.Domain}/addCategoryAdmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );
        const data = await response.json();
console.log(data.responseCode)
      if (data.responseCode===200) {
        fetchGarageList();
        console.log("API response:", data);
        message.success("Category Added Successfully");
        handleCloseModal();
      } else {
        console.error("Error fetching data from the API");
        message.warning(data.message)
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error);
    }
  };

  const handleCancel = () => {
    // Handle cancel logic here
    console.log("Form cancelled!");
    handleCloseModal();
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const handleVehicleChange = (value) => {
    console.log(`Selected: ${value}`);
    // You can perform additional actions based on the selected value
  };

  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal} width={"auto"}>
      <div className=''>
        <div className=''>
          <div className='add-category-modal-content-header'>
           
            <div>
              <h3 style={{ color: "#b11226" }}>Add Category</h3>
            </div>
          </div>
          <div className='add-category-modal-form'>
            <Form
              {...formItemLayout}
              form={form}
              name='register'
              style={{
                minWidth: 600,
              }}
              scrollToFirstError
              onFinish={handleSubmit}>
              <Form.Item
                name='vehicletype'
                label='Vehicle Type '
                rules={[
                  {
                    required: true,
                    message: "Please Select Vehicle Type",
                  },
                ]}>
                <Select
                  showSearch
                  style={{ height: 40 }}
                  placeholder='Select Type'
                  optionFilterProp='children'
                  onChange={handleVehicleChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  <Option value='cars'>Cars</Option>
                  <Option value='bikes'>Bikes</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='carImage'
                label='Category Image : '
                rules={[
                  {
                    // required: true,
                    message: "Please upload Category Image",
                  },
                ]}>

<div
                  name='categoryImage'
                  listType='picture-card'
                  className='avatar-uploader'
                  >
                  {imageUrlBike ? (
                    < div style={{display:"flex",gap:10}}>
                    <img
                      src={imageUrlBike}
                      alt='bike-avatar'
                      style={{ width: "20%" }}
                    />
                    <div class="upload">
                    <button type = "button" class = "btn-warning">
                    <CloudUploadOutlined /> Replace File
                      <input type="file" onChange={handleChangeBike}/>
                    </button>
              </div>
              </div>
                  ) : (
                    <>
                   

                    <div class="upload">
                    <button type = "button" class = "btn-warning">
                    <CloudUploadOutlined /> Upload File
                      <input type="file" onChange={handleChangeBike}/>
                    </button>
              </div>
              </>
                  )}
                </div>
 
                          
              </Form.Item>
              <Form.Item
                name='mainCategoryName'
                label='Main Category Name'
                rules={[
                  {
                    required: true,
                    message: "Please Enter Category Name",
                  },
                ]}>
                <TextArea placeholder='Enter Category Name' rows={3} />
              </Form.Item>

              <Form.Item
                name='mainCategoryDescription'
                label='Main Category Description'>
                <Input.TextArea
                  placeholder='Enter Category Description'
                  rows={4}
                />
              </Form.Item>
              <div className='add-category-popup-footer'>
                <button
                  className='cancel-button'
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}>
                  Cancel
                </button>
                <button className='submit-button' htmlType='submit'>
                  Submit
                </button>
              </div>
            </Form>
          </div>

         
        </div>
      </div>
    </Modal>
  );
};

export default AddCategory;
