import React, { useEffect, useState } from "react";
import "./role.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CategoryForm from "./Categoryform";
import CategoryTable from "./Categorytable";
import AddCategory from "./Addcategory";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";

const Roles = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
 const [load,setLoad]=useState(false)
  const fetchstart=()=>{
    setLoad(true)
  }
  const fetchsuccess=()=>{
    setLoad(false)
  }

  const fetchGarageList = async () => {
    fetchstart()
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/adminRoles`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data);

      if (response.status===200){
        
        setGarageList(response.data);
        
        fetchsuccess()
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-role-modal-overlay")) {
      handleCloseModal();
    }
  };
  console.log(garageList.data)

  // console.log(fetchGarageList);
  return (
    <div className='role-container-main' onClick={handleOverlayClick}>
      <div className='role-content'>
        <div className='role-header'>
          <h3 className='role-head'>Roles Management</h3>
          <ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>fetchGarageList()} />

          <button
            className='add-role'
            onClick={handleOpenModal}
            style={{ cursor: "pointer" }}>
            Add Roles
          </button>
        </div>
        <CategoryTable
          garageList={garageList}
          fetchGarageList={fetchGarageList}
          load={load}
        />
      </div>
      <div>
        {isModalVisible && (
          <AddCategory
          open={isModalVisible}
            handleCloseModal={handleCloseModal}
            fetchGarageList={fetchGarageList}

          />
        )}
      </div>
    </div>
  );
};

export default Roles;
