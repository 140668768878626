import React, { useEffect, useState } from "react";
import "./Sale.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import CategoryForm from "./Categoryform";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';

const CategoryTable = ({ garageList, load, fetchGarageList,count ,param}) => {
  console.log(garageList)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);
  const [id, setId] = useState("");
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList?.filter((item) => { 
        // Filtering logic based on fromDate, toDate, status, and search
        const formattedDate = moment(item?.chooseDate, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
  
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          formattedDate.isSameOrAfter(moment(filterCriteria.fromDate, "YYYY-MM-DD"), 'day');
  
        const toDateMatch =
          !filterCriteria.toDate ||
          formattedDate.isSameOrBefore(moment(filterCriteria.toDate, "YYYY-MM-DD"), 'day');
  
       
        const searchMatch =
          !filterCriteria.search ||
          item.customerName.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||   
                 (item.billNumber &&
            item.billNumber.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
            (item.vehicleNumber &&
              item.vehicleNumber.toLowerCase().includes(filterCriteria.search.toLowerCase()))||
              (item.totalDiscount &&
                item.totalDiscount.toLowerCase().includes(filterCriteria.search.toLowerCase()))||          (item.totalRates &&
                  item.totalRates.toLowerCase().includes(filterCriteria.search.toLowerCase()))
                  (item.totalAmounts &&
                    item.totalAmounts.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
                    (item.totalTax &&
                      item.totalTax.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
                      (item.garageName &&
                        item.garageName.toLowerCase().includes(filterCriteria.search.toLowerCase()))
        return fromDateMatch && toDateMatch  && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.chooseDate) - new Date(a.chooseDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };


  // Calculate start and end index for visible rows
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  // Populate visibleRows array with data for the current page
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const modalV=(index)=>{
    setModal(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);

  }
  console.log(selectedGarage)
  const cancelV=()=>{
    setModal(false)
  }
  const modalX=(index)=>{
    setModalXV(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const cancelx=()=>{
    setModalXV(false)
  }

  const handleClick = (url) => {
    // Open the PDF file in a new tab
    window.open(url, '_blank');
  };

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleExportExcel = () => {
    const formattedData = garageList .map((item, index) => {
        const formattedDate = moment(item?.chooseDate).format(
          "DD-MM-YYYY"
        );

        return [
            startIndex + index + 1,
            formattedDate || "-",
            item.billNumber || "-",
            item.garageName || "-",

            item.customerName || "-",
            item.vehicleNumber || "-",
            item.totalRates || "-",
            item.totalDiscount || "-",
            item.totalTax || "-",
            item.totalAmounts || "-",
            item.invoiceURL || "-"
        ];
    });

    // Add header row
    formattedData.unshift([
        "S.No",
        "Date",
        "Bill No",
        "Garage Name",
        "Customer Name",

        "Vehicle Number",
        "Subtotal (₹)",
        "Discount (₹)",
        "GST (₹)",
        "Grand Total (₹)",
        "Invoice"
        
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
};

  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };

  function cancel(e) {}

  console.log(count);
  console.log(filteredGarageList);

  return (
    <>
      <Spin spinning={load}>
        <CategoryForm onSearch={handleFilterChange} Csv={handleExportExcel} param={param} fetchGarageListed={fetchGarageList} />
        {!load && (
          <div className="sale-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Date</th>
                  <th className="th3">Bill No</th>
                  <th className="th4">Garage Name</th>

                  <th className="th4">Customer Name</th>
                  <th className="th5">Vehicle Number</th>
                  <th className="th6">Subtotal &#x20b9;</th>
                  <th className="th7">Discount &#x20b9;   </th>
                  <th className="th8">Gst &#x20b9; </th>
                  <th className="th9">GrandTotal &#x20b9;</th>
                  <th className="th11">View Invoice</th>
                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Sale Invoice List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");
                    const formattedDate = moment(item?.chooseDate).format(
                      "DD-MM-YYYY"
                    );

                    console.log(visibleRows);

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        <td>{formattedDate || "-"}</td>
                        <td>{item.billNumber || "-"}</td>
                        <td>{item.garageName || "-"}</td>

                        
                        <td onClick={(e)=>modalV(index)} style={{textDecoration:"underline",cursor:"pointer"}}>{item.customerName }</td>
                        <td onClick={(e)=>modalX(index)} style={{textDecoration:"underline",cursor:"pointer"}}>{item.vehicleNumber}</td>
                        <td>{item.totalRates || "-"}</td>
                        <td>{item.totalDiscount || "-"}</td>
                        <td>{item.totalTax || "-"}</td>
                        <td>{item.totalAmounts || "-"}</td>
                        <td style={{cursor:"pointer"}}>
                        {item.invoiceURL===""?(

<span style={{textAlign:"center"}}
>
  -
</span>
                        ):(
                       
                          <span
                          className="badge badge-success"
                          onClick={() => handleClick(item.invoiceURL)}
                        >
                          {"View"}
                        </span>
                  )}
                        </td>
                    
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modal && (
    <Modal footer={null} centered visible={modal} onCancel={cancelV}>
    <div className="">
                    <div className="add-sale-manage-modal-content-header">
                     
                      <div>
                        <h3 style={{ fontWeight: "bold",color:"#b11226" }}>Customer Details</h3>
                      </div>
                    </div>
                    <div
                      className="add-sale-manage-modal-forms"
                      style={{ width: 500,display:"flex",flexDirection:"row",}}
                    >
                      <div style={{}}>
                        <img  style={{width:80,borderRadius:10}} src={selectedGarage.customerImage}  />
                      </div>
                      <div style={{textAlign:"",padding:"0px 20px 0px 20px"}}>
                        <div> 
                          <p style={{paddingLeft:27,fontWeight:"bold",marginBottom:"0.5em"}}>Booking Id:<span style={{fontSize:15,padding:20}}>{selectedGarage.bookingId || "-"}</span></p>
                          <p style={{paddingLeft:32,fontWeight:"bold",marginBottom:"0.5em"}}>Full Name:<span style={{fontSize:15,padding:20}} >{selectedGarage.customerName || "-"}</span></p>
                          <p style={{paddingLeft:0,fontWeight:"bold",marginBottom:"0.5em"}}>Phone Number: <span style={{fontSize:15,padding:18}}>{selectedGarage.phone?selectedGarage.phone:"-"}</span></p>
                          <p style={{paddingLeft:60 ,fontWeight:"bold",marginBottom:"0.5em"}}>Email: <span style={{fontSize:15,padding:17}}>{selectedGarage.email?selectedGarage.email:"-"}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
            <div>
              {modalXV && (
    <Modal footer={null} centered visible={modalXV} onCancel={cancelx}>
    <div className="">
                    <div className="add-sale-manage-modal-content-header">
                     
                      <div>
                        <h3 style={{ fontWeight: "bold",color:"#b11226" }}>Vehicle Details</h3>
                      </div>
                    </div>
                    <div
                      className="add-sale-manage-modal-forms"
                      style={{ width: 500,padding:15}}
                    >
                    </div>
                    <div style={{textAlign:"",padding:"0px 30px 0px 30px"}}>
                      <div>
                        <p style={{paddingLeft:20,fontWeight:"bold"}}>Vehicle Number:<span style={{fontSize:15,padding:20}}>{selectedGarage?.vehicleNumber || "-"}</span></p>
                        <p style={{paddingLeft:83,fontWeight:"bold"}}>Brand:<span style={{fontSize:15,padding:20}} >{selectedGarage?.brand || "-"}</span></p>
                        <p style={{paddingLeft:83,fontWeight:"bold"}}>Model: <span style={{fontSize:15,padding:18}}>{selectedGarage?.model || "-"}</span></p>
                        <p style={{paddingLeft:93 ,fontWeight:"bold"}}>Year: <span style={{fontSize:15,padding:17}}>{selectedGarage?.year || "-"}</span></p>
                        <p style={{paddingLeft:58,fontWeight:"bold"}}>Fuel Type: <span style={{fontSize:15,padding:17}}>{selectedGarage?.fuelType || "-"}</span></p>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>
        )}
        <Pagination
          onChange={onChange}
          simple
          total={filteredGarageList.length}
          style={{ marginTop: 10, float: "right" }}
          current={currentPage} // Ensure the Pagination component reflects the current page
        />
      </Spin>
    </>
  );
};

export default CategoryTable;
