import React, { useState, useEffect } from "react";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";

import {
  message,
  Upload,
  Form,
  Col,
  Row,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Radio,
  AutoComplete,
  Spin,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./Subadmin.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";

const { Option } = Select;

const AddCategory = ({ handleCloseModal, fetchGarageList,open }) => {
  const [form] = Form.useForm();
  const [load, setLoad] = useState(false);

  const [data, setData] = useState([]);

  const { Option } = Select;

  // Other state variables...

  const accessToken = localStorage.getItem("admin");

  const handleSubmit = async (values) => {
    try {
      // Make an API call
      const response = await fetch(`${ApiDomain.Domain}/subAdmin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({
          ...values,
        }),
      });
      console.log(response);

      if (response.status === 201) {
        // Handle successful API response
        console.log("API call successful");
        fetchGarageList();
        message.success("Subadmin Created successfully");
        handleCloseModal();
      } else {
        // Handle API error
        console.error("API call failed");
        message.warning("Subadmin with this details already created");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoad(false);
    }
  };

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };
  const handleVehicleChange = (value) => {
    console.log(`Selected: ${value}`);
    // You can perform additional actions based on the selected value
  };

  const Features = () => {
    const apiUrl = `${ApiDomain.Domain}/adminRoles`;
    const authToken = localStorage.getItem("admin"); // Replace with your actual authentication token

    // Make a GET request using the Fetch API with the token in the headers
    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        // Other headers if needed
      },
    })
      .then((response) => {
        // Check if the request was successful (status code 200)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON in the response
        return response.json();
      })
      .then((data) => {
        // Handle the data from the API
        setData(data.data);
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        // setError(error.message);
      });
  };

  useEffect(() => {
    Features();
  }, []);

  return (
    <Modal visible={open} onCancel={handleCloseModal} footer={null}>
      <div className="">
        <div className="">
          <div className="">
            
            <div>
              <h3 style={{color:"#b11226",textAlign:"center",fontWeight:"bold"}}>Add SudAdmin</h3>
            </div>
          </div>
          <div
            className="add-garage-manage-modal-forms"
            style={{ maxHeight: "500px", width: 460 }}
          >
            <Spin spinning={load}>
              <div className="parent-select">
                <Form
                  form={form}
                  layout="vertical"
                  style={{ minWidth: "400px" }}
                  onFinish={handleSubmit}
                >
                  <div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Col xl={24} lg={24}>
                        <Form.Item
                          label="Subadmin Name : "
                          name="name"
                          required
                          rules={[
                            {
                              type: "text",
                              message: "The input is not valid!",
                            },
                            {
                              required: true,
                              message: "Please input your Subadmin name!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Your Subadmin Name"
                            style={{  }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xl={24} lg={24}>
                        <Form.Item
                          label="Email : "
                          name="email"
                          required
                          rules={[
                            {
                              type: "email",
                              message: "The email is not valid!",
                            },
                            {
                              required: true,
                              message: "Please input your Eamil!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Your Email"
                            style={{  }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item
                          name="roleId"
                          label="Select Role"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Role",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select Role"
                            optionFilterProp="children"
                            onChange={handleVehicleChange}
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {data
                              .filter((item) => item.status === true)
                              .map((item, index) => {
                                return (
                                  <Option value={item._id}>
                                    {item.roleName}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div className="add-garage-manage-popup-footer">
                    <button
                      className="cancel-button"
                      onClick={handleCancel}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </button>

                    <button className="submit-button" htmlType="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCategory;
