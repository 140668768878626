import React, { useState, useEffect } from "react";
import "./leads.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import BookingCustomerTable from "./LeadsTable";
import { ReloadOutlined } from "@ant-design/icons";

const Leads = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerList, setcustomerList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load, setLoad] = useState(false);
  const fetchstart = () => {
    setLoad(true);
  };
  const fetchsuccess = () => {
    setLoad(false);
  };
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-booking-manage-modal-overlay")) {
      handleCloseModal();
    }
  };
  const fetchcustomerList = async () => {
    try {
        fetchstart();
        const response = await axios.get(
            `${ApiDomain.Domain}/customerManagement`, 
           // Move data to here
            { headers: { Authorization: `Bearer ${token}` } } // Move headers here
        );
        console.log(response.data);
        if (response.status === 200) {
          const list =response?.data?.data
            setcustomerList(list);
            fetchsuccess();
        }
    } catch (error) {
        console.log(error.message);
    }
};

  useEffect(() => {
    fetchcustomerList();
  }, []);

  return (
    <>
      <div></div>
      <div className="booking-manage-container" onClick={handleOverlayClick}>
        <div className="booking-manage-content">
          <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
          <div className="booking-manage-header">
            <h3 className="booking-manage-head">Leads</h3>
            </div>
            <div>
            <ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>fetchcustomerList()} />

          </div>
          </div>
          <div className="booking-manage-card">
            <BookingCustomerTable
              garageList={customerList}
              load={load}
              handleCloseModal={handleCloseModal}
              fetchcustomerList={fetchcustomerList}
            />
          </div>
        </div>
       
      </div>
    </>
  );
};

export default Leads;
