import React, { useState } from "react";
import { Form, Input, Select, DatePicker, message, Modal } from "antd";
import "./category.css";
import ApiDomain from "../../../api/ApiDomain";
const Option = Select.Option;

const AddPromoCode = ({ handleCloseModal, ListPromo,selectedGarage,open }) => {

  console.log(selectedGarage)
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const accessToken = localStorage.getItem("admin");
const [value,setValue]=useState(true)
const id=selectedGarage?._id
const status=selectedGarage?.status



  const valueValidationRules = {
    validator(_, value) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue >= 0) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Value must be greater than or equal to 0"));
    },
  };

  const discountValidationRules = {
    validator(_, value) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Discount must be between 0% and 100%"));
    },
  };

  const validationRules = {
    discount: [discountValidationRules],
    value: [valueValidationRules],
  };
  const inDate = selectedGarage?.startDate
  const inexpiryDate = selectedGarage?.expiryDate
  console.log(inDate,inexpiryDate)

  const handleSubmit = async (values) => {
    setLoading(true);

    // Extract only the date part
    // const startDate = values.startDate.format("YYYY-MM-DD");
    // const expiryDate = values.expiryDate.format("YYYY-MM-DD");


    try {
      // Make an API call
      const response = await fetch(`${ApiDomain.Domain}/updatePromoCode`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({
          ...values,
          promoCodeId:id,
          // startDate,
          // expiryDate,
          status:status
        }),
      });
      console.log(response)

      if (response.status===200) {
        // Handle successful API response
        console.log("API call successful");
        ListPromo();
        message.success("Promo code updated successfully");
        handleCloseModal();
      } else {
        // Handle API error
        console.error("API call failed");
        message.error("Error adding promo code");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
      message.error("Error adding promo code");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };

  const handlePromoCodeTypeChange = (value) => {
    console.log(value)
    if(value === "discount"){
      setValue(true)
    }
    else{
      setValue(false)
    } 
    // Set value based on the condition

    // const rules = setValue
    //   ? [{ validator: discountValidationRules.validator, message: "Discount must be between 0% and 100%" }]
    //   : [{ validator: valueValidationRules.validator, message: "Value must be greater than or equal to 0" }];
  
    // Clear the value when changing type and set new rules
    form.setFieldsValue({
      promoCodeValue: "",
      promoCodeType: value, // If you want to store the promoCodeType value, you can set it here
    });
  
    // Set the rules
    // form.setFields([{ name: "promoCodeValue", rules }]);
  };
  console.log(value)

  
console.log(selectedGarage?.startDate)
  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal}>
      <div className="">
        <div className="">
          <div className="add-promo-modal-content-header">
           
            <div>
              <h3 style={{color:"#b11226",fontWeight:"bold",textAlign:"center"}}>Edit Promo Code</h3>
            </div>
          </div>
          <div className="add-promo-modal-formed">
            <Form {...formItemLayout} form={form} onFinish={handleSubmit}
             initialValues={{ 
              promoCodeType: selectedGarage?.promoCodeType, 
              promoCodeValue: selectedGarage?.promoCodeValue,
              promoCode: selectedGarage?.promoCode,
              startDate: selectedGarage?.startDate, 
              expiryDate: selectedGarage?.expiryDate, 



              // Set the initial value for roleId
            }}
            >
              <Form.Item name="promoCodeType" label="Type">
                <Select 
                  className="gx-mr-3 gx-mb-3"
                  onChange={handlePromoCodeTypeChange}
                >
                  <Option value="discount"> % Discount</Option>
                  <Option value="value">Flat Discount</Option>
                </Select>
              </Form.Item>
              <Form.Item name="promoCodeValue" label="Amount" rules={value?validationRules?.discount:validationRules?.value}>
                <Input />
              </Form.Item>
              <Form.Item name="promoCode" label="Promo code">
                <Input />
              </Form.Item>
              <Form.Item name="startDate" label="Start Date">
                <Input type="date" style={{ width: 205 }} />
              </Form.Item>
              <Form.Item name="expiryDate" label="Expiry Date">
                <Input type="date"  style={{ width: 205 }} />
              </Form.Item>

              <div className="add-promo-popup-footer">
                <button
                  className="cancel-button"
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button className="submit-button" htmlType="submit" loading={loading}>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddPromoCode;
