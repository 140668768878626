import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = (props) => {
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: 'white',
      borderRadius: '4px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Scrollbars
      {...props}
      autoHide
      renderTrackHorizontal={props => (
        <div {...props} style={{ display: 'none' }} className="track-horizontal" />
      )}
      renderThumbVertical={renderThumb}
    />
  );
};

export default CustomScrollbars;
