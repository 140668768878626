import React, { useEffect, useState } from "react";
import "./Jobcard.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import CategoryForm from "./Categoryform";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
const CategoryTable = ({ garageList, load, fetchGarageList,param }) => {
  console.log(garageList)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);

  const [id, setId] = useState("");
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList
      .filter((item) => {
        // Use moment to parse the date in multiple formats
        const formattedDate = moment(item?.registationDate, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
  
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch = !filterCriteria.fromDate || formattedDate.isSameOrAfter(moment(filterCriteria.fromDate, "YYYY-MM-DD"), 'day');
        const toDateMatch = !filterCriteria.toDate || formattedDate.isSameOrBefore(moment(filterCriteria.toDate, "YYYY-MM-DD"), 'day');
        const statusMatch = !filterCriteria.status || (item.status ? "active" : "inactive") === filterCriteria.status;
  
        const searchMatch = !filterCriteria.search || (
          item.customerName?.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          item.invoiceNumber?.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          item.vehicleNumber?.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          item.totalAmounts?.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          item.totalDiscount?.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          item.totalRates?.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          item.totalTax?.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          item.garageName?.toLowerCase().includes(filterCriteria.search.toLowerCase())
        );
  
        return fromDateMatch && toDateMatch && statusMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by registrationDate in descending order
        return new Date(b.registationDate) - new Date(a.registationDate);
      });
  
    setFilteredGarageList(filteredList);
  };
  

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const modalV=(index)=>{
    setModal(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows

  }
  const cancelV=(index)=>{
    setModal(false)
  }
  const modalX=(index)=>{
    setModalXV(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows

  }
  const cancelx=()=>{
    setModalXV(false)
  }


  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  

  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
        const formattedDate = moment(item?.data).format(
          "DD-MM-YYYY"
        );

        return [
            startIndex + index + 1,
            formattedDate || "-",
            item.invoiceNumber || "-",
            item.garageName || "-",

            item.customerName || "-",
            item.vehicleNumber || "-",
            item.totalRates || "-",
            item.totalDiscount || "-",
            item.totalTax || "-",
            item.totalAmounts || "-",
            // Assuming the invoice URL is stored in item.invoiceUrl
            // Adjust this based on your actual data structure
            item.invoiceUrl || "-",
        ];
    });

    // Add header row
    formattedData.unshift([
        "S.No",
        "Date",
        "Invoice Number",
        "Garage Name",


        "Customer Name",
        "Vehicle Number",
        "Subtotal (₹)",
        "Discount (₹)",
        "GST (₹)",
        "Grand Total (₹)",
        "View Invoice",
        
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
};

  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };
  const handleDownload = (url) => {
      // If URL is provided, open PDF in another tab
      window.open(url, '_blank');
    
  };
  


  function cancel(e) {}

  async function confirm(e,  newStatus,subAdminId) {
    console.log(newStatus)
    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/subAdmin/changeStatus`,
        { subAdminId,newStatus},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchGarageList();
        message.success("Status Change Success");
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  const onChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>

    
      

      <Spin spinning={load}>
        <CategoryForm onSearch={handleFilterChange} Csv={handleExportExcel} param={param} fetchGarageListed={fetchGarageList} />
        {!load && (
          <div className="job-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Date</th>
                  <th className="th4">Invoice Number</th>
                  <th className="th4">Garage name</th>

                  <th className="th4">Customer name</th>
                  <th className="th5">Vehicle Number</th>
                  <th className="th7">Subtotal &#x20b9;  </th>

                  <th className="th7">Discount &#x20b9;  </th>

                  <th className="th8">Gst &#x20b9;</th>

                  <th className="th9">GrandTotal &#x20b9;  </th>


                  <th className="th11">View Invoice</th>


                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Sale Invoice List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.data).format(
                      "DD-MM-YYYY"
                    );                    
                    

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        
                        <td>{formattedDate}</td>
                        <td>{item.invoiceNumber}</td>
                        <td>{item.garageName || "-"}</td>


                        <td onClick={(e)=>modalV(index)} style={{textDecoration:"underline",cursor:"pointer"}}>{item.customerName}</td>


                        <td onClick={(e)=>modalX(index)} style={{textDecoration:"underline",cursor:"pointer"}}>{item.vehicleNumber}</td>

                        <td>{item.totalRates}</td>

<td>{item.totalDiscount}</td>
<td>{item.totalTax}</td>


<td>{item.totalAmounts}</td>





             

{item.invoiceUrl===""?(
  <td>
                             <span style={{textAlign:"center"}}
                          >
                            -
                          </span>
                          </td>
                          )
                          :(
                        <td style={{cursor:"pointer"}}>
                          <span
                          className='badge badge-success'
                          onClick={(e) => handleDownload(item.invoiceUrl)}
                          >
                            {"View"}
                          </span>

                        </td>
                          )
                        }
                       
                        
                 
   
                      </tr>

                      
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modal && (
    <Modal footer={null} centered visible={modal} onCancel={cancelV}>
    <div className="">
                   <div className="add-job-manage-modal-content-header">
                    
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"#b11226" }}>Customer Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-job-manage-modal-forms"
                     style={{ width: 500,display:"flex",flexDirection:"row"}}
                   >
                    <div style={{}}>
                    <Avatar size={100} shape="square" src={selectedGarage.customerImage} />


                      </div>
                     

                  
        
                   <div style={{textAlign:"",padding:"0px 20px 0px 20px"}}>
                  
                <div>

                <p style={{paddingLeft:27,fontWeight:"bold",marginBottom:"0.5em"}}>Booking Id:<span style={{fontSize:15,padding:20}}>{selectedGarage.bookingId}</span></p>

                        <p style={{paddingLeft:32,fontWeight:"bold",marginBottom:"0.5em"}}>Full Name:<span style={{fontSize:15,padding:20}} >{selectedGarage.customerName}</span></p>
      

      <p style={{paddingLeft:0,fontWeight:"bold",marginBottom:"0.5em"}}>Phone Number: <span style={{fontSize:15,padding:18}}>{selectedGarage.customerPhoneNumber? selectedGarage.customerPhoneNumber:"-"}</span></p>

      <p style={{paddingLeft:60 ,fontWeight:"bold",marginBottom:"0.5em"}}>Email: <span style={{fontSize:15,padding:17}}>{selectedGarage.customerEmail?selectedGarage.customerEmail:"-"}</span></p>

      
                 </div>
                 </div>
</div>
                 
                 </div>
                 </Modal>
              
              )}
            </div>
            <div>
              {modalXV && (
    <Modal footer={null} centered visible={modalXV} onCancel={cancelx}>
    <div className="">
                   <div className="add-job-manage-modal-content-header">
                    
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"#b11226" }}>vehicle Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-job-manage-modal-forms"
                     style={{ width: 500,padding:15}}
                   >
                    {/* <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                      */}

                  
                   </div>
                   <div style={{textAlign:"",padding:"0px 30px 0px 30px"}}>
                  
                <div>

                <p style={{paddingLeft:20,fontWeight:"bold"}}>Vehicle Number:<span style={{fontSize:15,padding:20}}>{selectedGarage.vehicleNumber}</span></p>

                        <p style={{paddingLeft:83,fontWeight:"bold"}}>Brand:<span style={{fontSize:15,padding:20}} >{selectedGarage.brand?selectedGarage.brand:"-"}</span></p>
      

      <p style={{paddingLeft:83,fontWeight:"bold"}}>Model: <span style={{fontSize:15,padding:18}}>{selectedGarage.modal?selectedGarage.modal:"-"}</span></p>

      <p style={{paddingLeft:93 ,fontWeight:"bold"}}>Year: <span style={{fontSize:15,padding:17}}>{selectedGarage.year?selectedGarage.year:"-"}</span></p>
      <p style={{paddingLeft:58,fontWeight:"bold"}}>Fuel Type: <span style={{fontSize:15,padding:17}}>{selectedGarage.fuelType?selectedGarage.fuelType:"-"}</span></p>

      
                 </div>
                 </div>

                 
                 </div>
                 </Modal>
              
              )}
            </div>

          
           
          </div>
          
        )}
       
       <Pagination
          onChange={(page) => onChange(page)}
          simple
          total={filteredGarageList.length}
          pageSize={itemsPerPage}
          current={currentPage}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default CategoryTable;
