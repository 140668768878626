import React, { useState, useEffect } from "react";
import "./Sale.css";
import "react-datepicker/dist/react-datepicker.css";
import CategoryTable from "./Categorytable";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { Pagination } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

const SaleInvoice = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load, setLoad] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 7 items per page
  const [param, setParam] = useState("");
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1); // Initialize to 1
  const [id,setId]=useState("")

  const fetchstart = () => {
    setLoad(true);
  };

  const fetchsuccess = () => {
    setLoad(false);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-sale-manage-modal-overlay")) {
      handleCloseModal();
    }
  };

  const fetchGarageList = async (garageId) => {
    setId(garageId)
    try {
      fetchstart();
      const response = await axios.post(
        `${ApiDomain.Domain}/adminSaleInvoiceList`,
        {
          garageId: garageId ? garageId : "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setGarageList(response.data.data);
        setCount(response.data.totalCount);
        fetchsuccess();
        console.log(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
      fetchsuccess();
    }
  };

  useEffect(() => {
    fetchGarageList(id);
  }, []); // Add 'page' as a dependency to useEffect


  return (
    <>
      <div></div>
      <div className="sale-manage-container" onClick={handleOverlayClick}>
        <div className="sale-manage-content">
          <div className="sale-manage-header">
            <h3 className="sale-manage-head">Sale Invoice</h3>
            <ReloadOutlined
              style={{
                marginLeft: "10px",
                border: "1px solid black",
                cursor: "pointer",
                color: "black",
                padding: 7,
                borderRadius: 5,
              }}
              onClick={() => fetchGarageList()}
            />
          </div>
          <div className="sale-manage-card">
            <CategoryTable
              garageList={garageList}
              load={load}
              count={count}
              param={setParam}
              handleCloseModal={handleCloseModal}
              fetchGarageList={fetchGarageList}
            />
          </div>
        </div>
        
      </div>
    </>
  );
};

export default SaleInvoice;
