import React, { useState, useEffect } from "react";
import "./Subscription.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { Pagination } from "antd";
import SubscriptionTable from "./Subscriptiontable";
import { ReloadOutlined } from "@ant-design/icons";

const Subscription = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load, setLoad] = useState(false);
  const itemsPerPage = 10; // Display 10 items per page
  const [param, setParam] = useState("");
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);

  const fetchStart = () => {
    setLoad(true);
  };

  const fetchSuccess = () => {
    setLoad(false);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("add-subscription-manage-modal-overlay")) {
      handleCloseModal();
    }
  };

  const fetchGarageList = async (garageId, pageNumber = page) => {
    try {
      fetchStart();
      const response = await axios.post(
        `${ApiDomain.Domain}/listofSubscriptionData`,
        {
          limit: itemsPerPage,
          page: pageNumber,
          garageId: garageId ? garageId : "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setGarageList(response.data.data);
        setCount(response.data.totalCount);
        fetchSuccess();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchGarageList();
  }, [page]);

  const onChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <div></div>
      <div className="subscription-manage-container" onClick={handleOverlayClick}>
        <div className="subscription-manage-content">
          <div className="subscription-manage-header">
            <h3 className="subscription-manage-head">Subscription</h3>
            <ReloadOutlined
              style={{
                marginLeft: "10px",
                border: "1px solid black",
                cursor: "pointer",
                color: "black",
                padding: 7,
                borderRadius: 5,
              }}
              onClick={() => fetchGarageList(param, page)}
            />
          </div>
          <div className="subscription-manage-card">
            <SubscriptionTable
              garageList={garageList}
              load={load}
              count={count}
              param={setParam}
              handleCloseModal={handleCloseModal}
              fetchGarageList={fetchGarageList}
            />
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Subscription;
