import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Drawer, Layout} from "antd";

import SidebarContent from "./SidebarContent";
import {toggleCollapsedSideNav} from "../../appRedux/actions";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import ApiDomain from "../../api/ApiDomain";
import axios from "axios";

const {Sider} = Layout;

const Sidebar = ({act}) => {
  let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const {themeType, navStyle} = useSelector(({settings}) => settings);
  const navCollapsed = useSelector(({common}) => common.navCollapsed);
  const width = useSelector(({common}) => common.width);
  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [data,setData]=useState()
  const dispatch = useDispatch();
  const [profile, setProfile] = useState();
  const [load, setLoad] = useState("");
  const tab=localStorage.getItem("tab1")

 

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  useEffect(() => {
    setSidebarCollapsed(navStyle === NAV_STYLE_MINI_SIDEBAR)
  }, [navStyle])
 



  let drawerStyle = "gx-collapsed-sidebar";

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = "";
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = "gx-custom-sidebar"
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar";
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = "gx-collapsed-sidebar"
  }
  if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
    || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
    drawerStyle = "gx-collapsed-sidebar"
  }
  const fetchGarageList = async () => {
    try {
      setLoad(true);
      console.log(token)
      const response = await axios.get(`${ApiDomain.Domain}/admin_vecrep_dashboard`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (response.status === 200) {
        setGarageList(response?.data?.data);
        console.log(response?.data?.data);
        localStorage.setItem("role",((response?.data)?.data?.role))
        setLoad(false);
      } else {
        // Handle other status codes if needed
        console.log("Unexpected status code:", response.status);
        // Display an error message to the user
        // For example:
        // message.error("Failed to fetch garage list. Please try again later.");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.log("Error:", error.message);
      // Display an error message to the user
      // For example:
      // message.error("Failed to fetch garage list. Please check your internet connection.");
    }
  };
  

  useEffect(() => {
    if (tab === "1") {
      fetchGarageList();
    }
  }, [tab]); // Only re-run the effect when tab changes
  
  useEffect(() => {
    if (tab !== "1") {
      fetchGarageList();
    }
  }, [tab]); // Only re-run the effect when tab changes
  

  



  return (
    <Sider
      className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
      trigger={null}
      collapsed={(width < TAB_SIZE ? false : sidebarCollapsed || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
      theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
      collapsible>
      {
        navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ?
        
          <Drawer
            className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
            placement="left"
            closable={false}
            onClose={onToggleCollapsedNav}
            visible={navCollapsed}>
          
            <SidebarContent sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} garageList={garageList} action={true} fetchGarageList={fetchGarageList} />
            
            </Drawer> :
  
      
          <SidebarContent sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}  garageList={garageList} action={true} fetchGarageList={fetchGarageList}   />
          
  
            }

              

    </Sider>)
    
};


export default Sidebar;
