import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Form, Col, Row, Button, Input, Spin, Select, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./feature.css";
import ApiDomain from "../../../api/ApiDomain";

const EditCategory = ({ handleCloseModal,data,selectedGarage ,ListPromo,open}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {Option}=Select;
const accessToken=localStorage.getItem("admin")

  const handleSubmit =async (values) => {
    setLoading(true);
    console.log(values)

    // Extract only the date part
  const featureId=selectedGarage._id
  console.log(selectedGarage)
    try {
      // Make an API call
      const response = await fetch(`${ApiDomain.Domain}/adminFeatures`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({
          featureId:featureId,...values
        
        }),
      });
      console.log(response)

      if (response.ok) {
        // Handle successful API response
ListPromo()
        console.log("API call successful");
        message.success("Feature Updated successfully");
        handleCloseModal();
      } else {
        // Handle API error
        console.error("API call failed");
        message.error("Error updating Features");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    // Handle cancel logic here
    console.log("Form cancelled!");
    handleCloseModal();
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };
  
  const handlePromoCodeTypeChange = (value) => {

  };
  

  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal}  >
     <div className="">
        <div className="">
          <div className="">
            
            <div>
              <h3 style={{color:"#b11226",fontWeight:"bold",textAlign:"center"}}>Edit Feature</h3>
            </div>
          </div>
          <div className="add-feature-modal-formed">
            <Form {...formItemLayout} form={form} onFinish={handleSubmit} initialValues={true}>
              
              
              <Form.Item name="featureName" label="Feature" required initialValue={selectedGarage?.featureName}>
                <Input  />
              </Form.Item>
              <Form.Item name="endPoint" label="Endpoint" required initialValue={selectedGarage?.endPoint}>
                <Input  />
              </Form.Item>
              <p style={{textAlign:"center"}}>
                  <b>Note:</b>Spaces not use
                </p>
              <Form.Item name="source" label="Portal" required initialValue={selectedGarage?.source} >
                <Select placeholder="Select Portal"
                  className="gx-mr-3 gx-mb-3"
                  onChange={handlePromoCodeTypeChange}
                >
                  <Option value="vrgarage">Vr Garage</Option>
                  <Option value="vecrep">Vecrep.com</Option>
                </Select>
              </Form.Item>
              <Form.Item name="icon" label="Icon" required initialValue={selectedGarage?.icon}>
                <Select placeholder="Select Icon"
                  className="gx-mr-3 gx-mb-3"
                  
                  onChange={handlePromoCodeTypeChange}
                >
                   <Option value="auth-screen">auth-screen</Option>
                  <Option value="dasbhoard">Dashboard</Option>
                  <Option value="crypto">Crypto</Option>
                  <Option value="listing-dbrd">listing-dbrd</Option>
                 
                </Select>
              </Form.Item>
              
           
            

              <div className="add-feature-popup-footer">
                <button
                  className="cancel-button"
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button className="submit-button" htmlType="submit" loading={loading}>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditCategory;