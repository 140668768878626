import React, { useState, useEffect } from "react";
import "./Reward.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { Pagination } from "antd";
import RewardTable from "./Rewardstable";
import { ReloadOutlined } from "@ant-design/icons";

const Reward = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load, setLoad] = useState(false);
  const itemsPerPage = 10; // Display 10 items per page
  const [param, setParam] = useState("");
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);

  const fetchstart = () => {
    setLoad(true);
  };

  const fetchsuccess = () => {
    setLoad(false);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("add-reward-manage-modal-overlay")) {
      handleCloseModal();
    }
  };

  const fetchGarageList = async (garageId) => {
    try {
      fetchstart();
      const response = await axios.post(
        `${ApiDomain.Domain}/listOfRewardPoints`,
        {
        
          garageId: garageId || "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setGarageList(response.data.data);
        setCount(response.data.totalCount);
        fetchsuccess();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchGarageList();
  }, []); // Fetch garage list when page changes



  return (
    <>
      <div></div>
      <div className='reward-manage-container' onClick={handleOverlayClick}>
        <div className='reward-manage-content'>
          <div className='reward-manage-header'>
            <h3 className='reward-manage-head'>Cashback Coins</h3>
            <ReloadOutlined
              style={{
                marginLeft: '10px',
                border: "1px solid black",
                cursor: 'pointer',
                color: "black",
                padding: 7,
                borderRadius: 5,
              }}
              onClick={() => fetchGarageList()}
            />
          </div>
          <div className='reward-manage-card'>
            <RewardTable
              garageList={garageList}
              load={load}
              count={count}
              param={setParam}
              handleCloseModal={handleCloseModal}
              fetchGarageList={fetchGarageList}
            />
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Reward;
