import React, { useState, useEffect } from "react";
import "./Pushnotificarion.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { Pagination } from "antd";
import PushnotificarionTable from "./Pushnotificationstable";
import AddPushnotification from "./AddPushnotification";
import { ReloadOutlined } from "@ant-design/icons";

const Pushnotification = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const [load, setLoad] = useState(false);
  const [param, setParam] = useState("");
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1); // Page state starting from 1
  const itemsPerPage = 10;
  const token = localStorage.getItem("admin");
  const [id,setId]=useState("")

  const fetchstart = () => {
    setLoad(true);
  };

  const fetchsuccess = () => {
    setLoad(false);
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("add-notify-manage-modal-overlay")) {
      handleCloseModal();
    }
  };

  const fetchGarageList = async (garageId) => {
    setId(garageId)
    try {
      fetchstart();
      const response = await axios.post(
        `${ApiDomain.Domain}/listOfPushNotifications`,
        {
         
          garageId: garageId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setGarageList(response.data.data);
        setCount(response.data.totalCount);
        fetchsuccess();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchGarageList(id);
  }, []); // Fetch data whenever the page changes

  
  return (
    <>
      <div className="notify-manage-container" onClick={handleOverlayClick}>
        <div className="notify-manage-content">
          <div className="notify-manage-header">
            <h3 className="notify-manage-head">Push Notifications</h3>
            <ReloadOutlined
              style={{
                marginLeft: "10px",
                border: "1px solid black",
                cursor: "pointer",
                color: "black",
                padding: 7,
                borderRadius: 5,
              }}
              onClick={() => fetchGarageList()}
            />
            <button
              className="add-notify-manage"
              style={{ cursor: "pointer" }}
              onClick={handleOpenModal}
            >
              Add PushNotification
            </button>
          </div>
          <div className="notify-manage-card">
            <PushnotificarionTable
              garageList={garageList}
              load={load}
              count={count}
              param={setParam}
              handleCloseModal={handleCloseModal}
              fetchGarageList={fetchGarageList}
              id={setId}
            />
          </div>
          {isModalVisible && (
            <AddPushnotification
              handleCloseModal={handleCloseModal}
              fetchGarageList={fetchGarageList}
              isModalVisible={isModalVisible}
            />
          )}
        </div>
      
      </div>
    </>
  );
};

export default Pushnotification;
