import React, { useState, useEffect } from "react";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";

import {
  message,
  Upload,
  Form,
  Col,
  Row,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Radio,
  AutoComplete,
  Spin,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./Page.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";

const { Option } = Select;

const AddCategory = ({ handleCloseModal, fetchGarageList, data }) => {
  const [form] = Form.useForm();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [garageList, setGarageList] = useState([]);
  const [load, setLoad] = useState(false);
  const [autoSuggestions, setAutoSuggestions] = useState([]);
  const [state, setState] = useState([]);

  const [apiData, setapiData] = useState([]);
  const history = useHistory();
  const [isGSTExist, setIsGSTExist] = useState(false);
  const [slot, setSlots] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [location, setLocation] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);

  const [filteredGarageList, setFilteredGarageList] = useState([]);

  const { Option } = Select;
  const [generatedUrl, setGeneratedUrl] = useState("");

  const handleFormChange = (changedValues, allValues) => {
    // Construct the URL based on the selected form values
    const { title, vehicleType, categoryType, serviceName, location } = allValues;
    const baseURL = `${ApiDomain.SEO_URL}`;
    const Serv = serviceName?.replace(/\s+/g, "-")?.toLowerCase();
    const loc = location?.replace(/\s/g, "-");

    const constructedUrl = `${baseURL}${loc ? loc : "-"}/${Serv ? Serv : "-"}`;

    setGeneratedUrl(constructedUrl);
    console.log(constructedUrl);
  };

  const token = localStorage.getItem("admin");

  const fetchCategoryList = async (vehicleType) => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/CategoryAdminList`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const categories = response?.data?.categories.filter(
        (category) => category?.vehicleType == vehicleType
      );
      setServiceList(categories);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchServiceList = async (id) => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/category_service_list`,
        {
          categoryId: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data);
      setapiData(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (selectedVehicleType) {
      fetchCategoryList(selectedVehicleType);
    }
  }, [selectedVehicleType]);

  const handleSubmit = async (values) => {
    console.log(values);

    setLoad(true);
    const accessToken = localStorage.getItem("admin");

    // Extract only the date part
    const baseURL = `${ApiDomain.SEO_URL}`;
    const location = values.location;
    const category = values.categoryName;
    const Service = values.serviceName;
    const Serv = Service?.replace(/\s+/g, "-")?.toLowerCase();
    const loc = location.replace(/\s/g, "-");

    const url = `${baseURL}${loc}/${Serv}`;
    console.log(url);

    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/SEOdata`,
        {
          ...values,
          garageId: "",
          FAQContent: [{ title: "", description: "" }],
          WhyVecrepContent: [{ title: "", description: "" }],
          paragraphData: "",
          url: generatedUrl,
          pageType: "SEOPage",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 201) {
        console.log(response.data.data);

        // Handle successful API response
        console.log("API call successful");
        history.push(`/admin/vecrep/editpage/${response?.data?.data?._id}`);
        handleCloseModal();
      } else {
        // Handle API error
        console.error("API call failed");
        message.error("Error adding Feature");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
    } finally {
      setLoad(false);
    }
  };

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };

  const handleVehicleChange = (value) => {
    setSelectedVehicleType(value);
  };

  const handleStateChange = (id) => {
    fetchCities(id);
  };

  const handleCategoryChange = (value) => {
    const selectedCategory = serviceList.find(
      (category) => category.categoryName === value
    );
    if (selectedCategory) {
      fetchServiceList(selectedCategory._id);
    }
  };

  useEffect(() => {
    filterGarageSuggestions();
  }, [selectedVehicleType, garageList]);

  const handleVehicleTypeChange = (e) => {
    setSelectedVehicleType(e.target.value);
  };

  const filterGarageSuggestions = () => {
    const suggestions = garageList.filter(
      (garage) => garage.vehicleType == selectedVehicleType
    );
    setFilteredGarageList(suggestions);
  };

  const fetchServiceStates = async () => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/listOfStates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setState(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchCities = async (id) => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/listOfCities/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLocation(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchServiceStates();
  }, []);

  return (
    <Modal
      footer={null}
      centered
      visible={data}
      onCancel={handleCloseModal}
      width={550}
    >
      <div className="">
        <div className="">
          <div className="add-page-manage-modal-content-header">
            <div>
              <h3 style={{ fontWeight: "bold", color: "#b11226" }}>Add Page</h3>
            </div>
          </div>
          <div
            className="add-page-manage-modal-forms"
            style={{ maxHeight: "500px", overflowY: "scroll", width: 500 }}
          >
            <div className="">
              <Form
                form={form}
                layout="vertical"
                style={{ minWidth: "300px" }}
                onFinish={handleSubmit}
                initialValues={true}
                onValuesChange={handleFormChange} // Add an onChange handler to update the URL
              >
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="title" label="Title">
                          <Input type="text" placeholder="Page Title" />
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="vehicleType" label="Vehicle Type">
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select Vehicle Type"
                            optionFilterProp="children"
                            onChange={handleVehicleChange}
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="1">Car</Option>
                            <Option value="2">Bike</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="categoryType" label="Category">
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select Category"
                            optionFilterProp="children"
                            onChange={handleCategoryChange}
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {serviceList?.map((item, index) => (
                              <Option key={index} value={item?.categoryName}>
                                {item?.categoryName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="serviceName" label="Service">
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select Service"
                            optionFilterProp="children"
                            onChange={handleVehicleChange}
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {apiData?.services?.map((item, index) => (
                              <Option key={index} value={item?.serviceName}>
                                {item?.serviceName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="state" label="State" required={true}>
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select State"
                            optionFilterProp="children"
                            onChange={handleStateChange}
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {state?.map((item, index) => (
                              <Option key={index} value={item._id}>
                                {item.stateName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="location" label="City" required={true}>
                          <Select
                            showSearch
                            style={{ height: 40 }}
                            placeholder="Select City"
                            optionFilterProp="children"
                            onChange={handleVehicleChange}
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {location?.map((item, index) => (
                              <Option key={index} value={item.cityName}>
                                {item.cityName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className="select-extra">
                      <Col xl={24} lg={24}>
                        <Form.Item name="area" label="Area">
                          <Input placeholder="Enter Area" />
                        </Form.Item>
                      </Col>
                    </div>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <span style={{ color: "#b11226", fontWeight: "bold" }}>
                      URL:
                    </span>{" "}
                    <a href={generatedUrl} target="_blank">
                      <span
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        {generatedUrl}
                      </span>
                    </a>
                  </div>
                </Row>

                <div className="add-page-manage-popup-footer">
                  <button className="submit-button" htmlType="submit">
                    Create Page
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddCategory;
