import React, { useEffect, useState } from "react";
import "./Subscription.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
import SubscriptionForm from "./Subscriptionform";
const SubscriptionTable = ({ garageList, load, fetchGarageList,count ,param}) => {
  console.log(garageList)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);
  const [id, setId] = useState("");
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [garageId,setGarageId]=useState("")
  const [data,setData]=useState([])
  const[loads,setLoad]=useState(false)
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList?.filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.startDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.startDate) <= new Date(filterCriteria.toDate);

        

        const searchMatch =
          !filterCriteria.search ||
          item.currentPage.toLowerCase().includes(filterCriteria.search.toLowerCase());

        return fromDateMatch && toDateMatch  && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.chooseDate) - new Date(a.chooseDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };


  // Calculate start and end index for visible rows
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  // Populate visibleRows array with data for the current page
  const visibleRows = filteredGarageList?.slice(startIndex, endIndex);

  const modalV=(index)=>{
    setModal(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);

  }
  console.log(selectedGarage)
  const cancelV=()=>{
    setModal(false)
  }
  const modalX=(index,id)=>{
    setModalXV(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
    // setGarageId(id)
    fetchGarageListSingle(id);

  }
  const cancelx=()=>{
    setModalXV(false)
  }


  const token = localStorage.getItem("admin");
  const fetchGarageListSingle = async (id) => {

    try {
      setLoad(true)
      const response = await axios.post(
        `${ApiDomain.Domain}/listOfPlanDetails`,
        {
          
          garageId:id?id:"",
          // Use the current value of the 'page' state
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response)
      if (response.status === 200) {
        console.log(response.data)

        setData(response.data.data);
        
        // setCount(response.data.totalCount);
        setLoad(false)
        console.log(response.data.data)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  
  // useEffect(() => {
  //   fetchGarageListSingle();
  // }, []);


  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };
  


  const handleExportExcel = () => {
    // Extracting data from table rows
   
    const data = filteredGarageList.map((item, index) => {
      const formattedDate1 = moment(item?.registrationDate).format("DD-MM-YYYY");
      const formattedDate2 = moment(item?.subscriptionDate).format("DD-MM-YYYY");
      const formattedDate3 = moment(item?.expiryDate).format("DD-MM-YYYY");
  
      return {
        "S.No": startIndex + index + 1,
        "Register Date": formattedDate1 || "-",
        "Garage Name": item.garageName || "-",
        "Current Plan": item.currentPlan || "-",
        "Subscribed Date": formattedDate2 === "Invalid date" ? "-" : formattedDate2,
        "Expiry Date": formattedDate3 === "Invalid date" ? "-" : formattedDate3,
        "LifeTime Earning": item.lifetimeEarnings || "-"
      };
    });
  
    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(data);
  
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Save the file
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };
  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };

  function cancel(e) {}

 console.log(count)
 console.log(filteredGarageList)
 function isExpiringSoon(expiryDate) {
  const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const today = new Date();
  const expiry = new Date(expiryDate);
  const difference = expiry.getTime() - today.getTime(); // Difference in milliseconds

  return difference <= SEVEN_DAYS_IN_MS;
}

  return (
    <>

    {/* <Modal visible={modal} onCancel={cancelV}>
      <h1>ssdsds</h1>

    </Modal> */}
      

      <Spin spinning={load}>
        <SubscriptionForm onSearch={handleFilterChange} Csv={handleExportExcel} param={param} fetchGarageListed={fetchGarageList} />
        {!load && (
          <div className="subscription-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Register Date</th>
                  <th className="th4">Garage Name</th>

                  <th className="th4">Current plan</th>


                  <th className="th5">Subscriped Date</th>

                  <th className="th5">Expiry Date </th>
                  <th className="th4">Subscription History</th>

                  <th className="th4">LifeTime Earning</th>


                  









                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Subscription List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate1 = moment(item?.registrationDate).format(
                      "DD-MM-YYYY"
                    );
                    const formattedDate2 = moment(item?.subscriptionDate).format(
                      "DD-MM-YYYY"
                    );
                    const formattedDate3 = moment(item?.expiryDate).format(
                      "DD-MM-YYYY"
                    );

                    console.log(visibleRows)
                    
                    

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        <td>{formattedDate1?formattedDate1:"-"}</td>
                        <td  onClick={(e)=>modalV(index)} style={{textDecoration:"underline",cursor:"pointer"}}>{item.garageName?item.garageName:"-"}</td>



                        <td>{item.currentPlan?item.currentPlan:"-"}</td>
                        <td>{formattedDate2==="Invalid date"?"-":formattedDate2}</td>

                       

                        <td>{formattedDate3==="Invalid date"?"-":formattedDate3}
                          

                          {/* <span
                          className="badge badge-danger"
                          // onClick={() => activeStatus(item)}
                          >
                            {"Expired Soon"}
                          </span> */}
                        </td>
                  

                        <td onClick={(e)=>modalX(index,item.garageId)} style={{textDecoration:"underline",cursor:"pointer",fontWeight:"bold"}}>View</td>

                        <td>{item.lifetimeEarnings}</td>
                      

                     


                        
                        





             

                        
                   
                       
                        
                        
                  

                      </tr>

                      
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modal && (
    <Modal footer={null} centered visible={modal} onCancel={cancelV}>
    <div className="add-subscription-manage-modal-contents">
                   <div className="add-subscription-manage-modal-content-header">
                    
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"#b11226" }}>Garage Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-subscription-manage-modal-forms"
                     style={{ width: 500,display:"flex",flexDirection:"row",}}
                   >
                    <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                     

                  
              
                   <div style={{textAlign:"",padding:"0px 20px 0px 20px"}}>
                  
                <div>

                {/* <p style={{paddingLeft:27,fontWeight:"bold",marginBottom:"0.5em"}}>Booking Id:<span style={{fontSize:15,padding:20}}>{selectedGarage?.garageId?selectedGarage?.bookingId:"-"}</span></p> */}

                        <p style={{paddingLeft:32,fontWeight:"bold",marginBottom:"0.5em"}}>Full Name:<span style={{fontSize:15,padding:20}} >{selectedGarage?.garageName?selectedGarage?.garageName:"-"}</span></p>
      

      <p style={{paddingLeft:0,fontWeight:"bold",marginBottom:"0.5em"}}>Phone Number: <span style={{fontSize:15,padding:18}}>{selectedGarage.phone?selectedGarage.phone:"-"}</span></p>

      <p style={{paddingLeft:60 ,fontWeight:"bold",marginBottom:"0.5em"}}>Email: <span style={{fontSize:15,padding:17}}>{selectedGarage.email?selectedGarage.email:"-"}</span></p>

      
                 </div>
                 </div>

                 </div>
                 </div>
                 </Modal>
              
              )}
            </div>
            <div>
              {modalXV && (
    <Modal footer={null} centered visible={modalXV} onCancel={cancelx}>
    <div className="subscription-manage-table">
                   <div className="add-subscription-manage-modal-content-header">
                    
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"#b11226" }}>Subscription Details</h3>
                     </div>
                   </div>
                  
              
                   <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th4">Plan Name</th>
                  <th className="th5">Start Date </th>
                  <th className="th5">Expiry Date </th>
                  <th className="th4">View Invoice</th>


                  









                </tr>
              </thead>

              <tbody>
                {data.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Subscription History.
                    </td>
                  </tr>
                ) : (
                  data?.map((item, index) => {
                    // const words = item?.garageName?.split(" ");

                    const formattedDate1 = moment(item?.startDate).format(
                      "DD-MM-YYYY"
                    );
                    const formattedDate2 = moment(item?.endDate).format(
                      "DD-MM-YYYY"
                    );

                    console.log(formattedDate2)
                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        <td>{item.planName}</td>
                        <td>{formattedDate1}</td>
                        <td>{formattedDate2==="Invalid date"?"-":formattedDate2}</td>



                      

                        <td style={{cursor:"pointer"}}>
                          <span
                          className="badge badge-success"
                          // onClick={() => activeStatus(item)}
                          >
                            {"Download"}
                          </span>
                        </td>


                        
                        





             

                        
                   
                       
                        
                        
                  

                      </tr>

                      
                    );
                  })
                )}
              </tbody>
            </table>
                 
                 </div>
                 </Modal>
              
              )}
            </div>

          
           
          </div>
          
        )}
         
         <Pagination
          onChange={(page) => onChange(page)}
          simple
          total={filteredGarageList.length}
          pageSize={itemsPerPage}
          current={currentPage}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default SubscriptionTable;
