import React, { useState } from "react";
import "./role.css";
import { Popover } from "antd";
import { FaSearch, FaTimes } from "react-icons/fa";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const CategoryForm = ({ onSearch }) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const [minimumToDate, setMinimumToDate] = useState(null);
const role=localStorage.getItem("role")
  
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
    setMinimumToDate(null);
  };

 
  return (
    <div>
      <div className='role-card-header'>
        <form className='role-form-control'>
          {/* <div className='category-input'>
            <label htmlFor='fromdate' style={{fontWeight:"bold"}}>From Date</label>
            <input
              type='date'
              id='fromdate'
              name='fromdate'
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder='From Date'
              className='category-input-cal'
            />
          </div>
          <div className='category-input'>
            <label htmlFor='todate' style={{fontWeight:"bold"}}>To Date</label>
            <input
              type='date'
              id='todate'
              name='todate'
              value={selectedToDate}
              onChange={handleToDateChange}
              placeholder='To Date'
              className='category-input-cal'
              disabled={disableToDate}
              min={minimumToDate}
              title={disableToDate ? "Please select From Date first" : ""}
            />
          </div> */}
          <div className='role-input'>
            {/* <label htmlFor='status' style={{fontWeight:"bold"}} >Role Status</label> */}
            <select
              value={status}
              name='status'
              onChange={handleStatusChange}
              className='role-input-cal'>
              <option value='' disabled>
                Choose an option
              </option>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>
          <div className='role-input-search'>
            {/* <label htmlFor='search' style={{fontWeight:"bold"}}>Search</label> */}
            <input
              type='search'
              name='search'
              placeholder='Search...'
              className='search-input'
              onChange={handleSearchChange}
            />
          </div>
          {role ==="admin" &&(

          <div>
            <Popover content={"Clear Filters"}
      trigger="hover"
    
            >
            <button
              className='role-form-button'
              type='button'
              onClick={handleClear}>
            <CloseOutlined />
            </button>
            </Popover>
          </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
