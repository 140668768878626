import React, { useState, useEffect } from "react";
import { message, Form, Input, Checkbox, Row, Col, Select, Modal } from "antd";
import "./role.css";
import ApiDomain from "../../../api/ApiDomain";

const EditCategory = ({ handleCloseModal, fetchGarages, selectedGarage ,open}) => {
  const [form] = Form.useForm();
  const [dataCheck, setDataCheck] = useState([]);
  const accessToken = localStorage.getItem("admin");
  const [data,setData]=useState([])


  const[filterData,setFilterData]=useState([])
  const {Option}=Select;
  const [selectedSource, setSelectedSource] = useState(selectedGarage.source);
 
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${ApiDomain.Domain}/singleAdminRole`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ roleId: selectedGarage._id }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("API response:", result.data);
        setDataCheck(result?.data?.features);
      } else {
        console.error("API call failed. Status:", response.status);
        message.error("Error fetching data from the API");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
   

    // Call the fetchData function
    fetchData();
  }, [accessToken, selectedGarage?._id]);

  const handleCheckboxChange = (featureName, featureId, checked) => {
    // Update the state variable dataCheck based on the checked status
    const updatedDataCheck = dataCheck.map(item => {
      if (item.featureName === featureName) {
        return { ...item, checked }; // Update the checked status for the specific item
      }
      return item;
    });
    setDataCheck(updatedDataCheck);
  
    // Set the checkbox value using form.setFieldsValue
    form.setFieldsValue({ [featureName]: checked });
  };
  

  const Features = () => {
    const apiUrl = `${ApiDomain.Domain}/adminFeatures`;
    const authToken = localStorage.getItem("admin"); // Replace with your actual authentication token

    // Make a GET request using the Fetch API with the token in the headers
    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        // Other headers if needed
      },
    })
      .then((response) => {
        // Check if the request was successful (status code 200)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON in the response
        return response.json();
      })
      .then((data) => {
        // Handle the data from the API
        setData(data.data);
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        // setError(error.message);
      });
  };

  useEffect(() => {
    Features();
  }, []);
  console.log(dataCheck)


  const handleSubmit = async (values) => {
    console.log(values);
  
    // If no features are selected, initialize an empty array
    let featuresList = [];
    
    // Check if any features are selected
    const anyFeatureSelected = data.some(item => form.getFieldValue(item.featureName));
    
    // If any feature is selected, create the features list
    if (anyFeatureSelected) {
      featuresList = data
        .filter((item) => form.getFieldValue(item.featureName))
        .map((item) => ({
          featureId: item._id,
          privileges: item.privileges.map((privilege) => ({ privilegesType:"Read" })),
        }));
    }
  
    const payload = {
      roleName: values.roleName,
      source: values.source,
      features: featuresList,
      roleId: selectedGarage._id
    };
  
    console.log('API call payload:', payload);
  
    const token = localStorage.getItem("admin");
  
    try {
      const response = await fetch(
        `${ApiDomain.Domain}/adminRoles`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        fetchGarages();
        fetchData();
        console.log("API response:", data);
        message.success("Roles Updated Successfully");
        handleCloseModal();
      } else {
        console.error("Error fetching data from the API");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 20 },
      sm: { span: 12 },
    },
  };


  const handleVehicleChange = (value) => {
    console.log(`Selected: ${value}`);
  
    // You can perform additional actions based on the selected value
      setSelectedSource(value);
      const filteredData = dataCheck.filter(item => item.source === selectedSource);
    setFilterData(filteredData);
    console.log(filteredData)
    
  };
  const filteredDataVecrep = dataCheck.filter(item => item.source === "vecrep");
  const filteredDataVrgarage =dataCheck?.filter(item => item.source === "vrgarage");
console.log(filteredDataVecrep)
console.log(filteredDataVrgarage)


  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal} width={700}>
      <div className="">
        <div className="">
          <div className="">
          
            <div>
              <h3 style={{ color: "#b11226" ,textAlign:"center",fontWeight:"bold"}}>Edit Role</h3>
            </div>
          </div>
          <div className="add-role-modal-form">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              style={{
                minWidth: 600,
              }}
              initialValues={true}
              scrollToFirstError
              onFinish={handleSubmit}
            >
              <div  style={{display:"flex"}}>
              <Form.Item
                name="roleName"
                label="Role Name"
                initialValue={selectedGarage?.roleName}
                rules={[
                  {
                    message: "Please Enter Role Name",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item initialValue={selectedGarage.source}
                name='source'
                label='Portal'
                rules={[
                  {
                    message: "Please Enter Role Name",
                  },
                ]}>
   <Select  disabled
                                showSearch
                                style={{ height: 40,width:150 }}
                                placeholder="Select Type"
                                optionFilterProp="children"
                                onChange={handleVehicleChange}
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="vrgarage" >
                                  Vr Garage
                                </Option>
                                <Option value="vecrep">Vecrep.com</Option>
                              </Select>
              </Form.Item>
              </div>
              <h2
                style={{
                  textAlign: "center",
                  color: "black",
                  fontWeight: "bold",
                  padding: 20,
                }}
              >
                Select Features
              </h2>
              {data.length === 0 ? (
                <p style={{textAlign:"center"}}>No features available</p>
              ) : (
                <Row style={{ width: 750 }}>
  {selectedSource === 'vrgarage' && (
    filteredDataVrgarage.map((item, index) => (
      <Col key={index} span={8}>
        <Form.Item 
          name={item.featureName}
          valuePropName="checked"

          initialValue={item.status} // Set initial value here
        > 
          <Checkbox  checked={item.status}
            onChange={(e) =>
              handleCheckboxChange(item.featureName, item.featureId, e.target.checked)
            }
          >
            {item.featureName}
          </Checkbox>
        </Form.Item>
      </Col>
    ))
  )}
  {selectedSource === 'vecrep' && (
    filteredDataVecrep.map((item, index) => (
      <Col key={index} span={8}>
        <Form.Item
          name={item.featureName}
          valuePropName="checked"
          initialValue={item.status} // Set initial value here
        >
          <Checkbox checked={item.status}
            onChange={(e) =>
              handleCheckboxChange(item.featureName, item.featureId, e.target.checked)
            }
          >
            {item.featureName}
          </Checkbox>
        </Form.Item>
      </Col>
    ))
  )}
</Row>

              )}

              <div className="add-role-popup-footer">
                <button
                  className="cancel-button"
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </button>
                <button className="submit-button" htmlType="submit">
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default EditCategory