import React, { useEffect, useState } from "react";
import "./Subadmin.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import CategoryForm from "./Categoryform";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
const CategoryTable = ({ garageList, load, fetchGarageList }) => {
  console.log(garageList)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState([]); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [modalXV1,setModalXV1]=useState(false)
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: false,
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList
      .filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.registationDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.registationDate) <= new Date(filterCriteria.toDate);

        
        const searchMatch =
          !filterCriteria.search ||
          item.garageName.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          (item.vecrepId &&
            item.vecrepId.toLowerCase().includes(filterCriteria.search.toLowerCase())) 
            

        return fromDateMatch && toDateMatch  && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.registationDate) - new Date(a.registationDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const handleOpenModal = (index) => {
    console.log(index)
    setIsModalVisible(true);
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    console.log("datsggg",visibleRows[index])

  };
  console.log(selectedGarage)

  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };


  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item?.createdAt).format(
        "DD-MM-YYYY"
      );
      return [
        index + 1,
        item.vecrepId || "-",

        item.garageName || "-",
        item.garagePhoneNumber || "-",

        item.total_Amount || "-",
        item.date || "-",
      ];
    });
  
    // Add header row
    formattedData.unshift(["S.No", "ID", "Garage Name", "Phone Number", "Amount","Date"]);
  
    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);
  
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Save the file
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };
  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };
  function cancel(e) {}

  async function confirm(e,  newStatus,subAdminId) {
    console.log(newStatus)
    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/subAdmin/changeStatus`,
        { subAdminId,newStatus},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchGarageList();
        message.success("Status Change Success");
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  
  const modalX1 = (index, id) => {
    setModalXV1(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage);
    console.log(selectedGarage.bank)

  };
  const cancelx=()=>{
    setModalXV1(false)
  }
  const handleClick = (url) => {
    // Open the PDF file in a new tab
    window.open(url, '_blank');
  };

  return (
    <>
    
      <Spin spinning={load}>
        <CategoryForm onSearch={handleFilterChange} Csv={handleExportExcel} />
        {!load && (
          <div className="garage-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th5">Garage ID</th>
                  <th className="th3">Garage Name</th>
                  <th className="th3">Phone Number</th>
                  <th className="th3">Credited Date</th>
                  <th className="th3">Credited Amount</th>


                  <th className="th3">Bank Details</th>
                  <th className="th11">InVoice</th>
                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Credited List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.date).format(
                      "DD-MM-YYYY"
                    );

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        
                        <td>{item.vecrepId || "-"}</td>

                        <td>{item.garageName ||"-"}</td>
                        <td>{item.garagePhoneNumber ||"-"}</td>


                        <td>{formattedDate||"-"}</td>
                        <td>{item.total_Amount || "-"}</td>
                        <td onClick={(e) => modalX1(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                          <span style={{ paddingRight: 5 }}></span>

                        </td>

                        <td style={{cursor:"pointer"}}>
                        {item.invoice===""?(

<span style={{textAlign:"center"}}
>
  -
</span>
                        ):(
                       
                          <span
                          className="badge badge-success"
                          onClick={() => handleClick(item.invoice)}
                        >
                          {"View"}
                        </span>
                  )}
                        </td>
                        
                      
                       

     
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modalXV1 && (
                <Modal footer={null} centered visible={modalXV1} onCancel={cancelx} width={"auto"}>
                  <div className="garage-manage-table">
                    <div className="add-subscription-manage-modal-content-header">

                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Bank Details
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Holder Name</th>
                          <th className="th4">Bank Name</th>
                          <th className="th4">Branch</th>

                          <th className="th5">IFSC Code</th>
                          <th className="th5">AC Number</th>
                        </tr>
                      </thead>

                      <tbody>
                        {selectedGarage?.bank?.length === 0  ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Bank Details History.
                            </td>
                          </tr>
                        ) : (
                          selectedGarage?.bank?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(
                              item?.startDate
                            ).format("DD-MM-YYYY");
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );

                            console.log(formattedDate2);
                            return (
                              <tr key={item._id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.nameAsPerBankAccount}</td>
                                <td>{item.bankName}</td>
                                <td>{item.branch}</td>
                                <td>{item.ifscCode}</td>

                                <td>{item.accountNumber}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </Modal>
              )}
            </div>
       
           
          </div>
          
        )}
         <Pagination
              onChange={onChange}
              simple
              current={currentPage}
              total={filteredGarageList.length}
              pageSize={itemsPerPage}
              style={{ marginTop: 10, float: "right" }}
            />
      </Spin>
    </>
  );
};

export default CategoryTable;
