import React, { useState, useEffect } from "react";
import "./ourGarage.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../api/ApiDomain";
import CategoryTable from "./CategoryTable";
import { ReloadOutlined } from "@ant-design/icons";
import { Pagination } from "antd";

const OurGarage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const [load,setLoad]=useState(false)
  const itemsPerPage=10;
  const [count,setCount]=useState("")
  const token = localStorage.getItem("admin");
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-approve-approve-modal-overlay")) {
      handleCloseModal();
    }
  };
  const fetchGarageList = async () => {
    setLoad(true)
    try {
      const response = await axios.get(`${ApiDomain.Domain}/onlineGarages`, 
      
      {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status===200){
        
        setGarageList(response.data.data);
        setCount(response?.data?.totalCount)
        setLoad(false)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);

  return (
    <div className='approve-approve-container' onClick={handleOverlayClick}>
      <div className='approve-approve-content'>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
        <div className='approve-approve-header'>
          <h3 className='approve-approve-head'>Our Garage</h3>

          
        </div>
        <div>
        <ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>fetchGarageList()} />

        </div>
        </div>
        <div className='approve-approve-card'>
          <CategoryTable
            garageList={garageList}
            fetchGarageList={fetchGarageList}
            load={load}
          />
        </div>
      </div>
      
    </div>
  );
};

export default OurGarage;
