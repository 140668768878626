import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Input, Form, Button, Space, message } from 'antd';
import { BackwardOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { Link, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import ApiDomain from '../api/ApiDomain';
import MyRichTextEditor from './main/testing';

const SinglePage = () => {
  const [selectedOption, setSelectedOption] = useState('option1');
  const [inputValue, setInputValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [data, setData] = useState('Hello');
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [form] = Form.useForm();
  const [numFields, setNumFields] = useState(1); // State to track the number of fields
const [para,setPara]=useState("")
const location = useLocation();
const {pageId}=useParams();
console.log(pageId)
const  Data = location?.state;
console.log(Data?.url)
const [load,setLoad]=useState(false)
const [fAq,setFqQ]=useState([])
const [why,setWhy]=useState([])
const [WhyVecrepContent,setWhyvecrep]=useState("")

  const [singleData, setSingleData] = useState({
    WhyVecrepContent: [{ title: '', description: '' }],
  });

const accessToken=localStorage.getItem("admin")
 

const [listData, setListData] = useState([{ title: '', description: '' }]);

const handleAddField = () => {
  setSingleData(prevState => ({
    ...prevState,
    WhyVecrepContent: [...prevState?.WhyVecrepContent, { title: '', description: '' }]
  }));
};
const handleInputChange = (e, index) => {
  const { name, value } = e.target;
  const updatedWhyVecrepContent = [...singleData.WhyVecrepContent];
  updatedWhyVecrepContent[index][name] = value;
  setSingleData(prevState => ({ ...prevState, WhyVecrepContent: updatedWhyVecrepContent }));
};
const handleRemoveField = (index) => {
  const updatedWhyVecrepContent = [...singleData?.WhyVecrepContent];
  updatedWhyVecrepContent.splice(index, 1); // Remove the item at the specified index
  setSingleData(prevState => ({
    ...prevState,
    WhyVecrepContent: updatedWhyVecrepContent
  }));
  form.setFieldsValue({ WhyVecrepContent: updatedWhyVecrepContent }); // Update form values
};

const handleRemoveField1 = (index) => {
  const updatedFAQContent = [...singleData?.FAQContent];
  updatedFAQContent.splice(index, 1); // Remove the item at the specified index
  setSingleData(prevState => ({
    ...prevState,
    FAQContent: updatedFAQContent
  }));
  form.setFieldsValue({ FAQContent: updatedFAQContent }); // Update form values
};

const handleAddField1 = () => {
  setSingleData(prevState => ({
    ...prevState,
    FAQContent: [...prevState?.FAQContent, { title: '', description: '' }]
  }));
};



  const fetchGarageList = async () => {

    try {
      setLoad(true)
      const response = await axios.get(
        `${ApiDomain.Domain}/getSinglePage/${pageId}`,
       
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log(response)
      if (response.status === 200) {

        setSingleData(response.data.data);
        
        // setCount(response.data.totalCount);
        setLoad(false)
        console.log(response.data.data)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  
  useEffect(() => {
    fetchGarageList();
  }, []);

  const handleFormValuesChange = (changedValues, allValues) => {
    // Update listData when form values change
    const newListData = allValues.WhyVecrepContent?.map((item, index) => ({
      title: item.title || '',
      description: item.description || '',
    }));
    setListData(newListData);
  
    // Update the 'why' state with the CKEditor content
    const updatedWhyContent = allValues.WhyVecrepContent?.map(item => item.description || '').join('\n');
    setWhy(updatedWhyContent);
    console.log(updatedWhyContent)
  };
  console.log(why)
  
  const handleFormValuesChange1 = (changedValues, allValues) => {
    // Update listData when form values change
    const newListData = allValues.FAQContent?.map((item, index) => ({
      title: item.title || '',
      description: item.description || '',
    }));
    setListData(newListData);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  // const handleInputChange = (e) => {
  //   setInputValue(e.target.value);
  //   setSaveButtonEnabled(true);
  // };

  const handleInputtextChange = (e) => {
    setTextareaValue(e.target.value);
    setSaveButtonEnabled(true);
  };

  const handleSaveButtonClick = async () => {
    console.log(inputValue);
    setData(inputValue);
    setSaveButtonEnabled(false);
  };

  const initialData = `${singleData.paragraphData}`;
  const submitparagraph = async(e) => {
    e.preventDefault();
    
  

    // Assuming the API endpoint URL is 'https://example.com/api/submit'
    const response = await axios.put(`${ApiDomain.Domain}/SEOdata`,
    {
      paragraphData:para,
      serviceName:singleData?.serviceName,
vehicleType: singleData?.vehicleType,
location: singleData?.location,
url: singleData?.url,
title: singleData?.title,
garageId: "",
pageId:pageId,

categoryType: singleData?.categoryType,
       fAq,
      why,
    }
    ,
    {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
       
        
        
        


      }
      );
console.log(response)
      if (response.status===200) {
        // Handle successful API response
        message.success("Data updated Successfully");
        fetchGarageList()

        console.log("API call successful");
      } else {
        // Handle API error
        console.error("API call failed");
        message.error("Error adding Feature");
      }
}


const handleSubmit1 = async (values) => {
  setWhy(values)
  try {
    console.log(values)
    console.log()
   

    const response = await fetch(`${ApiDomain.Domain}/SEOdata`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
      },
      body: JSON.stringify({
      
          paragraphData: singleData?.paragraphData,
          serviceName: singleData?.serviceName,
          vehicleType: singleData?.vehicleType,
          location: singleData?.location,
          url: singleData?.url,
          title: singleData?.title,
          garageId: "",
          pageId: pageId,
          categoryType: singleData?.categoryType,
          FAQContent: singleData?.FAQContent,
          WhyVecrepContent:singleData?.WhyVecrepContent,
         
      }
      
      
      )
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    else{
      message.success("Data updated successfully")
      fetchGarageList()
    }

    const responseData = await response.json();
    console.log('API Response:', responseData);

  } catch (error) {
    console.error('Error:', error);
    // Handle errors
  }
};
const handleSubmit2 = async (values) => {
  setFqQ(values)
  try {
   

    const response = await fetch(`${ApiDomain.Domain}/SEOdata`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
      },
      body: JSON.stringify({
        paragraphData:para,
        serviceName:Data?.serviceName,
  vehicleType: Data?.vehicleType,
  location: Data?.location,
  url: Data?.url,
  title: Data?.title,
  garageId: "",
  pageId:pageId,
  categoryType: Data?.categoryType,
  ...values,
  why,
          
      }
      
      
      )
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    else{
      message.success("Data updated successfully")
      fetchGarageList()
    }

    const responseData = await response.json();
    console.log('API Response:', responseData);

  } catch (error) {
    console.error('Error:', error);
    // Handle errors
  }
};

  return (
    <div className='App'>
  <div style={{ display: 'flex' }}>
        <div
          style={{
            width: '200px',
            background: 'rgb(99 99 122 / 36%)',
            padding: '20px',
            height: '100vh',
            position: 'sticky',
            top: '0',
          }}
        >
        <div style={{ marginBottom: '20px' }}>
            <Link to="/admin/vecrep/page" style={{
      color:"black",cursor:"pointer",fontWeight:"bold",fontSize:15}}> <BackwardOutlined />Back to Pages</Link>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <button onClick={() => handleSelect('option1')} style={{padding:10, width:120,
      backgroundColor: selectedOption === 'option1' ? 'black' : '#b11226', // Change background color based on selectedOption
      color:"white",border:"none",borderRadius:10,cursor:"pointer",paddingRight:10,paddingLeft:10}}>Paragraph 1</button>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <button onClick={() => handleSelect('option2')} style={{padding:10,width:120,
      backgroundColor: selectedOption === 'option2' ? 'black' : '#b11226', // Change background color based on selectedOption
      color:"white",border:"none",borderRadius:10,cursor:"pointer"}}>Why Vecrep?</button>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <button onClick={() => handleSelect('option3')} style={{padding:10,width:120,
      backgroundColor: selectedOption === 'option3' ? 'black' : '#b11226', // Change background color based on selectedOption
      color:"white",border:"none",borderRadius:10,cursor:"pointer"}}>FAQ</button>
          </div>
        </div>
        <div style={{padding: '20px' ,width:"100%"}}>
          <h1 style={{textAlign:"center",fontSize:50,fontWeight:"bold"}}>{singleData?.title}</h1>

          {selectedOption === 'option1' && (
            <>
            <CKEditor
              editor={ClassicEditor}
              data={initialData}
              onReady={(editor) => {
                console.log('CKEditor5 React Component is ready to use!', editor);
                // Customize editor configuration
                editor.config.define('config', {
                  h1: { styles: { color: 'black' } },
                  h2: { styles: { color: 'black' } },
                  h3: { styles: { color: 'black' } },
                });
              }}
              style={{ height: '500px' }}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                setPara(data)
              }}
            />
            {/* <MyRichTextEditor/> */}
         
            <div>
        <Form.Item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 30,}}>
        <Button style={{backgroundColor:"black",color:"white" }} onClick={(e)=>submitparagraph(e)}>
            Submit
          </Button>
        </Form.Item>
        </div>
</>
          )}
     
    
     {selectedOption === 'option2' && (
            <Form onFinish={handleSubmit1} style={{ minWidth: 600 }}>
              {singleData?.WhyVecrepContent?.map((item, index) => (
                <div key={index} style={{ marginBottom: 8 }}>
                  <div>
                  <Form.Item>
                      <Input
                        placeholder='Title'
                        value={item.title}
                        onChange={(e) => handleInputChange(e, index)}
                        name='title'
                        style={{ height: '50px' }}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <Form.Item name={['WhyVecrepContent', index, 'description']} initialValue={item.description}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={item.description}
                        onReady={(editor) => {
                          console.log('CKEditor5 React Component is ready to use!', editor);
                          editor.config.define('config', {
                            h1: { styles: { color: 'black' } },
                            h2: { styles: { color: 'black' } },
                            h3: { styles: { color: 'black' } },
                          });
                        }}
                        style={{ height: '500px' }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          const updatedWhyVecrepContent = [...singleData.WhyVecrepContent];
                          updatedWhyVecrepContent[index].description = data;
                          setSingleData(prevState => ({ ...prevState, WhyVecrepContent: updatedWhyVecrepContent }));
                        }}
                      />
                    </Form.Item>
                  </div>
                  <Button onClick={() => handleRemoveField(index)}>Remove fields</Button>

                </div>
              ))}
              <div>
                <Form.Item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 30 }}>
                <Button onClick={handleAddField}>Add Field</Button>

                <Button type='primary' htmlType='submit'>Submit</Button>

                </Form.Item>
              </div>
            </Form>
          )}
         {selectedOption === 'option3' && (
          <div className='App'>
            <Form
      form={form}
      onFinish={handleSubmit2}
      onValuesChange={handleFormValuesChange1}
      name='dynamic_form_nest_item'
      style={{ minWidth: 600 }}
      autoComplete='off'
      initialValues={true}
    >
      {singleData?.FAQContent?.map((item, index) => (
        <div key={index} style={{ marginBottom: 8, display: 'flex', gap: '50px', alignItems: 'center', justifyContent: 'center' }}>
          <div>
            <Form.Item
              name={['FAQContent', index, 'title']}
              initialValue={item.title}
            >
              <Input placeholder='Question' style={{ width: '370px', height: '50px' }} />
            </Form.Item>
          </div>
          <div style={{marginTop:25}}>
            <Form.Item
              name={['FAQContent', index, 'description']}
              initialValue={item.description}
            >
              <TextArea placeholder='Answer' style={{ width: '370px', height: '100px' ,marginTop:25}} />
            </Form.Item>
          </div>
          <Button onClick={() => handleRemoveField1(index)}>Remove</Button>
        </div>
      ))}
      <div>
        <Form.Item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 30 }}>
          <Button onClick={handleAddField1}>Add Field</Button>
          <Button type='primary' htmlType='submit' >
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
  
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default SinglePage;
