import React, { useState, useEffect } from "react";
import "./approve.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../api/ApiDomain";
import CategoryTable from "./CategoryTable";
import { ReloadOutlined } from "@ant-design/icons";
import { Pagination } from "antd";

const ApproveGarage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const [load,setLoad]=useState(false)
  const [currentPage,setCurrentPage]=useState(1)
  const[count,setCount]=useState(1)
  const token = localStorage.getItem("admin");
  const itemsPerPage = 10;

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-approve-approve-modal-overlay")) {
      handleCloseModal();
    }
  };
  const fetchGarageList = async () => {
    setLoad(true)
    try {
      const response = await axios.get(`${ApiDomain.Domain}/onlineRequestedGarages`,
       
     
    {
        headers: { Authorization: `Bearer ${token}` },
      });

      // return false;
      if (response.status===200){
        
        setGarageList(response.data.data);
        setCount(response.data.totalCount)
        setLoad(false)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);

  return (
    <div className='approve-approve-container' onClick={handleOverlayClick}>
      <div className='approve-approve-content'>
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>

        <div className='approve-approve-header'>

          <h3 className='approve-approve-head'>Approve Garage</h3>

</div>
<div style={{marginTop:10}}>
<ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>fetchGarageList()} />

          {/* <button className='add-garage-approve' onClick={handleOpenModal}>

            Add garage-approve
          </button> */}
          </div>
        </div>

        <div className='approve-approve-card'>
          <CategoryTable
            garageList={garageList}
            fetchGarageList={fetchGarageList}
            load={load}
            count={count}
          />
        </div>
      </div>
     
     
    </div>
  );
};

export default ApproveGarage;
