import React, { useEffect, useState } from "react";
import "./SubCategory.css";
import SubCategoryForm from "./SubCategoryForm";
import SubCategoryTable from "./SubCategoryTable";
import AddSubCategory from "./AddSubCategory";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";

const SubCategory = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiData, setapiData] = useState([]);
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-subcategory-modal-overlay")) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem("admin");
    setLoad(true);
    try {
      const response = await fetch(`${ApiDomain.Domain}/adminServiceList`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("API response:", data);
        setapiData(data.data);
        setLoad(false);
      } else {
        console.error("Error fetching data from the API");
        setError(error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error);
    }
  };
  return (
    <div className="subcategory-container" onClick={handleOverlayClick}>
      <div className="subcategory-content">
        <div className="subcategory-header">
          <h3 className="subcategory-head" style={{ fontWeight: "bold" }}>
            Service Management
          </h3>
          <button className="add-subcategory" onClick={handleOpenModal}>
            Add Service
          </button>
        </div>
        <SubCategoryTable apiData={apiData} fetchData={fetchData} load={load} />
      </div>
      <div>
        {isModalVisible && (
          <AddSubCategory
          open={isModalVisible}
            handleCloseModal={handleCloseModal}
            fetchData={fetchData}
          />
        )}
      </div>
    </div>
  );
};

export default SubCategory;
