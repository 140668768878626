import React, { useState, useEffect } from "react";
import { Layout, Modal, Row } from "antd";
import { Link } from "react-router-dom";

import { toggleCollapsedSideNav } from "../../appRedux/actions";
import UserInfo from "../../components/UserInfo";
import Auxiliary from "util/Auxiliary";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";

import { useDispatch, useSelector } from "react-redux";

import UserProfile from "../Sidebar/UserProfile";

const { Header } = Layout;

const Topbar = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const dispatch = useDispatch();
 
  return (
    <Header style={{}}>
      <Row
        style={{
          width: "106%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-3 ">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </div>
        ) : null}
        {width >= TAB_SIZE ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {/* <Link to="/user/dashboard">
                <img
                  alt="logo"
                  src={"/assets/images/clokam.png"}
                  style={{ width: 150 }}
                />
              </Link> */}
            </div>

            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 0px 0px 0px",
              }}
            >
              <Auxiliary>
                <li
                  className="gx-user-nav"
                  style={{
                    listStyle: "none",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <UserProfile
                   
                  />
                </li>
              </Auxiliary>
            </div>
          
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* <Link
              to="/user/dashboard"
              className="gx-d-block gx-d-lg-none gx-pointer"
            >
              <img
                alt=""
                src={"/assets/images/clokam.png"}
                style={{ width: 150 }}
              />
            </Link> */}
          </div>
        )}

        {width >= TAB_SIZE ? null : (
          <>
            <Auxiliary>
              <li
                className="gx-user-nav"
                style={{
                  listStyle: "none",
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <UserInfo/>
              </li>
            </Auxiliary>
          </>
        )}
        {/* Ant Design Modal */}

      </Row>
    </Header>
  );
};

export default Topbar;
