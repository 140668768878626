import React, { useState, useEffect } from "react";
import "./Page.css";
import { FaSearch, FaTimes} from "react-icons/fa";
import { Popover } from "antd";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";

const CategoryForm = ({ onSearch,ExportCsv}) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const [minimumToDate, setMinimumToDate] = useState(null);
  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load,setLoad]=useState(false)
  const fetchstart=()=>{
    setLoad(true)
  }
  const fetchsuccess=()=>{
    setLoad(false)
  }
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedFromDate(e.target.value);
    setDisableToDate(false);
    const minimumToDateValue = selectedDate ? selectedDate : null;
    setSelectedToDate("");
    setMinimumToDate(minimumToDateValue);
  };
  const handleToDateChange = (e) => {
    setSelectedToDate(e.target.value);
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  console.log(status)
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
    setMinimumToDate(null);
  };

  const handleSubmit = (e, values) => {
    e.preventDefault();
    console.log(selectedFromDate);
    console.log(selectedToDate);
    console.log(status);
    console.log(values);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status:status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };
  const fetchGarageList = async () => {
    try {
      fetchstart()
      const response = await axios.post(`${ApiDomain.Domain}/getAllUsers`,
      {
        
          status: true,
          garageId: "",
          serviceName: "",
          categoryName: "",
          location: "",
          vehicleType: "",
          page: 0,
          pageCount: 2,
          pageType: "SEOPage"

      
      },
       {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      if (response.status===200){
        
        setGarageList(response.data.garages);
        fetchsuccess()
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);

  

  return (
    <div>
      <div className="page-manage-card-header">
        <form className="page-manage-form-control">
          {/* <div className="page-manage-input page-man">
            <label htmlFor="fromdate" style={{ fontWeight: "bold" }}>
              From Date
            </label>
            <input
              type="date"
              id="fromdate"
              name="fromdate"
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder="From Date"
              className="page-manage-input-cal"
            />
          </div>
          <div className="page-manage-input page-man">
            <label htmlFor="todate" style={{ fontWeight: "bold" }}>
              To Date
            </label>
            <input
              type="date"
              id="todate"
              name="todate"
              value={selectedToDate}
              onChange={handleToDateChange}
              min={minimumToDate}
              placeholder="To Date"
              className="page-manage-input-cal"
              disabled={disableToDate}
              title={disableToDate ? "Please select From Date first" : ""}
            />
          </div> */}
          <div className="page-manage-input page-man">
            <label htmlFor="status" style={{ fontWeight: "bold" }}>
              Vehicle Type
            </label>
            <select
              value={status}
              name="status"
              onChange={handleStatusChange}
              className="page-manage-input-cal"
            >
              <option value="" disabled>
                Choose an option
              </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="page-manage-input page-man">
            <label htmlFor="status" style={{ fontWeight: "bold" }}>
              Service Type
            </label>
            <select
              value={status}
              name="status"
              onChange={handleStatusChange}
              className="page-manage-input-cal"
            >
              <option value="" disabled>
                Choose an option
              </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="page-manage-input page-man">
            <label htmlFor="status" style={{ fontWeight: "bold" }}>
              Category Type
            </label>
            <select
              value={status}
              name="status"
              onChange={handleStatusChange}
              className="page-manage-input-cal"
            >
              <option value="" disabled>
                Choose an option
              </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div className="page-manage-input page-man1">
            <label htmlFor="search" style={{ fontWeight: "bold" }}>
              Search
            </label>
            <input
              type="search"
              name="search"
              placeholder="Search..."
              className="search-input"
              onChange={handleSearchChange}

            />
          </div>
          <div>
            <Popover content={"Search"}
      trigger="hover"
    
            >
            <button type="button" className="page-manage-form-button" onClick={handleSubmit}>
            <SearchOutlined />            </button>
            </Popover>
          </div>
          <div>
            <Popover 
            
            content={"Clear Filters"}
      trigger="hover"
            >
            <button type="button" className="page-manage-form-button"               onClick={handleClear}>

            {/* <FaTimes /> */}
            <CloseOutlined />
            </button>
            </Popover>
          </div>
          <div onClick={ExportCsv}>

          {/* <Popover 
            
            content={"Export CSV"}
      trigger="hover"
            >
            <button type="button" className="page-manage-form-excel">
            <TbFileExport /> 

                       </button>
                       </Popover> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
