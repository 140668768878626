import React, { useEffect, useState } from "react";
import "./Vendor.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import CategoryForm from "./Categoryform";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
import {CloudUploadOutlined} from "@ant-design/icons"
const CategoryTable = ({ garageList, load, fetchGarageList,param }) => {
  console.log(garageList)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);

  const [id, setId] = useState("");
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList
      .filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.registationDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.registationDate) <= new Date(filterCriteria.toDate);

        const statusMatch =
          !filterCriteria.status || (item.status ? "active" : "inactive") === filterCriteria.status;

        const searchMatch =
          !filterCriteria.search ||
          item.vendorName.toLowerCase().includes(filterCriteria.search.toLowerCase()) || (item.vendorEmail &&
            item.vendorEmail.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
            (item.vendorPhoneNumber &&
              item.vendorPhoneNumber.toLowerCase().includes(filterCriteria.search.toLowerCase())) || (item.totalPaidAmount &&
                item.totalPaidAmount.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
                (item.totalBalanceAmount &&
                  item.totalBalanceAmount.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
                  (item.garageName &&
                    item.garageName.toLowerCase().includes(filterCriteria.search.toLowerCase()))

                  
                
              
            
        console.log(statusMatch);

        return fromDateMatch && toDateMatch && statusMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.registationDate) - new Date(a.registationDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const modalV=()=>{
    setModal(true)
  }
  const cancelV=()=>{
    setModal(false)
  }
  const modalX=()=>{
    setModalXV(true)
  }
  const cancelx=()=>{
    setModalXV(false)
  }


  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };


  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
        const formattedDate = moment(item?.vendorDate).format("DD-MM-YYYY");

        return [
            startIndex + index + 1,
            formattedDate || "-",
            item.garageName ||"-",
            item.vendorName || "-",
            item.vendorEmail || "-",
            item.vendorPhoneNumber || "-",
            item?.pamentData?.totalBalanceAmount || "-",
            item?.pamentData?.totalPaidAmount || "-",
            // Assuming the image URL is stored in item.imageUrl
            // Adjust this based on your actual data structure
            item.imageUrl || "-",
        ];
    });

    // Add header row
    formattedData.unshift([
        "S.No",
        "Date",
        "Garage Name",
        "Vendor Name",
        "Email",
        "Phone",
        "Due Amount (₹)",
        "Paid Amount (₹)",
        "Uploaded Image",
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
};

  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };

  function cancel(e) {}

  async function confirm(e,  newStatus,subAdminId) {
    console.log(newStatus)
    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/subAdmin/changeStatus`,
        { subAdminId,newStatus},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchGarageList();
        message.success("Status Change Success");
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const vendorX=(index)=>{
    setModal(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);

  }

  return (
    <>

    {/* <Modal visible={modal} onCancel={cancelV}>
      <h1>ssdsds</h1>

    </Modal> */}
      

      <Spin spinning={load}>
        <CategoryForm onSearch={handleFilterChange} Csv={handleExportExcel} param={param} fetchGarageListed={fetchGarageList} />
        {!load && (
          <div className="vendor-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Date</th>
                  <th className="th4">Garage name</th>

                  <th className="th4">Vendor name</th>
                  <th className="th4">Email</th>
                  <th className="th4">Phone</th>


                  <th className="th100">Due Amount &#x20b9;</th>

                  <th className="th100">Paid Amount &#x20b9;</th>

                  <th className="th100">Uploaded Image</th>




                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Sale Invoice List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.vendorDate).format(
                      "DD-MM-YYYY"
                    );
                    

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        
                        <td>{formattedDate}</td>
                        <td>{item.garageName|| "-"}</td>


                        <td onClick={()=>(vendorX(index))} style={{textDecoration:"underline",cursor:"pointer"}}>{item.vendorName}</td>



                        <td>{item.vendorEmail}</td>

                        <td>{item.vendorPhoneNumber || "-"}</td>
                        <td>{item?.paymentData?.totalBalanceAmount ||"-"}</td>


                        <td>{item?.paymentData?.totalPaidAmount || "-"}</td>





                        <td style={{cursor:"pointer"}}>
                          <span
                          className={'badge badge-success'}
                          // onClick={() => activeStatus(item)}
                          >
                            {"View"}
                          </span>
                        </td>

                 
                        
                        {/* <td>
                          <div className="action-cell">
                            <div
                              className="edit-cell"
                              onClick={() => handleOpenModal(index)}
                            >
                              <FaPencilAlt />
                            </div>
                          </div>
                        </td> */}


                      </tr>

                      
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
            {modal && (
    <Modal footer={null} centered visible={modal} onCancel={cancelV}>
    <div className="add-sale-manage-modal-contents">
                   <div className="add-sale-manage-modal-content-header">
                     
                     <div>
                       <h3 style={{ fontWeight: "bold" ,color:"#b11226"}}>Customer Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-sale-manage-modal-forms"
                     style={{ width: 500,display:"flex",flexDirection:"row",}}
                   >
                    <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                     

                  
              
                   <div style={{textAlign:"",padding:"0px 20px 0px 20px"}}>
                  
                <div>

                {/* <p style={{paddingLeft:27,fontWeight:"bold",marginBottom:"0.5em"}}>Booking Id:<span style={{fontSize:15,padding:20}}>{selectedGarage.bookingId}</span></p> */}

                        <p style={{paddingLeft:32,fontWeight:"bold",marginBottom:"0.5em"}}>Full Name:<span style={{fontSize:15,padding:20}} >{selectedGarage.vendorName?selectedGarage.vendorName:"-"}</span></p>
      

      <p style={{paddingLeft:0,fontWeight:"bold",marginBottom:"0.5em"}}>Phone Number: <span style={{fontSize:15,padding:18}}>{selectedGarage.vendorPhoneNumber?selectedGarage.vendorPhoneNumber:"-"}</span></p>

      <p style={{paddingLeft:16 ,fontWeight:"bold",marginBottom:"0.5em"}}>Email: <span style={{fontSize:15,padding:17}}>{selectedGarage.vendorEmail?selectedGarage.vendorEmail:"-"}</span></p>

      
                 </div>
                 </div>

                 </div>
                 </div>
                 </Modal>
              
              )}
            </div>
            <div>
              {modalXV && (
    <Modal footer={null} centered visible={modalXV} onCancel={cancelx}>
    <div className="">
                   <div className="add-vendor-manage-modal-content-header">
                     <span
                       className="add-vendor-manage-close-button"
                       onClick={cancelx}
                     >
                       &times;
                     </span>
                     <div>
                       <h3 style={{ fontWeight: "bold" ,color:"#b11226"}}>vehicle Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-vendor-manage-modal-forms"
                     style={{ width: 500,padding:15}}
                   >
                    {/* <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                      */}

                  
                   </div>
                   <div style={{textAlign:"",padding:"0px 30px 0px 30px"}}>
                  
                <div>

                <p style={{paddingLeft:20,fontWeight:"bold"}}>Vehicle Number:<span style={{fontSize:15,padding:20}}>56FFFF</span></p>

                        <p style={{paddingLeft:83,fontWeight:"bold"}}>Brand:<span style={{fontSize:15,padding:20}} >Suziki</span></p>
      

      <p style={{paddingLeft:83,fontWeight:"bold"}}>Modal: <span style={{fontSize:15,padding:18}}>Duke 200</span></p>

      <p style={{paddingLeft:93 ,fontWeight:"bold"}}>Year: <span style={{fontSize:15,padding:17}}>2024</span></p>
      <p style={{paddingLeft:58,fontWeight:"bold"}}>Fuel Type: <span style={{fontSize:15,padding:17}}>Petrol</span></p>

      
                 </div>
                 </div>

                 
                 </div>
                 </Modal>
              
              )}
            </div>

          
           
          </div>
          
        )}
          <Pagination
          onChange={onChange}
          simple
          total={filteredGarageList.length}
          pageSize={itemsPerPage}
          style={{ marginTop: 10, float: "right" }}
          current={currentPage} // Ensure the Pagination component reflects the current page
        />
      </Spin>
    </>
  );
};

export default CategoryTable;
