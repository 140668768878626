import React, { useState, useEffect } from "react";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";

import {
  message,
  Upload,
  Form,
  Col,
  Row,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Radio,
  AutoComplete,
  Modal,
  Spin,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./Page.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";

const { Option } = Select;

const EditCategory = ({
  handleCloseModal,
  fetchGarageList,
  selectedGarage,
}) => {
  console.log(selectedGarage);
  const [form] = Form.useForm();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [garageList, setGarageList] = useState([]);
  const [autoSuggestions, setAutoSuggestions] = useState([]);

  const [workingHours, setWorkingHours] = useState([
    { day: "Monday", slots: [], disabled: false },
    { day: "Tuesday", slots: [], disabled: false },
    { day: "Wednesday", slots: [], disabled: false },
    { day: "Thursday", slots: [], disabled: false },
    { day: "Friday", slots: [], disabled: false },
    { day: "Saturday", slots: [], disabled: false },
    { day: "Sunday", slots: [], disabled: false },
  ]);
  const history = useHistory();
  const [isGSTExist, setIsGSTExist] = useState(false);
  const [clockVisible, setClockVisible] = useState(false);
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [load, setLoad] = useState(false);
  const { Option } = Select;

  const showClockModal = () => {
    setClockVisible(true);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  // Other state variables...
  const handleChangeCar = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoadingCar(true);

      // Get the image URL from the API response
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        "https://api.vecrep.com/uploadImage",
        formData
      );

      // Assuming the API response contains the image URL
      const imageUrl = response.data.imagePath1;
      // Update the state with the image URL
      setImageUrlCar(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoadingCar(false);
    }
  };

  const uploadButton = (
    <div>
      {loadingCar ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleSlotChange = (day, slots) => {
    setWorkingHours((prev) =>
      prev.map((item) => (item.day === day ? { ...item, slots } : item))
    );
  };

  const handleDayToggle = (day) => {
    setWorkingHours((prev) =>
      prev.map((item) =>
        item.day === day ? { ...item, disabled: !item.disabled } : item
      )
    );
  };

  const fetchstart = () => {
    setLoad(true);
  };
  const fetchsuccess = () => {
    setLoad(false);
  };

  const handleInputChange = (value) => {
    const suggestions = garageList.filter(
      (garage) =>
        typeof garage === "string" &&
        garage.toLowerCase().includes(value.toLowerCase())
    );
    setAutoSuggestions(suggestions);
  };

  const token = localStorage.getItem("admin");

  const handleSubmit = (values) => {
    form
      .validateFields()
      .then((formValues) => {
        const submittedData = workingHours.map((dayObj) => ({
          day: dayObj.day,
          slots: dayObj.slots,
          checked: !dayObj.disabled,
        }));

        const requestData = {
          ...formValues,
          imagePath: imageUrlCar,

          garageId: selectedGarage.garageId,
          availableTimes: submittedData,
          // startTime: selectedStartTime,
          // endTime: selectedEndTime,
        };
        console.log(requestData);

        const headers = {
          Authorization: `Bearer ${token}`,
        };
        if (navigator.geolocation) {
          fetchstart();

          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              requestData.latitude = latitude;
              requestData.longitude = longitude;

              axios
                .put(
                  `${ApiDomain.Domain}/editGarageProfile`,
                  requestData,
                  { headers }
                )
                .then((response) => {
                  console.log("API Response:", response);
                  if (response?.data?.responseCode === 200) {
                    fetchsuccess();
                    handleCloseModal();
                    fetchGarageList();
                    message.success(response?.data?.message);
                  }
                })
                .catch((error) => {
                  console.error("API Error:", error);
                });
            },
            (error) => {
              console.error("Error getting user's location:", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    console.log("Form cancelled!");
    handleCloseModal();
  };
  return (
    <div className="add-page-manage-modal-overlay">
      <div className="add-page-manage-modal-contents">
        <div className="add-page-manage-modal-content-header">
          <span
            className="add-page-manage-close-button"
            onClick={handleCloseModal}
          >
            &times;
          </span>
          <div>
            <h3>Edit Garage</h3>
          </div>
        </div>
        <div
          className="add-page-manage-modal-forms"
          style={{ maxHeight: "500px", overflowY: "scroll", width: 900 }}
        >
          <Spin spinning={load}>
            <Form
              form={form}
              layout="vertical"
              style={{ minWidth: "600px" }}
              onFinish={handleSubmit}
              initialValues={true}
            >
              <div>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        name="garageImage"
                        label="Garage Image : "
                        rules={[
                          {
                            // required: true,
                            message: "Please upload Garage Image",
                          },
                        ]}
                      >
                        <div class="upload">
                          <button type="button" class="btn-warning">
                            <CloudUploadOutlined /> Upload File
                            <input type="file" onChange={handleChangeCar} />
                          </button>
                        </div>
                      </Form.Item>
                    </Col>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}></Col>
                  </div>
                </Row>

                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Garage Name : "
                        name="garageName"
                        initialValue={selectedGarage?.garageName}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            required: true,
                            message: "Please input your Garage Name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Your Garage Name"
                          style={{ height: "50px" }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Email : "
                        name="email"
                        initialValue={selectedGarage?.email}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            required: true,
                            message: "Please input your Email",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Your Email"
                          style={{ height: "50px" }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Contact No : "
                        name="phoneNumber"
                        initialValue={selectedGarage?.phoneNumber}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            required: true,
                            message: "Please input your Phone number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Mobile Number"
                          style={{ height: "50px" }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Address : "
                        name="address"
                        initialValue={selectedGarage?.address}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            required: true,
                            message: "Please input your Address!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter address"
                          style={{ height: "50px" }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="PAN Number : "
                        name="pancardNumber"
                        required
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            message: "Please input your Pan Card number!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter Your PAN Card Number"
                          style={{ height: "50px" }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        name="bussinessType"
                        label="Business Type "
                        rules={[
                          {
                            required: true,
                            message: "Please Select Business Type",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ height: 40 }}
                          placeholder="Select Type"
                          optionFilterProp="children"
                          // onChange={handleVehicleChange}
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="1">
                            Workshops/Garages/Modification
                          </Option>
                          <Option value="2">Accessories</Option>
                          <Option value="3">Washing/Detailing</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item label="GST Number : " name="gstNumber">
                        <Input
                          placeholder="Enter Your gst Card Number"
                          style={{ height: "50px" }}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item label="Open and Close time : " name="time">
                        <Radio.Group name="radiogroup">
                          <Radio value={1}>Manual Time</Radio>
                          <Radio value={2}>24*7</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </div>
                </Row>

                <h5
                  style={{
                    color: "inherit",
                    paddingLeft: "8px",
                    paddingBottom: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Working Hours:
                </h5>
                {workingHours?.map((dayObj) => (
                  <Row
                    key={dayObj.day}
                    style={{ paddingBottom: "10px", paddingTop: "10px" }}
                  >
                    <Col xl={24} lg={24}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            width: "150px",
                            textAlign: "right",
                            paddingRight: "20px",
                          }}
                        >
                          <label style={{ textAlign: "center" }}>
                            {dayObj.day}
                          </label>
                        </div>
                        <div>
                          <TimePicker
                            use12Hours
                            disabled={dayObj.disabled}
                            onChange={(time, timeString) =>
                              setSelectedStartTime(timeString[0])
                            }
                            format="hh:mm:ss A"
                            style={{ marginRight: "10px" }}
                          />
                          <TimePicker
                            use12Hours
                            disabled={dayObj.disabled}
                            onChange={(time, timeString) =>
                              setSelectedEndTime(timeString[1])
                            }
                            format="hh:mm:ss A"
                          />
                          <Checkbox
                            onChange={() => handleDayToggle(dayObj.day)}
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))}

                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Vehicle Type : "
                        name="garageType"
                        required
                        initialValue={selectedGarage.garageType}
                        rules={[
                          {
                            type: "text",
                            message: "The input is not valid!",
                          },
                          {
                            required: true,
                            message: "Please input your Garage Type!",
                          },
                        ]}
                      >
                        <Radio.Group name="radiogroup">
                          <Radio value={1}>4W</Radio>
                          <Radio value={2}>2W</Radio>
                          <Radio value={3}>Both</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
                <Row
                  style={{
                    display: "flex",

                    flexDirection: "row",

                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        label="Specialized : "
                        name="specialized"
                        initialValue={selectedGarage?.specialized}
                      >
                        <AutoComplete
                          placeholder="Input placeholder"
                          style={{ height: "50px" }}
                          onChange={handleInputChange}
                        >
                          {garageList?.map((suggestion) => (
                            <AutoComplete.Option
                              style={{ padding: 10 }}
                              key={suggestion._id}
                              value={suggestion.categoryName}
                            >
                              {suggestion.categoryName}
                            </AutoComplete.Option>
                          ))}
                        </AutoComplete>
                      </Form.Item>
                    </Col>
                  </div>
                </Row>

                <Row
                  style={{
                    display: "flex",

                    flexDirection: "row",

                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Col xl={24} lg={24}>
                      <Form.Item
                        initialValue={selectedGarage?.aboutGarage}
                        label="Garage Description : "
                        name="aboutGarage"
                      >
                        <TextArea rows={3} placeholder="Enter Description" />
                      </Form.Item>
                    </Col>
                  </div>
                </Row>
              </div>

              <div className="add-garage-manage-popup-footer">
                <button
                  className="cancel-button"
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </button>

                <button className="submit-button" htmlType="submit">
                  Submit
                </button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
