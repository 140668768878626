import React, { useEffect, useState } from "react";
import "./Mycustomer.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import {
  Avatar,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from "xlsx";
import CustomerForm from "./MyCustomerform";

const MyCustomerTable = ({ garageList, load, fetchGarageList }) => {
  console.log(garageList)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [active, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState("");
  const [data, setData] = useState([]);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [modalXV, setModalXV] = useState(false);
  const [modalXV1, setModalXV1] = useState(false);
  const [modalXV2, setModalXV2] = useState(false);

  const [form] = Form.useForm();
  const [category, setCategory] = useState([]);
  const [services, setServices] = useState([]);
  const { Option } = Select;
  const [initialSelectedServiceIds, setInitialSelectedServiceIds] = useState(
    []
  );
  const [initialCategoryNames, setInitialCategoryNames] = useState([]);

  const [garageIdData, setGarageIdData] = useState("");
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });

  const fetchServiceLists = async (id) => {
    console.log(id);
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/category_service_list`,
        {
          categoryId: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data?.data);
      setServices(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchServiceLists();
  }, []);

  const ServicesHit = (id) => {
    console.log("ddd");
    fetchServiceLists(id);
  };
  console.log(selectedGarage);
  useEffect(() => {
    // Assuming selectedGarage is populated with data containing categories
    const categories = selectedGarage?.categories?.map(
      (category) => category.services
    );
    setInitialCategoryNames(categories);
  }, [selectedGarage]);

  console.log(initialCategoryNames);

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList?.filter((item) => {
        const formattedDate = moment(item?.date).format(
          "DD-MM-YYYY"
        );

        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.formattedDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.formattedDate) <= new Date(filterCriteria.toDate);

        const searchMatch =
          !filterCriteria.search ||
          item.customerName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase());
        setDate(formattedDate);

        return fromDateMatch && toDateMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.date) - new Date(a.date);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };
  useEffect(() => {
    if (selectedGarage) {
      fetchServiceLists(selectedGarage?.categoryId);
    }
  }, [selectedGarage]);

  const handleChange = (value) => {
    const selectedCategory = category.categories.find(
      (cat) => cat.categoryId === value
    );
    setSelectedGarage(selectedCategory);
  };

  const handleVehicleChange = (value) => {
    // console.log(Selected: ${value});
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList?.slice(startIndex, endIndex);
  console.log(visibleRows);
  const handleOpenModal = (index) => {
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  };

  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
    form.resetFields(); // Reset form fields to their initial values

    setModalXV1(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const modalX = (index, item) => {
    setModalXV(true);
    const selectedGarage = visibleRows[index]; // Get the selected garage

    setSelectedGarage(selectedGarage); // Updated to use visibleRows

  };

  const modalX1 = (index, item) => {
    setModalXV1(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    console.log(selectedGarage)
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };
  const modalUrl = (index, id) => {
    setModalXV2(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage);
    // fetchGarageLists(id);
  };
  // Update initial selected service IDs when selectedGarage changes
  // useEffect(() => {
  //   if (selectedGarage) {
  //     const services = selectedGarage?.categories.map(category =>
  //       category?.services.map(service => service?.serviceName)
  //     ).flat();
  //     setInitialSelectedServiceIds(services);
  //   }
  // }, [selectedGarage]);
  const fact = () => {
    const services = selectedGarage.categories
      ?.map((category) =>
        category.services.map((service) => service.serviceName)
      )
      .flat();
    // Set initial selected service IDs
    setInitialSelectedServiceIds(services);
  };
  console.log(initialSelectedServiceIds);
  const cancelx = () => {
    setModalXV(false);
    setModalXV2(false)
    setModalXV1(false);
  };

  async function handleSubmit2(values) {
    console.log(values);

    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/manageGarageProfile`,
        { garageId: id, ...values, status: active },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response?.data?.responseCode === 200) {
        fetchGarageList();
        message.success("Status Updated Successfully");
        handleCancel();
      }

      // Update the garage list after successful approval
    } catch (error) {
      console.log(error.message);
    }
  }

  const fetchGarageListSingle = async (id) => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/getListOfBankDetails`,
        {
          garageId: id ? id : "",

          // Use the current value of the 'page' state
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.status === 200) {
        console.log(response.data);

        setData(response.data.data);

        // setCount(response.data.totalCount);
        console.log(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleCancel = () => {
    setModalXV1(false);
    setModal(false);
    form.resetFields(); // Reset form fields to their initial values
  };
  console.log(garageList?.length);

  const activeStatus = (item) => {
    setId(item?.garageId);
    setActive(!item?.status);
    setModal(true);
  };
  const handleExportExcel = () => {
    // Extracting headers from the table
    const headers = [
      "S.No",
      "Date",
      "Customer Name",
      "City",
      "Email","Phone Number","Dob",
      "Vehicle Type",
      "Vehicle Number",
      "Brand Name",
      "Model Name",
      "Variant",
      "Fuel Type",
      "Year",
      "Chasis Number",
      "Company Name",
      "Insurance Number",
      "Expiry Date"

    ];
  
    // Creating data array for the Excel sheet
    const excelData = [];
  
    // Adding headers to the data array
    excelData.push(headers);
  
    // Populating data from the table rows
    visibleRows.forEach((item, index) => {
      const formattedDate = moment(item?.vehicles?.created_at).format("DD-MM-YYYY");
      const rowData = [
        startIndex + index + 1,
        formattedDate,
        item.customerName,
        item.city,
        item.email,
        item.phoneNumber,
        item.dateOfBirth,
        item.vehicleType,
        item.vehicleNumber,
        item.brand,
        item.model,
        item.variant,
        item.fuelType,
        item.year,
        item.chassisNumber,item.insuranceNumber,item.companyName,item.expiryDate
      ];
      excelData.push(rowData);
    });
  
    // Create a workbook
    const wb = XLSX.utils.book_new();
    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
  };
  

  console.log(date);
  console.log(garageIdData);

  const handleSubmit1 = async (values) => {
    console.log(garageIdData);

    const d = {
      garageId: garageIdData,
      categoryId: values.categoryId,
      services: values.serviceId?.map((serviceId) => ({
        serviceId: serviceId,
        vehicleType: 1, // Assuming you have a function to get vehicleType based on serviceName
        quantity: 1,
        rate: 0,
      })),
    };
    console.log(d);
    // Extract only the date part
    // const startDate = values.startDate.format("YYYY-MM-DD");
    // const expiryDate = values.expiryDate.format("YYYY-MM-DD");

    try {
      const response = await fetch(
        `${ApiDomain.Domain}/add_category_services`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
          body: JSON.stringify({
            garageId: garageIdData,
            categoryId: values.categoryId,
            services: values.serviceId?.map((serviceId) => ({
              serviceId: serviceId,
              vehicleType: 1, // Assuming you have a function to get vehicleType based on serviceName
              quantity: 1,
              rate: 0,
            })),

            // startDate,
            // expiryDate,
          }),
        }
      );

      if (response.ok) {
        // Handle successful API response
        console.log("API call successful");
        fetchGarageList();
        message.success("Sevices added successfully");
        handleCloseModal();
      } else {
        // Handle API error
        console.error("API call failed");
        message.error("Error adding  Service Add");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
      message.error("Error addingService add");
    } finally {
    }
  };

  const a = selectedGarage?.categories?.map((category) =>
    category?.services?.map((service) => service.serviceName)
  );

  console.log(a?.flat());
  const window1 = (url) => {
    // Open the PDF file in a new tab
    window?.open(url, '_blank');
  };  
  
  return (
    <>
      {modal && (
        <div className="add-customer-manage-modal-overlay">
          <div className="add-customer-manage-modal-contents">
            <div className="add-customer-manage-modal-content-header">
              <div>
                <h3 style={{ color: "white" }}>Garage Status</h3>
              </div>
            </div>
            <div className="add-customer-modal-form">
              <Spin spinning={load}>
                <Form
                  // form={form}
                  layout="vertical"
                  style={{ minWidth: "600px" }}
                  onFinish={handleSubmit2}
                >
                  <div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "80%", marginTop: 10 }}>
                        <Col xl={24} lg={24}>
                          <Form.Item label="Reason : " name="reason">
                            <Input
                              placeholder="Enter Your Reason Name"
                              style={{ height: "50px" }}
                            />
                          </Form.Item>
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div className="add-customer-manage-popup-footer">
                    <button
                      className="cancel-button"
                      onClick={handleCancel}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </button>

                    <button className="submit-button" htmlType="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
      )}

      <Spin spinning={load}>
        <CustomerForm
          onSearch={handleFilterChange}
          ExportCsv={handleExportExcel}
        />
        {!load && (
          <div className="customer-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Date</th>
                  <th className="th3">Customer Name</th>
                  <th className="th4">City</th>
                  <th className="th5">VehicleType</th>
                  <th className="th6">VehicleNumber</th>
                  <th className="th7">Brand Name</th>
                  <th className="th8">Model Name</th>
                  <th className="th9">Variant</th>
                  <th className="th12">Fuel Type</th>
                  <th className="th13">Year</th>
                  <th className="th13">Booking History</th>

                </tr>
              </thead>

              <tbody>
                {filteredGarageList?.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Customers found.
                    </td>
                  </tr>
                ) : (
                  visibleRows?.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.vehicles?.created_at).format(
                      "DD-MM-YYYY"
                    );

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                       
                        <td>{formattedDate}</td>
                        <td style={{textDecoration:"underline",cursor:"pointer"}} onClick={()=>modalX(index,item)}>{item?.customerName}</td>
                        <td>{item?.city || "-"}</td>

                        <td>{item?.vehicleType === 1 ? "Car" : item?.vehicleType === 2 ? "Bike" : item?.vehicleType === 3 ? "Both" : "-"}</td>

                        <td style={{textDecoration:"underline",cursor:"pointer"}} onClick={()=>modalX1(index,item)}>{item?.vehicleNumber}</td>
                        <td>{item?.brand || "-" }</td>
                        <td>{item?.model || "-"}</td>
                        <td>{item?.variant ||"-" }</td>
                        <td>{item?.fuelType || "-"}</td>
                        <td>{item?.year || "-"}</td>



                        <td onClick={()=>modalUrl(index,item)} >
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                      

                      
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
           
           

        

          
          </div>
        )}
        <Pagination
          simple
          onChange={onChange}
          current={currentPage}
          total={filteredGarageList?.length}
          pageSize={itemsPerPage}
          style={{ marginTop: 10, float: "right" }}
        />
         <div>
              {modalXV && (
    <Modal footer={null} centered visible={modalXV} onCancel={cancelx} width={"auto"}>
    <div className="customer-manage-table">
                   <div className="add-sale-manage-modal-content-header">
                    
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"#b11226" }}>Customer Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-sale-manage-modal-forms"
                     style={{ width: 500,display:"flex",flexDirection:"row",}}
                   >
                     <div style={{}}>
                       <img  style={{width:80,borderRadius:10}} src={selectedGarage?.customerImage}  />
                     </div>
                     <div style={{textAlign:"",padding:"0px 20px 0px 20px"}}>
                       <div>
                         <p style={{paddingLeft:0,fontWeight:"bold",marginBottom:"0.5em"}}>Phone Number: <span style={{fontSize:15,padding:18}}>{selectedGarage?.phoneNumber?selectedGarage.phoneNumber:"-"}</span></p>
                         <p style={{paddingLeft:60 ,fontWeight:"bold",marginBottom:"0.5em"}}>Email: <span style={{fontSize:15,padding:17}}>{selectedGarage?.email?selectedGarage.email:"-"}</span></p>
                         <p style={{paddingLeft:55,fontWeight:"bold",marginBottom:"0.5em"}}>DOB:<span style={{fontSize:15,padding:17}} >{selectedGarage?.dateOfBirth?selectedGarage:"-"}</span></p>

                       </div>
                     </div>
                   </div>
                 </div>
               </Modal>
              )}
            </div>
            <div>
              {modalXV1 && (
    <Modal footer={null} centered visible={modalXV1} onCancel={cancelx} width={"auto"}>
    <div className="customer-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Vehicle Details
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th4">Chasis Number</th>
                          <th className="th4">Company Number</th>
                          <th className="th4">Insurance Number</th>

                          <th className="th5">Expiry Date</th>
                        </tr>
                      </thead>
                      <tbody>
  {selectedGarage ? (
    // Render data if fetchGarageList is not empty
  
      <tr key={selectedGarage.id}>
        <td>{selectedGarage.chassisNumber?selectedGarage.chassisNumber:"-"}</td>
        <td>{selectedGarage.companyName?selectedGarage.companyName:"-"}</td>
        <td>{selectedGarage.insuranceNumber?selectedGarage.insuranceNumber:"-"}</td>
        <td>{selectedGarage.expiryDate?selectedGarage.expiryDate:"-"}</td>
      </tr>
    
  ) : (
    // Render a single row with a message if fetchGarageList is empty
    <tr>
      <td colSpan="4" style={{ textAlign: "center" }}>
        No Details.
      </td>
    </tr>
  )}
</tbody>

                    </table>
                  </div>
                </Modal>
              )}
            </div>
            <div>
              {modalXV2 && (
    <Modal footer={null} centered visible={modalXV2} onCancel={cancelx} width={"auto"}>
    <div className="customer-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Booking History
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Date and Time</th>

                          <th className="th5">GarageName</th>
                        </tr>
                      </thead>

                      <tbody>
                      {selectedGarage?.serviceBookings?.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Booking found.
                    </td>
                  </tr>
                ) : (
                  selectedGarage?.serviceBookings?.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(selectedGarage.date).format(
                      "DD-MM-YYYY"
                    );

                            return (
                              <tr key={item.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{formattedDate}</td>
                                {/* <td onClick={(window1(item.invoiceURL))} >
                                  
                                 <span  
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}>
                          Download
                          </span>
                          </td> */}

                                <td>{item.garageName?item.garageName:"-"}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </Modal>
              )}
            </div>
      </Spin>
    </>
  );
};

export default MyCustomerTable;
