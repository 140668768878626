import React, { useState, useEffect } from "react";
import "./approve.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CategoryTable from "./Categorytable";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";
import { Pagination } from "antd";

const ApproveManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const [load,setLoad]=useState(false)
  const token = localStorage.getItem("admin");
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const maxRetries = 3;
  const retryDelay = 1000; // in milliseconds

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-approve-approve-modal-overlay")) {
      handleCloseModal();
    }
  };
  
  const fetchGarageList = async ( retries = 0) => { 
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/getAllApproveUsers`,
        
      
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      console.log(response.data);
      if (response.status === 200) {
        setGarageList(response.data.garages);
        setCount(response.data.totalGarages);
        setLoad(false);
      }
    } catch (error) {
      console.log(error.response ? error.response.status : error.message);
      if (error.response && error.response.status === 503 && retries < maxRetries) {
        console.log("Retrying request...");
        setTimeout(() => {
          fetchGarageList();
        }, retryDelay);
      } else {
        setLoad(false);
      }
    }
  };

  useEffect(() => {

     
      fetchGarageList();
      
  


    
  }, []);

  


  return (
    <div className='approve-approve-container' onClick={handleOverlayClick}>
      <div className='approve-approve-content'>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
        <div className='approve-approve-header'>
          <h3 className='approve-approve-head'>Approve Garage</h3>
          </div>
          <div>
          
            <ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>fetchGarageList()} />

        </div>
        
        </div>
        <div className='approve-approve-card'>
          <CategoryTable
            garageList={garageList}
            fetchGarageList={fetchGarageList}
            load={load}
          />
        </div>
      </div>
     
    </div>
  );
};

export default ApproveManagement;
