import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Input, Form, Button, message, Row, Col } from 'antd';
import { BackwardOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { Link, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import ApiDomain from '../api/ApiDomain';

const NearMeEdit = () => {
  const [selectedOption, setSelectedOption] = useState('option1');
  
  const [inputValue, setInputValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [data, setData] = useState('Hello');
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [form] = Form.useForm();
  const [numFields, setNumFields] = useState(1); // State to track the number of fields
const location = useLocation();
const {pageId}=useParams();
console.log(pageId)
const  Data = location?.state;
console.log(Data)
const [para,setPara]=useState("")
const [load,setLoad]=useState(false)
const [fAq,setFqQ]=useState([])
const [why,setWhy]=useState([])

  const [singleData, setSingleData] = useState({
    WhyVecrepContent: [{ title: '', description: '' }],
  });

const accessToken=localStorage.getItem("admin")
 

const [listData, setListData] = useState([{ title: '', description: '' }]);

const [vehicleTypes, setVehicleTypes] = useState({});
const [inputValues, setInputValues] = useState({});



  // Handle changes to the addonBefore (vehicleType)
  const handleVehicleTypeChange = (vehicleKey, e) => {
    const value = e.target.value;
    setVehicleTypes((prevValues) => ({
      ...prevValues,
      [vehicleKey]: value,
    }));
  };
  // Handle changes to the main input
  const handleInputChange = (vehicleKey, e) => {
    const value = e.target.value;
    setInputValues((prevValues) => ({
      ...prevValues,
      [vehicleKey]: value,
    }));
  };





const handleRemoveField1 = (index) => {
  const updatedFAQContent = [...singleData?.FAQContent];
  updatedFAQContent.splice(index, 1); // Remove the item at the specified index
  setSingleData(prevState => ({
    ...prevState,
    FAQContent: updatedFAQContent
  }));
  form.setFieldsValue({ FAQContent: updatedFAQContent }); // Update form values
};

const handleAddField1 = () => {
  setSingleData(prevState => ({
    ...prevState,
    FAQContent: [...prevState?.FAQContent, { title: '', description: '' }]
  }));
};

  const [formData, setFormData] = useState({
    Basic: [
      { vehicleType: 'Hatchback', price: 0 },
      { vehicleType: 'Sedan', price: 0 },
      { vehicleType: 'SUV', price: 0 },
      { vehicleType: 'Luxury', price: 0 }
    ],
    Premium: [
      { vehicleType: 'Hatchback', price: 0 },
      { vehicleType: 'Sedan', price: 0 },
      { vehicleType: 'SUV', price: 0 },
      { vehicleType: 'Luxury', price: 0 }
    ],
    Gold: [
      { vehicleType: 'Hatchback', price: 0 },
      { vehicleType: 'Sedan', price: 0 },
      { vehicleType: 'SUV', price: 0 },
      { vehicleType: 'Luxury', price: 0 }
    ]
  });

  const fetchGarageList = async () => {
    try {
      setLoad(true);
      const response = await axios.get(
        `${ApiDomain.Domain}/getSinglePage/${pageId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response.status === 200) {
        setSingleData(response.data.data);
        setLoad(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchGarageList();
  }, []);

  const handleSubmitss = async (values) => {
    const vehicleData = Object.entries(formData).map(([packageType, vehicles]) => ({
      package: packageType,
      vehicles: vehicles.map((vehicle, index) => {
        const vehicleKey = `${packageType}_${vehicle.vehicleType}`; // Create vehicleKey
        return {
          vehicleType: values[`vehicleType_${index}`], // Fetch vehicleType from values using index
          price: parseFloat(values[`price_${vehicleKey[index]}`]) || "" // Fetch price using vehicleKey
        };
      })
    }));
  
  
    
  
    try {
      const response = await fetch(`${ApiDomain.Domain}/SEOdata`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          paragraphData: para,
          serviceName: singleData?.serviceName,
          vehicleType: singleData?.vehicleType,
          location: singleData?.location,
          url: singleData?.url,
          title: singleData?.title,
          garageId: "",
          pageId: pageId,
          categoryType: singleData?.categoryType,
          FAQContent: singleData?.FAQContent,
          vehicleData,
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else {
        message.success("Data updated successfully");
        fetchGarageList()
      }

      const responseData = await response.json();
      console.log('API Response:', responseData);

    } catch (error) {
      console.error('Error:', error);
      message.error("Error updating data");
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const initialData = `${singleData.paragraphData}`;
  const handleSubmit2 = async (values) => {
    setFqQ(values)
    try {
     
  
      const response = await fetch(`${ApiDomain.Domain}/SEOdata`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({
          paragraphData:para,
          serviceName:Data?.serviceName,
    vehicleType: Data?.vehicleType,
    location: Data?.location,
    url: Data?.url,
    title: Data?.title,
    garageId: "",
    pageId:pageId,
    categoryType: Data?.categoryType,
    ...values,
    
            
        }
        
        
        )
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      else{
        message.success("Data updated successfully")
        fetchGarageList()

      }
  
      const responseData = await response.json();
      console.log('API Response:', responseData);
  
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }
  };


  const datas= [
    {
      package: "Basic",
      vehicles: [
        { vehicleType: "Hatchback", price: "" },
        { vehicleType: "Sedan", price: "" },
        { vehicleType: "SUV", price: "" },
        { vehicleType: "Luxury", price: "" },
      ],
    },
    {
      package: "Premium",
      vehicles: [
        { vehicleType: "Hatchback", price: "" },
        { vehicleType: "Sedan", price: "" },
        { vehicleType: "SUV", price: "" },
        { vehicleType: "Luxury", price: "" },
      ],
    },
    {
      package: "Gold",
      vehicles: [
        { vehicleType: "Hatchback", price: "" },
        { vehicleType: "Sedan", price: "" },
        { vehicleType: "SUV", price: "" },
        { vehicleType: "Luxury", price: "" },
      ],
    },
  ]
  const handleFormValuesChange1 = (changedValues, allValues) => {
    // Update listData when form values change
    const newListData = allValues.FAQContent?.map((item, index) => ({
      title: item.title || '',
      description: item.description || '',
    }));
    setListData(newListData);
  };
  return (
    <div className='App'>
      <div style={{ display: 'flex' }}>
        <div style={{
          width: '200px',
          background: 'rgb(99 99 122 / 36%)',
          padding: '20px',
          height: '100vh',
          position: 'sticky',
          top: '0',
        }}>
          <div style={{ marginBottom: '20px' }}>
            <Link to="/admin/vecrep/nearme" style={{
              color: "black", cursor: "pointer", fontWeight: "bold", fontSize: 15
            }}> <BackwardOutlined />Back to Nearme</Link>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <button onClick={() => handleSelect('option1')} style={{
              padding: 10, width: 120,
              backgroundColor: selectedOption === 'option1' ? 'black' : '#b11226', // Change background color based on selectedOption
              color: "white", border: "none", borderRadius: 10, cursor: "pointer", paddingRight: 10, paddingLeft: 10
            }}>Paragraph 1</button>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <button onClick={() => handleSelect('option3')} style={{
              padding: 10, width: 120,
              backgroundColor: selectedOption === 'option3' ? 'black' : '#b11226', // Change background color based on selectedOption
              color: "white", border: "none", borderRadius: 10, cursor: "pointer"
            }}>FAQ</button>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <button onClick={() => handleSelect('option2')} style={{
              padding: 10, width: 120,
              backgroundColor: selectedOption === 'option2' ? 'black' : '#b11226', // Change background color based on selectedOption
              color: "white", border: "none", borderRadius: 10, cursor: "pointer"
            }}>Add Prices</button>
          </div>
        </div>

        <div style={{ padding: '20px', width: "100%" }}>
          <h1 style={{ textAlign: "center", fontSize: 50, fontWeight: "bold" }}>{singleData?.title}</h1>

          {selectedOption === 'option1' && (
            <>
              <div>
      <CKEditor
        editor={ClassicEditor}
        data={initialData}
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote'
          ],
          heading: {
            options: [
              { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
              { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
              { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
              { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
            ]
          }
        }}
        onReady={(editor) => {
          console.log('CKEditor5 React Component is ready to use!', editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          console.log({ event, editor, data });
          setPara(data);
        }}
        style={{ height: '500px' }}
      />
    </div>

              <div>
                <Form.Item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 30 }}>
                  <Button style={{ backgroundColor: "black", color: "white" }} onClick={handleSubmitss}>
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </>
          )}

          {selectedOption === 'option2' && (
            <div className="">
              <div className="">
                <div className="">
                  <div>
                    <h3 style={{ fontWeight: "bold", color: "black" }}>Add Prices</h3>
                  </div>
                </div>
                <div className="add-page-manage-modal-forms">
                  <div className="">
                    <Form layout="vertical" onFinish={handleSubmitss}>

                    {
                      
                    singleData.vehicleData?.length > 0 &&(
  singleData.vehicleData.map((packageData, packageIndex) => (
    <div key={packageIndex}>
      <h3>{packageData.package}</h3>
      <Row gutter={[16, 16]}>
        {packageData.vehicles.map((vehicle,key) => {
          const vehicleKey = `${packageData.package}_${vehicle.vehicleType}`;

          return (
            <React.Fragment key={vehicleKey}>
              <Col xl={3} lg={3}>
                <Form.Item
                  name={`vehicleType_${key}`} // Unique name for vehicle type
                  initialValue={vehicle.vehicleType} // Set initial value if necessary
                >
                  <Input
                    type="text"
                    value={vehicleTypes[vehicleKey] || vehicle.vehicleType} // Use state value
                    onChange={(e) => handleVehicleTypeChange(vehicleKey, e)}
                    placeholder="Type"
                    style={{ width: 120 }}
                  />
                </Form.Item>
              </Col>
              <Col xl={3} lg={3}>
                <Form.Item
                  name={`price_${vehicleKey[key]}`} // Unique name for price
                  initialValue={vehicle.price} // Set initial value if necessary
                >
                  <Input
                    type="text"
                    value={inputValues[vehicleKey] || vehicle.price} // Use state value
                    onChange={(e) => handleInputChange(vehicleKey, e)}
                    placeholder="Price"
                    style={{ width: 70 }}
                  />
                </Form.Item>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </div>
  ))

)

}{
                      singleData.vehicleData ==[] &&(

datas.map((packageData, packageIndex) => (
  <div key={packageIndex}>
    <h3>{packageData.package}</h3>
    <Row gutter={[16, 16]}>
      {packageData.vehicles.map((vehicle) => {
        const vehicleKey = `${packageData.package}_${vehicle.vehicleType}`;

        return (
          <React.Fragment key={vehicleKey}>
            <Col xl={3} lg={3}>
              <Form.Item
                name={`vehicleType_${vehicleKey}`} // Unique name for vehicle type
                initialValue={vehicle.vehicleType} // Set initial value if necessary
              >
                <Input
                  type="text"
                  value={vehicleTypes[vehicleKey] || vehicle.vehicleType} // Use state value
                  onChange={(e) => handleVehicleTypeChange(vehicleKey, e)}
                  placeholder="Type"
                  style={{ width: 120 }}
                />
              </Form.Item>
            </Col>
            <Col xl={3} lg={3}>
              <Form.Item
                name={`price_${vehicleKey}`} // Unique name for price
                initialValue={vehicle.price} // Set initial value if necessary
              >
                <Input
                  type="text"
                  value={inputValues[vehicleKey] || vehicle.price} // Use state value
                  onChange={(e) => handleInputChange(vehicleKey, e)}
                  placeholder="Price"
                  style={{ width: 70 }}
                />
              </Form.Item>
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  </div>
))
                      )
}



                      <Form.Item style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button type='primary' htmlType='submit'>
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}

{selectedOption === 'option3' && (
          <div className='App'>
            <Form
      form={form}
      onFinish={handleSubmit2}
      onValuesChange={handleFormValuesChange1}
      name='dynamic_form_nest_item'
      style={{ minWidth: 600 }}
      autoComplete='off'
      initialValues={true}
    >
      {singleData?.FAQContent?.map((item, index) => (
        <div key={index} style={{ marginBottom: 8, display: 'flex', gap: '50px', alignItems: 'center', justifyContent: 'center' }}>
          <div>
            <Form.Item
              name={['FAQContent', index, 'title']}
              initialValue={item.title}
            >
              <Input placeholder='Question' style={{ width: '370px', height: '50px' }} />
            </Form.Item>
          </div>
          <div style={{marginTop:25}}>
            <Form.Item
              name={['FAQContent', index, 'description']}
              initialValue={item.description}
            >
              <TextArea placeholder='Answer' style={{ width: '370px', height: '100px' ,marginTop:25}} />
            </Form.Item>
          </div>
          <Button onClick={() => handleRemoveField1(index)}>Remove</Button>
        </div>
      ))}
      <div>
        <Form.Item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 30 }}>
          <Button onClick={handleAddField1}>Add Field</Button>
          <Button type='primary' htmlType='submit' >
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
                  
              
              </div>

          )}
        </div>
      </div>
    </div>
  );
};

export default NearMeEdit;
