import React, { useState, useEffect } from "react";
import "./role.css";
import { FaEdit, FaLock, FaLockOpen, FaPencilAlt } from "react-icons/fa";
import EditCategory from "./EditCategory";
import { Avatar, Popconfirm, Spin, message } from "antd";
import CategoryForm from "./Categoryform";
import moment from "moment";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
const CategoryTable = ({ garageList, fetchGarageList, load }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Adjusted to display 6 items per page

  const [lock, setLock] = useState(true);
  const [lockStatus, setLockStatus] = useState({});
  const [vehicleType, setVehicleType] = useState(0);
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryDesc, setCategoryDesc] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: null,
    search: "",
  });

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  // const fetchGarages = () => {
  //   handleCloseModal();
  //   fetchGarageList();
  // };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("edit-role-modal-overlay")) {
      handleCloseModal();
    }
  };
  // const handleToggleLock = (index) => {
  //   // Update the lock status for the clicked item
  //   setLockStatus((prevStatus) => ({
  //     ...prevStatus,
  //     [index]: !prevStatus[index],
  //   }));
  // };

  // console.log(fetchGarageList);
  const token = localStorage.getItem("admin");
  async function confirm(e, roleId, newStatus) {
    if (e) {
      e.preventDefault();
    }

    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/adminRoles/changeStatus`,
        { roleId, newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.status === 200) {
        fetchGarageList();
        message.success(`Role is now ${newStatus ? "Active" : "Inactive"}`);
        // setStatus(newStatus);
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  function cancel(e) {}

  console.log(vehicleType);
  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList?.data
      ?.filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.createdAt) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.createdAt) <= new Date(filterCriteria.toDate);

        const statusMatch =
          !filterCriteria.status || 
          (!item.status ? "active" : "inactive") === filterCriteria.status;

        const searchMatch =
          !filterCriteria.search ||
          item?.roleName?.toLowerCase()?.includes(filterCriteria?.search?.toLowerCase()) || 
            item?.roleId?.toLowerCase()?.includes(filterCriteria?.search?.toLowerCase()) ;
            

        return fromDateMatch && toDateMatch && statusMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList?.slice(startIndex, endIndex);
  console.log(visibleRows);
  const handleEdit = (index) => {
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
    handleOpenModal();
  };
  console.log(selectedGarage);
  return (
    <Spin spinning={load}>
      <div className="role-card">
        <CategoryForm onSearch={handleFilterChange} />

        <div className="role-table" onClick={handleOverlayClick}>
          {/* <CategoryForm /> */}
          {filteredGarageList?.length === 0 ? (
            <p style={{ textAlign: "center" }}>No Roles available.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Created</th>
                  <th>Role Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredGarageList?.map((item, index) => {
                  const formattedDate = 
                    item?.createdAt?.slice(0, 10)

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>{formattedDate}</td>
                      <td>{item.roleName}</td>
                      <td>
                        <span
                          className={`badge badge-${
                            item.status ? "success" : "danger"
                          }`}
                          // onClick={() => activeStatus(item)}
                        >
                          {item.status ? "Active" : "InActive"}
                        </span>
                      </td>
                      <td>
                        <div className="action-cell">
                          <div
                            className="edit-cell"
                            onClick={() => {
                              handleEdit(index);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <FaPencilAlt />
                          </div>
                          <div className="edit-cell">
                            <Popconfirm
                              title={`Are you sure ${
                                !item.status ? "Active" : "InActive"
                              } this Roles?`}
                              onConfirm={(e) =>
                                confirm(e, item._id, !item.status)
                              }
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              {item.status ? <FaLockOpen /> : <FaLock />}
                            </Popconfirm>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div>
            {isModalVisible && (
              <EditCategory 
              open={isModalVisible}
                handleCloseModal={handleCloseModal}
                fetchGarages={fetchGarageList}
                selectedGarage={selectedGarage}
              />
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CategoryTable;
