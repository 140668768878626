import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { Select } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed,account,calling,fetchGarageList }) => {
  const { width, themeType } = useSelector(({ settings }) => settings);
  const {Option}=Select;
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
const history=useHistory();
  const handleChange=(e)=>{

    console.log(e)
    if(e==="ADMIN"){
    account(0)
    history.push("/admins/dashboard")
    localStorage.setItem("tab1",0)

    }
    else if(e==="VRGarage")
    {
      account(1)
      
      history.push("/admin/dashboard")
    localStorage.setItem("tab1",1)
    }
    else{
      account(2)
      history.push("/admin/vecrep/dashboard")

      localStorage.setItem("tab1",2)
    }
    
  }
  const tab1=localStorage.getItem("tab1")
  return (
    <div className="gx-layout-sider-header">
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon icon-${
              !sidebarCollapsed ? "menu-unfold" : "menu-fold"
            } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
            onClick={() => {
              setSidebarCollapsed(!sidebarCollapsed);
            }}
          />
        </div>
      ) : null}


      <Select
    defaultValue={
      tab1 === "1" ? "VRGarage" :
      tab1 === "2" ? "VecRep" :
  "VRGarage"
    }
    
      style={{ width: 250 }}
      onChange={(e)=>handleChange(e)}

      options={[{ value: 'VRGarage', label: 'VR Garage' } ,{ value: 'Vecrep', label: 'VecRep.com' }]}
    />
    </div>
  );
};

export default SidebarLogo;
