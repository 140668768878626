import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Col, Form, Input, message, Switch } from "antd";
import { BackwardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ApiDomain from "../api/ApiDomain";

const SeoEditNearme = () => {
  const [isActive, setIsActive] = useState();
  const {pageId}=useParams();
  const [load,setLoad]=useState(false)
  const accessToken=localStorage.getItem("admin")
const [data,setData]=useState("")
 const [seoTitle,setTitle]=useState("")
 const [seoDescription,setDescription]=useState("")
 const [seoMetaData,setMeta]=useState("")

 const [seoColonical,setColonical]=useState("")

 const [seoSiteType,setSite]=useState("")
 const [seoSiteMap,setMap]=useState("")


  const customConfigs = {
    Title: {
      styles: { color: "black" },
    },
    Description: {
      styles: { color: "red" },
    },
    "Meta Robots": {
      styles: { color: "blue" },
    },
    Canonical: {
      styles: { color: "blue" },
    },
    Sitemap: {
      styles: { color: "blue" },
    },
    "Type of Sitemap": {
      styles: { color: "green" },
    },
  };

  const handleToggle = (checked) => {
    setIsActive(checked);
  };


 
  const fetchGarageList = async () => {

    try {
      setLoad(true)
      const response = await axios.get(
        `${ApiDomain.Domain}/getSinglePage/${pageId}`,
       
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log(response)
      if (response.status === 200) {

        setData(response.data.data);
        
        // setCount(response.data.totalCount);
        setLoad(false)
        console.log(response.data.data)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  
  useEffect(() => {
    fetchGarageList();
  }, []);

  const handleSubmit = async(e) => {
    e.preventDefault();
    
  

    // Assuming the API endpoint URL is 'https://example.com/api/submit'
    const response = await axios.put(`${ApiDomain.Domain}/SEOdata`,
    {
      paragraphData:data.paragraphData,
      serviceName:data?.serviceName,
vehicleType: data?.vehicleType,
location: data?.location,
url: data?.url,
title: data?.title,
garageId: "",
pageId:pageId,
seoDescription: seoDescription,
        seoMetaData: seoMetaData,
        seoColonical: seoColonical,
        seoSiteMap:seoSiteMap,
        seoSiteType: seoSiteType,
        seoTitle:seoTitle,
categoryType: data?.categoryType,
FAQContent:data?.FAQContent,
       WhyVecrepContent:data.WhyVecrepContent,
    }
    ,
    {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the token in the Authorization header
        },
       
        
        
        


      }
      );
console.log(response)
      if (response.status===200) {
        // Handle successful API response
        message.success("Data updated Successfully");

        console.log("API call successful");
      } else {
        // Handle API error
        console.error("API call failed");
        message.error("Error adding Feature");
      }
}

  
    

  return (
    <>
      <div className="App">
        <div style={{ padding:"20px" }}>
          <Link to="/admin/vecrep/nearme" style={{
            color:"black", cursor:"pointer", fontWeight:"bold", fontSize:15
          }}>
            <BackwardOutlined /> Back to NearMe
          </Link>
        </div>
        <div style={{ padding: "20px", width: "100%" }}>
        <h1 style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}>
            Title      </h1>
            <Col md={24}>
            <CKEditor
              editor={ClassicEditor}
              data={data?.seoTitle}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote'
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                  ]
                }
              }}
              onReady={(editor) => {
                console.log('CKEditor5 React Component is ready to use!', editor);
                // Customize editor configuration
                editor.config.define('config', {
                  h1: { styles: { color: 'black' } },
                  h2: { styles: { color: 'black' } },
                  h3: { styles: { color: 'black' } },
                });
              }}
              style={{ height: '500px' }}
              onChange={(event, editor) => {

                const data = editor.getData();
                const plainText = new DOMParser().parseFromString(data, 'text/html').body.textContent || ''; // Parse to plain text
    console.log('Plain Text:', plainText);
    setTitle(plainText); // Update the state with plain text
              }}
            />
          </Col>
          <h1 style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}>
            Description     </h1>
            <Col md={24}>
            <CKEditor
              editor={ClassicEditor}
              data={data?.seoDescription}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote'
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                  ]
                }
              }}
              onReady={(editor) => {
                console.log('CKEditor5 React Component is ready to use!', editor);
                // Customize editor configuration
                editor.config.define('config', {
                  h1: { styles: { color: 'black' } },
                  h2: { styles: { color: 'black' } },
                  h3: { styles: { color: 'black' } },
                });
              }}
              style={{ height: '500px' }}
              onChange={(event, editor) => {
                const data = editor.getData();
                const plainText = new DOMParser().parseFromString(data, 'text/html').body.textContent || ''; // Parse to plain text
    console.log('Plain Text:', plainText);
    setTitle(plainText); // Update the state with plain text
                setDescription(data)
              }}
            />
          </Col>
      <h1 style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}>
      Meta Robots      </h1>
      <Col md={24}>
      <CKEditor
              editor={ClassicEditor}
              data={data?.seoMetaData}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote'
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                  ]
                }
              }}
              onReady={(editor) => {
                console.log('CKEditor5 React Component is ready to use!', editor);
                // Customize editor configuration
                editor.config.define('config', {
                  h1: { styles: { color: 'black' } },
                  h2: { styles: { color: 'black' } },
                  h3: { styles: { color: 'black' } },
                });
              }}
              style={{ height: '500px' }}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                setMeta(data)
              }}
            />
      </Col>
      <h1 style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}>
      Canonical      </h1>
      <Col md={24}>
      <CKEditor
              editor={ClassicEditor}
              data={data?.seoColonical}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote'
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                  ]
                }
              }}
              onReady={(editor) => {
                console.log('CKEditor5 React Component is ready to use!', editor);
                // Customize editor configuration
                editor.config.define('config', {
                  h1: { styles: { color: 'black' } },
                  h2: { styles: { color: 'black' } },
                  h3: { styles: { color: 'black' } },
                });
              }}
              style={{ height: '500px' }}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                setColonical(data)
              }}
            />
      </Col>
      <h1 style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}>
      Sitemap      </h1>
      <Col md={24}>
      <CKEditor
              editor={ClassicEditor}
              data={data?.seoSiteMap}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote'
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                  ]
                }
              }}
              onReady={(editor) => {
                console.log('CKEditor5 React Component is ready to use!', editor);
                // Customize editor configuration
                editor.config.define('config', {
                  h1: { styles: { color: 'black' } },
                  h2: { styles: { color: 'black' } },
                  h3: { styles: { color: 'black' } },
                });
              }}
              style={{ height: '500px' }}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                setMap(data)
              }}
            />
      </Col>
      <h1 style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}>
      Type of Sitemap      </h1>
      <Col md={24}>
      <CKEditor
              editor={ClassicEditor}
              data={data?.seoSiteType}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote'
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                  ]
                }
              }}
              onReady={(editor) => {
                console.log('CKEditor5 React Component is ready to use!', editor);
                // Customize editor configuration
                editor.config.define('config', {
                  h1: { styles: { color: 'black' } },
                  h2: { styles: { color: 'black' } },
                  h3: { styles: { color: 'black' } },
                });
              }}
              style={{ height: '500px' }}
              onChange={(event, editor) => {
                const data = editor.getData();
                console.log({ event, editor, data });
                setSite(data)
              }}
            />
      </Col>
      
    </div>
  
        <div
          style={{
            padding: "20px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            Page Status{" "}
            <span style={{ color: data.status ? "green" : "red" }}>
              {data.status ? "Active" : "Inactive"}
            </span>
          </h1>
          {/* <Switch checked={data.status} onChange={handleToggle} /> */}
          <Form.Item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 30 }}>
            <Button type='primary' htmlType='submit' onClick={handleSubmit}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default SeoEditNearme;
