import { CloudUploadOutlined, DeleteFilled } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ApiDomain from "../../api/ApiDomain";
import { message, Input } from "antd";

function MultipleImageUpload({
  selectedGarage,
  handleCloseModal,
  fetchGarageList,
  setImagesPreview,
}) {
  const token = localStorage.getItem("admin");

  console.log(selectedGarage);

  const initialImageUrls =
    selectedGarage?.garageImages?.map((imageObj) => ({
      url: imageObj?.image,
      alt: imageObj?.alt || "", // Include alt text here
    })) || [];

  const [images, setImages] = useState(initialImageUrls);
  const [filesToUpload, setFilesToUpload] = useState([]); // Store files to upload
  const [previewImage, setPreviewImage] = useState(null);
  const [previewAltText, setPreviewAltText] = useState("");

  useEffect(() => {
    setImages(initialImageUrls);
  }, [selectedGarage?.garageImages]);

  console.log(images, filesToUpload);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    const newImages = files.map((file) => {
      const randomNum = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number
      const altText = `${selectedGarage.vecrepId} ${selectedGarage.garageName} in ${selectedGarage.city} ${randomNum}`;
      return {
        file,
        url: URL.createObjectURL(file), // Create a local URL for the preview
        alt: altText, // Default to a generated alt text
      };
    });

    setImages((prevImages) => [
      ...prevImages,
      ...newImages.map((img) => ({ url: img.url, alt: img.alt })),
    ]);
    setFilesToUpload((prevFiles) => [...prevFiles, ...newImages]);
  };

  const handleSubmit = async () => {
    if (filesToUpload.length === 0 && images.length === 0) {
      message.error("No images to upload.");
      return;
    }

    try {
      // Process new file uploads
      const uploadPromises = filesToUpload.map((fileData) => {
        const formData = new FormData();
        formData.append("file", fileData.file);
        return axios
          .post(`${ApiDomain.Domain}/uploadImage`, formData)
          .then((response) => response.data.imagePath1)
          .then((imageUrl) => ({
            url: imageUrl,
            alt: fileData.alt, // Use the alt text from fileData
          }));
      });

      const uploadedImages = await Promise.all(uploadPromises);

      // Filter out blob URLs from the images state
      const validImages = images.filter(
        (image) => !image.url.startsWith("blob:")
      );

      // Create updated images array with new URLs and corresponding alt texts
      const updatedImages = [...validImages, ...uploadedImages];

      const requestData = {
        garageId: selectedGarage._id
          ? selectedGarage._id
          : selectedGarage.garageId,
        garageImages: updatedImages.map((image) => ({
          image: image.url,
          alt: image.alt,
        })),
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.put(
        `${ApiDomain.Domain}/editGarageProfile`,
        requestData,
        { headers }
      );

      if (response?.data?.responseCode === 200) {
        handleCloseModal();
        fetchGarageList();
        message.success(response?.data?.message);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleAltTextChange = (index, newAltText) => {
    setImages((prevImages) =>
      prevImages.map((image, i) =>
        i === index ? { ...image, alt: newAltText } : image
      )
    );
  };

  const handleDelete = (index) => {
    const deletedImage = images[index];

    // Clear the preview if the deleted image is the currently previewed image
    if (deletedImage.url === previewImage) {
      setPreviewImage(null);
      setPreviewAltText("");
    }

    // Remove the image from the displayed images
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    // Remove the deleted image file from filesToUpload
    setFilesToUpload((prevFiles) =>
      prevFiles.filter(
        (file) => URL?.createObjectURL(file) !== deletedImage.url
      )
    );
  };

  const handleImageClick = (index) => {
    const clickedImage = images[index];
    setPreviewImage(clickedImage?.url || null);
    setPreviewAltText(clickedImage?.alt || "");
  };

  const handlePreviewAltTextChange = (e) => {
    setPreviewAltText(e.target.value);
  };

  const handleSaveAltText = () => {
    const index = images.findIndex((image) => image.url === previewImage);
    handleAltTextChange(index, previewAltText);
  };

  return (
    <div style={{ margin: 10 }}>
      <div style={{ display: "flex", gap: 10 }}>
        <div className="upload">
          <button type="button" className="btn-warning">
            <CloudUploadOutlined /> Select Images
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              accept="image/*"
            />
          </button>
        </div>
        <div className="upload">
          <button type="button" className="btn-warning" onClick={handleSubmit}>
            <CloudUploadOutlined /> Submit Images
          </button>
        </div>
      </div>
      <div className="image-container">
        <div className="gallery-container">
          {images.length === 0 ? (
            <div className="gallery-image-empty">No Upload Images</div>
          ) : (
            images.map((image, index) => (
              <div key={index} className="gallery-image-box">
                <button
                  onClick={() => handleDelete(index)}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 1,
                  }}
                >
                  <DeleteFilled />
                </button>
                {image.url ? (
                  <img
                    src={image.url}
                    alt={image.alt}
                    onClick={() => handleImageClick(index)}
                  />
                ) : null}
                <h4 className="image-alt">{image.alt}</h4>
              </div>
            ))
          )}
        </div>
        <div className="preview-box">
          <div className="preview-image">
            {previewImage ? (
              <>
                <img src={previewImage} alt="Preview" />
                <div className="preview-alt-box">
                  <Input
                    value={previewAltText}
                    onChange={handlePreviewAltTextChange}
                    placeholder="Edit Alt Text"
                    style={{ marginTop: 10 }}
                  />
                  <button
                    type="button"
                    className="btn-warning btn-alt"
                    onClick={handleSaveAltText}
                    style={{ marginTop: 10 }}
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              <span style={{ padding: 10 }}>No Image Preview</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultipleImageUpload;
