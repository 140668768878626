import React, { useState, useEffect } from "react";
import "./category.css";
import { FaEdit, FaLock, FaLockOpen, FaPencilAlt } from "react-icons/fa";
import EditCategory from "./EditCategory";
import { Avatar, Popconfirm, Spin, message, Pagination, Modal, Row, Col, Input,Form, Select } from "antd";
import CategoryForm from "./Categoryform";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";

const CategoryTable = ({ garageList, fetchGarageList, load,loads,fetch }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id,setId]=useState("");
  const [lock, setLock] = useState(true);
  const [lockStatus, setLockStatus] = useState({});
  const [modal1,setModal1]=useState(false)
  const [vehicleType, setVehicleType] = useState(0);
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryDesc, setCategoryDesc] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [loading,setLoading]=useState(load)
  const [city,setCity]=useState(null)
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [status, setStatus] = useState(null);
  const [cities,setCities]=useState([])
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
    tier:"",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const handleOpenModal = () => {
    setIsModalVisible(true);

  };
  const {Option}=Select;
  const handleOpenModal1 = (item) => {
    setModal1(true);
    setCity(item)
    console.log(item)
    
  };


  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModal1(false)
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };
  console.log(garageList.length)

  const handleToggleLock = (index) => {
    setLockStatus((prevStatus) => ({
      ...prevStatus,
      [index]: !prevStatus[index],
    }));
  };

  const handleEdit = () => { // Define handleEdit function
    handleOpenModal();
  };

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList, currentPage, pageSize]);

  const applyFilters = () => {
    const filteredList = filteredGarageList
      .filter((item) => {
       
        const statusMatch =
          !filterCriteria.status || item.status === filterCriteria.status;
          const statusMatchTier =
          !filterCriteria.tier || item.tier === filterCriteria.tier;

        const searchMatch =
          !filterCriteria.search ||
          item.cityName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase());

        return statusMatch && searchMatch && statusMatchTier;
      })
      .sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedList = filteredList.slice(startIndex, endIndex);

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

 


  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
  };
console.log(cities)
console.log(filteredGarageList)
async function handleSubmit2(values) {
  console.log(values);
setLoading(true)
  try {
    const response = await axios.put(
      `${ApiDomain.Domain}/updateCity`,
      { city:city.cityName,tier:values.tier?values.tier :city.tier },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response);
    if (response?.status===200) {
      message.success("Tier Updated Successfully");

      handleCloseModal();
      fetchCities();

      setLoading(false)
    }

    // Update the garage list after successful approval
  } catch (error) {
    console.log(error.message);
  }
}const fetchCities = async () => {

  setLoading(true)
  try {
    const response = await axios.get(
      `${ApiDomain.Domain}/listOfCities/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data.data)
  setFilteredGarageList(response?.data?.data)
  setLoading(false)
  
  } catch (error) {
    console.log(error.message);
  }
};


async function confirm(e, status,id) {
  try {
    const response = await axios.put(
      `${ApiDomain.Domain}/statusCity`,
      { status,id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (response.status === 200) {
      message.success("Status Updated Succesfully");
    
    }
  } catch (error) {
    console.log(error.message);
  }
}

function cancel(e) {}

  return (
    <Spin spinning={load?load:loading}>
      <div className="category-card">
        <CategoryForm onSearch={handleFilterChange} cities={setFilteredGarageList} name={setId} fetch={fetch}/>

        <div className="category-table" onClick={handleOverlayClick}>
          {filteredGarageList.length === 0 ? (
            <p style={{ textAlign: "center" }}>No cities available.</p>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>City Name</th>
                    <th>Tier</th>
                    <th>Tier Edit</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredGarageList.map((item, index) => {
                    console.log(filteredGarageList)
                   
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        
                        <td>{item?.cityName}</td>
                        <td>{item?.tier===1 && "Tier-1"|| item.tier===2 && "Tier-2" || item.tier===3 && "Tier-3"}</td>


   <td>
                          <div className="action-cell">
                            <div
                              className="edit-cell"
                              onClick={() => handleOpenModal1(item)}
                            >
                              <FaPencilAlt />
                            </div>
                          </div>
                        </td>
                        <td>
                          <span
                            className={`badge badge-${
                              item.status ? "success" : "danger"
                            }`}
                          >
                            {item.status ? "Active" : "Inactive"}
                          </span>
                        </td>
                        <td>

<Popconfirm
    title={`Are you sure${item.status ? "Active" : "Inactive"}`}
    onConfirm={(e) => confirm(e, item.status,item._id)}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
  <div className="action-cell">
    <div className="edit-cell">
   {item.status?<FaLockOpen />: <FaLock />}
    </div>
  </div>
  </Popconfirm>
</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div style={{ marginTop: "20px", float: "right" }}>
                <Pagination 
                simple
                  current={currentPage}
                  total={filteredGarageList.length}
                  onChange={onPageChange}
                  onShowSizeChange={onPageSizeChange}
                />
              </div>
            </>
          )}

          <div>
            {isModalVisible && (
              <EditCategory
                handleCloseModal={handleCloseModal}
                vehicle={vehicleType}
                categoryName={categoryName}
                categoryDesc={categoryDesc}
                categoryImage={categoryImage}
                categoryId={categoryId}
                fetchGarages={fetchGarageList}
              />
            )}
          </div>
        </div>
      </div>
      <div>
 
</div>
{modal1 && (
    <Modal visible={modal1}  centered onCancel={handleCloseModal} footer={null}   width={"auto"}>
    <div className="">
            <div className="add-garage-manage-modal-content-header">
              <div>
                <h3 style={{ color: "#b11226" }}>Tier Edit</h3>
              </div>
            </div>
            <div className="add-category-modal-form">
              <Spin spinning={load}>
                <Form
                  // form={form}
                  layout="vertical"
                  style={{ minWidth: "600px" }}
                  onFinish={handleSubmit2}
                >
                  <div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "80%", marginTop: 10 }}>
                        <Col xl={24} lg={24}>
                          <Form.Item label="Tiers : " name="tier">
                          <Select defaultValue={city?.tier===1 && "Tier-1"|| city.tier===2 && "Tier-2" || city.tier===3 && "Tier-3"}
                          showSearch
                          style={{  }}
                          placeholder="Select Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value="1">
                            Tier-1
                          </Option>
                          <Option value="2">Tier-2</Option>
                          <Option value="3">Tier-3</Option>
                        </Select>
                          </Form.Item>
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div className="add-garage-manage-popup-footer">
                    <button
                      className="cancel-button"
                      onClick={handleCloseModal}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </button>

                    <button className="submit-button" htmlType="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </Modal>
      )}
    </Spin>
  );
};

export default CategoryTable;
