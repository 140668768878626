import React, { useState, useEffect } from "react";
import "./feature.css";
import { FaEdit, FaLock, FaLockOpen, FaPencilAlt } from "react-icons/fa";
import EditCategory from "./EditCategory";
import moment from "moment";
import CategoryForm from "./Categoryform";
import { Popconfirm, message } from "antd";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";

const CategoryTable = ({ data ,ListPromo}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details
  const itemsPerPage = 5; // Adjusted to display 6 items per page

  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(null);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, data]);

  const token = localStorage.getItem("admin");

  const applyFilters = () => {
    const filteredList = data
      ?.filter((item) => {
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.startDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.startDate) <= new Date(filterCriteria.toDate);

        const searchMatch =
          !filterCriteria.search ||
          item.featureName.toLowerCase().includes(filterCriteria.search.toLowerCase());

          const statusMatch =
          !filterCriteria.status || (item.status ? "active" : "inactive") === filterCriteria.status;


        return fromDateMatch && toDateMatch && searchMatch && statusMatch
      })
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

 

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList?.slice(startIndex, endIndex);
console.log(visibleRows)
  const handleOpenModal = (index) => {
    console.log(index)
    setSelectedGarage(filteredGarageList[index]); // Updated to use visibleRows
    setIsModalVisible(true);
    console.log(index)

  };
  console.log(filteredGarageList)


  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-feature-modal-overlay")) {
      handleCloseModal();
    }
  };

  async function confirm(e, featureId, newStatus) {
    if (e) {
      e.preventDefault();
    }

    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/adminFeatures/changeStatus`,
        { featureId, newStatus: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        ListPromo();
        message.success(`Feature is now ${newStatus ? 'Active' : 'Inactive'}`);
        setStatus(newStatus);
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  function cancel(e) {}

  return (
    <>
      <CategoryForm onSearch={handleFilterChange} />

      <div className="feature-table" onClick={handleOverlayClick}>
        {filteredGarageList?.length === 0 ? (
          <p style={{textAlign:"center"}}>No records available.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Feature Name</th>
                <th>Create Date</th>
                <th>Update Date</th>
                {/* <th>Privilege Type</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredGarageList?.map((item, index) => {
                const formattedDateStart = moment(item.startDate).format('DD-MM-YYYY');
                const formattedDateEnd = moment(item.expiryDate).format('DD-MM-YYYY');
                const isExpired = new Date(item.expiryDate) < new Date();
                console.log((item?.privileges?.slice(0))?.privilegeType
                )

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.featureName}</td>
                    <td>{formattedDateStart}</td>
                    <td>{formattedDateEnd}</td>
                    {/* <td>{(item?.privileges)?.privilegesType}</td> */}
                    <td>
                     
                        <span
                          className={`badge badge-${isExpired ? 'warning' : item.status ? 'success' : 'danger'}`}
                        >
                          {isExpired ? 'Expired' : item.status ? 'Active' : 'Inactive'}
                        </span>
                    </td>
                    <td>
                      <div className="action-cell">
                        <div className="edit-cell" onClick={()=>handleOpenModal(index)}>
                          <FaPencilAlt />
                        </div>
                        <div className="edit-cell">

                        <Popconfirm
                        title={`Are you sure ${
                         !item.status ? 'Active' : 'InActive'
                        } this Feature?`}
                        onConfirm={(e) => confirm(e, item._id,(!item.status))}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        {item.status ? <FaLockOpen /> : <FaLock />}
                          </Popconfirm>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <div>{isModalVisible && <EditCategory handleCloseModal={handleCloseModal} open={isModalVisible}  data={data} selectedGarage={selectedGarage}  ListPromo={ListPromo}/>}</div>
      </div>
    </>
  );
};

export default CategoryTable;
