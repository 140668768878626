import React, { useEffect, useState } from "react";
import "./Inventory.css";
import { AutoComplete, Popover, Select } from "antd";
import { FaSearch, FaTimes } from "react-icons/fa";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";

const InventoryForm = ({ onSearch,Csv,param,fetchGarageListed}) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const {Option}=Select;
  const role=localStorage.getItem("role")
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
const [load,setLoad]=useState(false)
const token=localStorage.getItem("admin")
const [source,setSource]=useState([])
const handleInputChange = (value) => {
  const suggestions = source.filter(
    (garage) =>
      typeof garage.garageName === "string" &&
      garage?.garageName?.toLowerCase().includes(value.toLowerCase())
  );
  setOptions(suggestions);
};
 

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
  };


  const fetchGarageList = async (page) => {

    try {
      // fetchstart();
      setLoad(true)
      const response = await axios.get(
        `${ApiDomain.Domain}/garageNameList`,
        
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data)
      if (response.status === 200) {
        setSource(response.data.data);
        // setCount(response.data.totalCount);
        setLoad(false)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  
  useEffect(() => {
    fetchGarageList();
  }, []);
  const handleOptionClick = (option) => {
    const garageId = option.key; 
    console.log(garageId)// Access the ID from the option object
    // param(garageId)
  
    fetchGarageListed(garageId);


    // Use the garageId as needed
  };
  return (
    <div>
      <div className='inventory-manage-card-header'>
        <form className='inventory-manage-form-control'>
        <div className='sale-manage-input' style={{marginTop:10}} >
        <Select
        showSearch
        mode="multiple"
                              placeholder="Search  Garage"
                              style={{width:300 }}
                              onChange={(e)=>handleInputChange(value)}
                              onSelect={(value, option) => handleOptionClick(option)}
                              >
                              {source?.map((suggestion) => (
                                <Option key={suggestion._id} value={suggestion.garageName}>
                                <div style={{ display: 'flex',gap:10}}>
                                  <div style={{width:50,border:"1px solid #b11226",height:35,borderRadius:8}}>
                                  <p  style={{textAlign:"center",fontWeight:"bold",paddingTop:7,color:"yellow"}}>{suggestion.garageId?suggestion.garageId:"-"}</p>
                                  </div>
                                  <div>
                                  <p style={{ marginLeft: 10,padding:0 ,marginBottom:0,color:"#b11226",fontWeight:"bold"}}>{suggestion.garageName?suggestion.garageName:"-"}</p>
                                  <p style={{ marginLeft: 10,padding:0,marginBottom:0,color:"grey" }}>{suggestion.city?suggestion.city:"-"}</p>
                                  </div>
                                </div>
                              </Option>
                              ))}
                            </Select>
                            
                            
          </div>


          

          

          <div>
          
            <Popover content={"Clear Filters"}
      trigger="hover"
    
            >
            <button
              type='button'
              className='inventory-manage-form-button'
              onClick={handleClear}>
            <CloseOutlined />
            </button>
            </Popover>
          </div>
          {role ==="admin" &&(

          <div onClick={Csv}>
          <Popover content={"Export CSV"}
      trigger="hover"
    
            >
            <button
              type='button'
              className='inventory-manage-form-excel'>
                <TbFileExport/>
            </button>
            </Popover>
          </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default InventoryForm;
