import React, { useState, useEffect } from "react";
import "./Rating.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { Pagination } from "antd";
import RatingTable from "./Ratingtable";
import { ReloadOutlined } from "@ant-design/icons";

const Rating = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load,setLoad]=useState(false)
  const itemsPerPage = 7; // Adjusted to display 6 items per page
const [param,setParam]=useState("");
  const[count,setCount]=useState(1)
  const [page,setPage]=useState(0)
  const fetchstart=()=>{
    setLoad(true)
  }
  const fetchsuccess=()=>{
    setLoad(false)
  }
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-rating-manage-modal-overlay")) {
      handleCloseModal();
    }
  };
  const fetchGarageList = async (garageId) => {

    try {
      fetchstart();
      const response = await axios.post(
        `${ApiDomain.Domain}/ratingsAndReviewsForGarages`,
        {
         
          garageId:garageId?garageId:"",
          // Use the current value of the 'page' state
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {

        setGarageList(response.data.data);
        
        setCount(response.data.totalCount);
        fetchsuccess();
        console.log(response.data)
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  
  useEffect(() => {
    fetchGarageList();
  }, []);

  
  
  

  return (
    <>
      <div>
      
      </div>
      <div className='rating-manage-container' onClick={handleOverlayClick}>
        <div className='rating-manage-content'>
          <div className='rating-manage-header'>
            <h3 className='rating-manage-head'>rating</h3>
            <ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>fetchGarageList()} />

          </div>
          <div className='rating-manage-card'>
            <RatingTable garageList={garageList} load={load}  count={count}   param={setParam}         handleCloseModal={handleCloseModal}
  fetchGarageList={fetchGarageList}/>
          </div>
        </div>
   
      </div>
   
    </>
  );
};

export default Rating;
