import React, { useEffect, useState } from "react";
import "./Purchase.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import CategoryForm from "./Categoryform";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
import {CloudUploadOutlined} from "@ant-design/icons"
const CategoryTable = ({ garageList, load, fetchGarageList,param }) => {
  console.log(garageList)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);

  const [id, setId] = useState("");
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList
      .filter((item) => {
        // Filtering logic based on fromDate, toDate, status, and search
        const formattedDate = moment(item?.chooseDate, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
  
        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          formattedDate.isSameOrAfter(moment(filterCriteria.fromDate, "YYYY-MM-DD"), 'day');
  
        const toDateMatch =
          !filterCriteria.toDate ||
          formattedDate.isSameOrBefore(moment(filterCriteria.toDate, "YYYY-MM-DD"), 'day');
  
    
      
        const searchMatch =
          !filterCriteria.search ||
          item.customerName.toLowerCase().includes(filterCriteria.search.toLowerCase()) ||
          (item.totalAmounts &&
            item.totalAmounts.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
            (item.totalDiscount &&
              item.totalDiscount.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
              (item.totalRates &&
                item.totalRates.toLowerCase().includes(filterCriteria.search.toLowerCase())) ||
                (item.totalTax &&
                  item.totalTax.toLowerCase().includes(filterCriteria.search.toLowerCase()))  ||
                  (item.garageName &&
                    item.garageName.toLowerCase().includes(filterCriteria.search.toLowerCase()))  


        return fromDateMatch && toDateMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.chooseDate) - new Date(a.chooseDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const modalV=(index)=>{
    setModal(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows


  }
  const cancelV=()=>{
    setModal(false)
  }
  const modalX=()=>{
    setModalXV(true)
  }
  const cancelx=()=>{
    setModalXV(false)
  }

  const handleDownload = (url) => {
    // If URL is provided, open PDF in another tab
    window.open(url, '_blank');
  
};
  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };


  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
        const formattedDate = moment(item?.chooseDate).format(
          "DD-MM-YYYY"
        );

        return [
            startIndex + index + 1,
            formattedDate || "-",
            item.billNumber || "-",
            item.garageName || "-",


            item.customerName || "-",
            item.totalRates || "-",
            item.totalDiscount || "-",
            item.totalTax || "-",
            item.totalAmounts || "-",
            item.invoiceURL || "-",
            // Add the image URL or any other relevant data
            // Assuming the image URL is stored in item.uploadedImage
        ];
    });

    // Add header row
    formattedData.unshift([
        "S.No",
        "Date",
        "Bill No",
        "Garage Name",

        "Vendor Name",
        "Subtotal (₹)",
        "Discount (₹)",
        "GST (₹)",
        "Grand Total (₹)",
        "Invoice"
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
};

  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };

  function cancel(e) {}

  async function confirm(e,  newStatus,subAdminId) {
    console.log(newStatus)
    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/subAdmin/changeStatus`,
        { subAdminId,newStatus},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        fetchGarageList();
        message.success("Status Change Success");
        cancel();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>

    {/* <Modal visible={modal} onCancel={cancelV}>
      <h1>ssdsds</h1>

    </Modal> */}
      

      <Spin spinning={load}>
        <CategoryForm onSearch={handleFilterChange} Csv={handleExportExcel}  param={param} fetchGarageListed={fetchGarageList}/>
        {!load && (
          <div className="purchase-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Date</th>
                  <th className="th2">Bill No</th>
                  <th className="th4">Garage name</th>

                  <th className="th4">Vendor name</th>
                  <th className="th7">Subtotal &#x20b9;  </th>

                  <th className="th7">Discount &#x20b9;  </th>

                  <th className="th8">Gst &#x20b9;  </th>

                  <th className="th9">GrandTotal &#x20b9;   </th>

                  <th className="th100">Uploaded Image</th>

                  <th className="th11">View Invoice</th>



                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Sale Invoice List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.chooseDate).format(
                      "DD-MM-YYYY"
                    );

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        
                        <td>{formattedDate}</td>
                        <td>{item.billNumber}</td>
                        <td>{item.garageName || "-"}</td>



                        <td onClick={(e)=>modalV(index)} style={{textDecoration:"underline",cursor:"pointer"}}>{item.customerName}</td>



                        <td>{item.totalRates}</td>

                        <td>{item.totalDiscount}</td>
                        <td>{item.totalTax}</td>


                        <td>{item.totalAmounts}</td>





             
  
                        <td style={{cursor:"pointer"}}>
                          <span
                          className="badge badge-success"
                          // onClick={() => activeStatus(item)}
                          >
                            {"View"}
                          </span>
                        </td>
                       
                        <td>
                          {item.invoiceURL===""?(
                             <span style={{textAlign:"center"}}
                          >
                            -
                          </span>)
                          :(
                          <span style={{cursor:"pointer"}}
                          className={'badge badge-success'}
                          onClick={() => handleDownload(item.invoiceURL)}
                          >
                            {"View"}
                          </span>
                          )}
                        </td>
                     
                      
                        
                        
                      

                      </tr>

                      
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modal && (
                 <div className="add-purchase-manage-modal-overlay">
                 <div className="add-purchase-manage-modal-contents">
                   <div className="add-purchase-manage-modal-content-header">
                     <span
                       className="add-purchase-manage-close-button"
                       onClick={cancelV}
                     >
                       &times;
                     </span>
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"white" }}>Customer Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-purchase-manage-modal-forms"
                     style={{ width: 500,display:"flex",flexDirection:"row"}}
                   >
                    <div style={{}}>
                    <Avatar size={100} shape="square" src={selectedGarage.customerImage} />


                      </div>
                     

                  
                   <div style={{textAlign:"",padding:"0px 20px 0px 20px"}}>
                  
                <div>

                <p style={{paddingLeft:27,fontWeight:"bold",marginBottom:"0.5em"}}>Booking Id:<span style={{fontSize:15,padding:20}}>{selectedGarage.bookingId}</span></p>

                        <p style={{paddingLeft:32,fontWeight:"bold",marginBottom:"0.5em"}}>Full Name:<span style={{fontSize:15,padding:20}} >{selectedGarage.customerName}</span></p>
      

      <p style={{paddingLeft:0,fontWeight:"bold",marginBottom:"0.5em"}}>Phone Number: <span style={{fontSize:15,padding:18}}>8340073458</span></p>

      <p style={{paddingLeft:60 ,fontWeight:"bold",marginBottom:"0.5em"}}>Email: <span style={{fontSize:15,padding:17}}>Suresh@gmail.com</span></p>

      
                 </div>
                 </div>
                 </div>

                 
                 </div>
                 </div>
              
              )}
            </div>
            <div>
              {modalXV && (
                 <div className="add-purchase-manage-modal-overlay">
                 <div className="add-purchase-manage-modal-contents">
                   <div className="add-purchase-manage-modal-content-header">
                     <span
                       className="add-purchase-manage-close-button"
                       onClick={cancelx}
                     >
                       &times;
                     </span>
                     <div>
                       <h3 style={{ fontWeight: "bold",color:"white" }}>vehicle Details</h3>
                     </div>
                   </div>
                   <div
                     className="add-purchase-manage-modal-forms"
                     style={{ width: 500,padding:15}}
                   >
                    {/* <div style={{}}>
                      <Avatar size={100} shape="square"  >
                      </Avatar>

                      </div>
                      */}

                  
                   </div>
                   <div style={{textAlign:"",padding:"0px 30px 0px 30px"}}>
                  
                <div>

                <p style={{paddingLeft:20,fontWeight:"bold"}}>Vehicle Number:<span style={{fontSize:15,padding:20}}>56FFFF</span></p>

                        <p style={{paddingLeft:83,fontWeight:"bold"}}>Brand:<span style={{fontSize:15,padding:20}} >Suziki</span></p>
      

      <p style={{paddingLeft:83,fontWeight:"bold"}}>Modal: <span style={{fontSize:15,padding:18}}>Duke 200</span></p>

      <p style={{paddingLeft:93 ,fontWeight:"bold"}}>Year: <span style={{fontSize:15,padding:17}}>2024</span></p>
      <p style={{paddingLeft:58,fontWeight:"bold"}}>Fuel Type: <span style={{fontSize:15,padding:17}}>Petrol</span></p>

      
                 </div>
                 </div>

                 
                 </div>
                 </div>
              
              )}
            </div>

          
           
          </div>
          
        )}
         <Pagination
          onChange={(page) => onChange(page)}
          simple
          total={filteredGarageList.length}
          pageSize={itemsPerPage}
          current={currentPage}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default CategoryTable;
