import React, { useState } from "react";
import "./Subadmin.css";
import { Popover } from "antd";
import { FaSearch, FaTimes } from "react-icons/fa";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const CategoryForm = ({ onSearch,Csv }) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
const role=localStorage.getItem("role")
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedFromDate(selectedDate);
    setDisableToDate(false);
    setSelectedToDate(""); // Reset "To Date" when "From Date" changes
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleToDateChange = (e) => {
    setSelectedToDate(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    console.log(e.target.value)
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status:e.target.value,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
  };

 

  return (
    <div>
      <div className='garage-manage-card-header'>
        <form className='garage-manage-form-control'>
          <div className='garage-manage-input'>
            {/* <label htmlFor='fromdate' style={{ fontWeight: "bold" }}>
              From Date
            </label> */}
            <input
              type='date'
              id='fromdate'
              name='fromdate'
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder='From Date'
              className='garage-manage-input-cal'
            />
          </div>

          <div className='garage-manage-input'>
            {/* <label htmlFor='todate' style={{ fontWeight: "bold" }}>
              To Date
            </label> */}
            <input
              type='date'
              id='todate'
              name='todate'
              value={selectedToDate}
              onChange={handleToDateChange}
              placeholder='To Date'
              className='garage-manage-input-cal'
              disabled={disableToDate}
              min={selectedFromDate} 
            />
          </div>

          <div className='garage-manage-input'>
            {/* <label htmlFor='status' style={{ fontWeight: "bold" }}>
              Admin Status
            </label> */}
            <select
              value={status}
              name='status'
              onChange={handleStatusChange}
              className='garage-manage-input-cal'
            >
              <option value='' disabled>
                Choose an option
              </option>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>

          {/* <div className='garage-manage-input'>
            <label htmlFor='status' style={{ fontWeight: "bold" }}>
              Admin Role
            </label>
            <select
              value={status}
              name='status'
              onChange={handleStatusChange}
              className='garage-manage-input-cal'
            >
              <option value='' disabled>
                Choose an option
              </option>
              <option value='supervisor'>Supervisor</option>
              <option value='volunteer'>Volunteer</option>
            </select>
          </div> */}

          <div className='garage-manage-input'>
            {/* <label htmlFor='search' style={{ fontWeight: "bold" }}>
              Search
            </label> */}
            <input
              type='search'
              name='search'
              placeholder='Search...'
              className='search-input'
              onChange={handleSearchChange}
            />
          </div>

          {/* <div>

          
            <Popover content={"Search"}
      trigger="hover"
    
            >
            <button
              type='button'
              className='garage-manage-form-button'
              onClick={handleSubmit}>
<SearchOutlined/>            </button>
            </Popover>
          </div> */}

          <div>
          
            <Popover content={"Clear Filters"}
      trigger="hover"
    
            >
            <button
              type='button'
              className='garage-manage-form-button'
              onClick={handleClear}>
            <CloseOutlined />
            </button>
            </Popover>
          </div>
          {role ==="admin" &&(

          <div onClick={Csv}>
          <Popover content={"Export CSV"}
      trigger="hover"
    
            >
            <button
              type='button'
              className='garage-manage-form-excel'>
                <TbFileExport/>
            </button>
            </Popover>
          </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
