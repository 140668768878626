import React, { useState, useEffect } from "react";
import "./Subadmin.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CategoryTable from "./Categorytable";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { ReloadOutlined } from "@ant-design/icons";

const Sales = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [garageList, setGarageList] = useState([]);
  const token = localStorage.getItem("admin");
  const [load,setLoad]=useState(false)
  const fetchstart=()=>{
    setLoad(true)
  }
  const fetchsuccess=()=>{
    setLoad(false)
  }
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    // Check if the click occurred outside of the modal content
    if (e.target.classList.contains("add-garage-manage-modal-overlay")) {
      handleCloseModal();
    }
  };
  const fetchGarageList = async () => {
    try {
      fetchstart()
      const response = await axios.get(`${ApiDomain.Domain}/sales`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      if (response.status===200){
        
        setGarageList(response.data.detailedWallet);
        fetchsuccess()
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);

  return (
    <>
      <div>
      
      </div>
      <div className='garage-manage-container' onClick={handleOverlayClick}>
        <div className='garage-manage-content'>
          <div className='garage-manage-header'>
            <h3 className='garage-manage-head'>Coins History</h3>
            <ReloadOutlined style={{ marginLeft: '10px', border:"1px solid black", cursor: 'pointer',color:"black",padding:7,borderRadius:5 }} onClick={()=>fetchGarageList()} />

          
          </div>
          <div className='garage-manage-card'>
            <CategoryTable garageList={garageList} load={load}             handleCloseModal={handleCloseModal}
  fetchGarageList={fetchGarageList}/>
          </div>
        </div>
      
      </div>
    </>
  );
};

export default Sales;
