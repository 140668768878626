import React, { useEffect, useState } from "react";
import "./Rating.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import { Avatar, Col, Form, Input, Modal, Pagination, Popconfirm, Row, Spin, message } from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from 'xlsx';
import RatingForm from "./Ratingform";
import { DeleteOutlined, StarFilled } from "@ant-design/icons";
const RatingTable = ({ garageList, load, fetchGarageList, count, param }) => {
  console.log(garageList)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [modalXV, setModalXV] = useState(false);
  const [modalXV1, setModalXV1] = useState(false);

  const [modalXV2, setModalXV2] = useState(false);

  const [modalXV3, setModalXV3] = useState(false);
  const [modalXV4, setModalXV4] = useState(false);
  const [modalXV5, setModalXV5] = useState(false);


  const [id, setId] = useState("");
  const [newStatus, setActive] = useState(false);
  const itemsPerPage = 7; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });


  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList?.filter((item) => {
      // Filtering logic based on fromDate, toDate, status, and search
      const fromDateMatch =
        !filterCriteria.fromDate ||
        new Date(item.chooseDate) >= new Date(filterCriteria.fromDate);

      const toDateMatch =
        !filterCriteria.toDate ||
        new Date(item.chooseDate) <= new Date(filterCriteria.toDate);



      const searchMatch =
        !filterCriteria.search ||
        item.customerName.toLowerCase().includes(filterCriteria.search.toLowerCase());

      return fromDateMatch && toDateMatch && searchMatch;
    })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.chooseDate) - new Date(a.chooseDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };


  // Calculate start and end index for visible rows
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  // Populate visibleRows array with data for the current page
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const modalV = (index) => {
    setModal(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);

  }
  console.log(selectedGarage)
  const cancelV = () => {
    setModal(false)
  }
  const modalX = (index) => {
    setModalXV(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const modalX1 = (index) => {
    setModalXV1(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const modalX2 = (index) => {
    setModalXV2(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const modalX3 = (index) => {
    setModalXV3(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const modalX4 = (index) => {
    setModalXV4(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const modalX5 = (index) => {
    setModalXV5(true)
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  }
  const cancelx = () => {
    setModalXV(false)
    setModalXV1(false)

    setModalXV2(false)

    setModalXV3(false)

    setModalXV4(false)

    setModalXV5(false)


  }


  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const onChange = (page) => {
    setCurrentPage(page);
    fetchGarageList(page)
  };



  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item?.chooseDate).format("DD-MM-YYYY");
      return [
        index + 1,
        formattedDate || "",
        item.customerName || "",
        item.billNumber || "",
        item.vehicleNumber || "",
        item.totalRates || "",
        item.totalDiscount || "",
        item.totalTax || "",
        item.totalAmounts || "",
      ];
    });

    // Add header row
    formattedData.unshift(["S.No", "RegisterDate", "Bill Number", "Vehicle Number", "Subtotal", "Discount", "GST", "GrandTotal"]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };
  const handleCancel = () => {
    setModal(false);
  };

  const activeStatus = (item) => {
    setId(item.garageId);
    setModal(true);
  };

  function cancel(e) { }

  console.log(count)
  console.log(filteredGarageList)
  async function confirm1(id) {
    try {
      const response = await axios.delete(
        `${ApiDomain.Domain}/deleteRaating`,
        {
          headers: { Authorization: `Bearer ${token}` }, // Token should be passed in headers
          data: { ratingId: id }, // Include ratingId in the request body
        }
      );
  
      if (response.status === 200) {
        message.success(`Comment is Delete Successfully`);
        cancelx()
        fetchGarageList()
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  

  function cancel(e) {}


  return (
    <>

      {/* <Modal visible={modal} onCancel={cancelV}>
      <h1>ssdsds</h1>

    </Modal> */}


      <Spin spinning={load}>
        <RatingForm onSearch={handleFilterChange} Csv={handleExportExcel} param={param} fetchGarageListed={fetchGarageList} />
        {!load && (
          <div className="rating-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Garage Name</th>
                  <th className="th3">Garage Rating</th>
                  <th className="th4">Service Rating</th>


                  <th className="th6">1<StarFilled /></th>


                  <th className="th6">2<StarFilled /> </th>
                  <th className="th6">3 <StarFilled /> </th>
                  <th className="th6">4 <StarFilled /> </th>
                  <th className="th6">5 <StarFilled /> </th>
















                </tr>
              </thead>

              <tbody>
                {filteredGarageList?.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Rating List found.
                    </td>
                  </tr>
                ) : (
                  visibleRows?.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.chooseDate).format(
                      "DD-MM-YYYY"
                    );

                    console.log(visibleRows)


                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        <td>{item.garageName}</td>

                        <td>-</td>

                        <td onClick={(e) => modalX5(index, item.garageId)} style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }}>View</td>

                        <td onClick={(e) => modalX(index, item.garageId)} style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }}>View</td>

                        <td onClick={(e) => modalX1(index, item.garageId)} style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }}>View</td>
                        <td onClick={(e) => modalX2(index, item.garageId)} style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }}>View</td>

                        <td onClick={(e) => modalX3(index, item.garageId)} style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }}>View</td>

                        <td onClick={(e) => modalX4(index, item.garageId)} style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "bold" }}>View</td>



                        

















                      </tr>


                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modal && (
    <Modal footer={null} centered visible={modal} onCancel={cancelV}>
    <div className="">
                    <div className="add-rating-manage-modal-content-header">
                      <span
                        className="add-rating-manage-close-button"
                        onClick={cancelV}
                      >
                        &times;
                      </span>
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "white" }}>Customer Details</h3>
                      </div>
                    </div>
                    <div
                      className="add-rating-manage-modal-forms"
                      style={{ width: 500, display: "flex", flexDirection: "row", }}
                    >
                      <div style={{}}>
                        <Avatar size={100} shape="square" src={selectedGarage.customerImage} />


                      </div>




                      <div style={{ textAlign: "", padding: "0px 20px 0px 20px" }}>

                        <div>

                          <p style={{ paddingLeft: 27, fontWeight: "bold", marginBottom: "0.5em" }}>Booking Id:<span style={{ fontSize: 15, padding: 20 }}>{selectedGarage.bookingId}</span></p>

                          <p style={{ paddingLeft: 32, fontWeight: "bold", marginBottom: "0.5em" }}>Full Name:<span style={{ fontSize: 15, padding: 20 }} >{selectedGarage.customerName}</span></p>


                          <p style={{ paddingLeft: 0, fontWeight: "bold", marginBottom: "0.5em" }}>Phone Number: <span style={{ fontSize: 15, padding: 18 }}>{selectedGarage.phone ? selectedGarage.phone : "-"}</span></p>

                          <p style={{ paddingLeft: 60, fontWeight: "bold", marginBottom: "0.5em" }}>Email: <span style={{ fontSize: 15, padding: 17 }}>{selectedGarage.email ? selectedGarage.email : "-"}</span></p>


                        </div>
                      </div>

                    </div>
                  </div>
                </Modal>

              )}
            </div>
            <div>
              {modalXV && (
    <Modal footer={null} centered visible={modalXV} onCancel={cancelx}>
    <div className="rating-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <span
                        className="add-subscription-manage-close-button"
                        onClick={cancelx}
                      >
                        &times;
                      </span>
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "white" }}>Ratings Details</h3>
                      </div>
                    </div>


                    <table>
                      <thead>
                      <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Comment</th>
                          <th className="th5">Customer Name </th>
                          <th className="th5">Delete </th>













                        </tr>
                      </thead>

                      <tbody>
                        { selectedGarage && selectedGarage["1star"]?.length === 0 ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Rating Found.
                            </td>
                          </tr>
                        ) : (
                          selectedGarage && selectedGarage["1star"]?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(item?.startDate).format(
                              "DD-MM-YYYY"
                            );
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );

                            console.log(formattedDate2)
                            return (
                              <tr key={item.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.description}</td>
                                <td>{item.customerName}</td>







                                <td>      <div
                  className="edit-cell">
                  <Popconfirm
                  title={`Are you Delete this Comment`}
                  onConfirm={(e) => confirm1(item.ratingId)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No">
              
<DeleteOutlined />
</Popconfirm> 
               </div>
               </td>
















                              </tr>


                            );
                          })
                        )}
                      </tbody>
                    </table>

                  </div>
                </Modal>

              )}
              {modalXV1 && (
    <Modal footer={null} centered visible={modalXV1} onCancel={cancelV}>
    <div className="rating-manage-table">
                    <div className="add-rating-manage-modal-content-header">
                      <span
                        className="add-rating-manage-close-button"
                        onClick={cancelx}
                      >
                        &times;
                      </span>
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "white" }}>Ratings Details</h3>
                      </div>
                    </div>


                    <table>
                      <thead>
                        <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Comment</th>
                          <th className="th5">Customer Name </th>
                          <th className="th5">Delete </th>













                        </tr>
                      </thead>

                      <tbody>
                        { selectedGarage && selectedGarage["2star"]?.length === 0 ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Rating Found.
                            </td>
                          </tr>
                        ) : (
                           selectedGarage && selectedGarage["2star"]?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(item?.startDate).format(
                              "DD-MM-YYYY"
                            );
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );

                            console.log(formattedDate2)
                            return (
                              <tr key={item.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.description}</td>
                                <td>{item.customerName}</td>


               
          <td>      <div
                  className="edit-cell">
                  <Popconfirm
                  title={`Are you Delete this Comment`}
                  onConfirm={(e) => confirm1(item.ratingId)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No">
              
<DeleteOutlined />
</Popconfirm> 
               </div>
               </td>





















                              </tr>


                            );
                          })
                        )}
                      </tbody>
                    </table>

                  </div>
                </Modal>

              )}
              {modalXV2 && (
    <Modal footer={null} centered visible={modalXV2} onCancel={cancelV}>
    <div className="rating-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <span
                        className="add-subscription-manage-close-button"
                        onClick={cancelx}
                      >
                        &times;
                      </span>
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "white" }}>Ratings Details</h3>
                      </div>
                    </div>


                    <table>
                      <thead>
                      <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Comment</th>
                          <th className="th5">Customer Name </th>
                          <th className="th5">Delete </th>













                        </tr>
                      </thead>

                      <tbody>
                        { selectedGarage && selectedGarage["3star"]?.length === 0 ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Rating Found.
                            </td>
                          </tr>
                        ) : (
                           selectedGarage && selectedGarage["3star"]?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(item?.startDate).format(
                              "DD-MM-YYYY"
                            );
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );
console.log(item)
                            return (
                              <tr key={item.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.description}</td>
                                <td>{item.customerName}</td>





                                <td>      <div
                  className="edit-cell">
                  <Popconfirm
                  title={`Are you Delete this Comment`}
                  onConfirm={(e) => confirm1(item.ratingId)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No">
              
<DeleteOutlined />
</Popconfirm> 
               </div>
               </td>




















                              </tr>


                            );
                          })
                        )}
                      </tbody>
                    </table>

                  </div>
                </Modal>

              )}
              {modalXV3 && (
    <Modal footer={null} centered visible={modalXV3} onCancel={cancelV}>
    <div className="rating-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <span
                        className="add-subscription-manage-close-button"
                        onClick={cancelx}
                      >
                        &times;
                      </span>
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "white" }}>Ratings Details</h3>
                      </div>
                    </div>


                    <table>
                      <thead>
                      <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Comment</th>
                          <th className="th5">Customer Name </th>
                          <th className="th5">Delete </th>













                        </tr>
                      </thead>

                      <tbody>
                        { selectedGarage && selectedGarage["4star"]?.length === 0 ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Rating Found.
                            </td>
                          </tr>
                        ) : (
                         selectedGarage  &&  selectedGarage["4star"]?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(item?.startDate).format(
                              "DD-MM-YYYY"
                            );
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );

                            console.log(formattedDate2)
                            return (
                              <tr key={item.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.description}</td>
                                <td>{item.customerName}</td>


          
                                <td>      <div
                  className="edit-cell">
                  <Popconfirm
                  title={`Are you Delete this Comment`}
                  onConfirm={(e) => confirm1(item.ratingId)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No">
              
<DeleteOutlined />
</Popconfirm> 
               </div>
               </td>






















                              </tr>


                            );
                          })
                        )}
                      </tbody>
                    </table>

                  </div>
                </Modal>

              )}
              {modalXV4 && (
    <Modal footer={null} centered visible={modalXV4} onCancel={cancelV}>
    <div className="rating-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <span
                        className="add-subscription-manage-close-button"
                        onClick={cancelx}
                      >
                        &times;
                      </span>
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "white" }}>Ratings Details</h3>
                      </div>
                    </div>


                    <table>
                      <thead>
                      <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Comment</th>
                          <th className="th5">Customer Name </th>
                          <th className="th5">Delete </th>













                        </tr>
                      </thead>

                      <tbody>
                         { selectedGarage   && selectedGarage["5star"]?.length === 0 ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Rating Found.
                            </td>
                          </tr>
                        ) : (
                          selectedGarage && selectedGarage["5star"]?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(item?.startDate).format(
                              "DD-MM-YYYY"
                            );
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );

                            console.log(formattedDate2)
                            return (
                              <tr key={item.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.description}</td>
                                <td>{item.customerName}</td>






          
                                <td>      <div
                  className="edit-cell">
                  <Popconfirm
                  title={`Are you Delete this Comment`}
                  onConfirm={(e) => confirm1(item.ratingId)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No">
              
<DeleteOutlined />
</Popconfirm> 
               </div>
               </td>


















                              </tr>


                            );
                          })
                        )}
                      </tbody>
                    </table>

                  </div>
                </Modal>

              )}
              {modalXV5 && (
    <Modal footer={null} centered visible={modalXV5} onCancel={cancelV}>
    <div className="rating-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <span
                        className="add-subscription-manage-close-button"
                        onClick={cancelx}
                      >
                        &times;
                      </span>
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "white" }}>Ratings Details</h3>
                      </div>
                    </div>


                    <table>
                      <thead>
                      <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Comment</th>
                          <th className="th5">Customer Name </th>
                          <th className="th5">Delete </th>













                        </tr>
                      </thead>

                      <tbody>
                        {selectedGarage && selectedGarage?.serviceRatings?.length === 0 ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Rating Found.
                            </td>
                          </tr>
                        ) : (
                           selectedGarage && selectedGarage?.serviceRatings?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(item?.startDate).format(
                              "DD-MM-YYYY"
                            );
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );

                            console.log(formattedDate2)
                            return (
                              <tr key={item.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.serviceName}</td>
                                <td>{item.rating}</td>



          
                                <td>      <div
                  className="edit-cell">
                  <Popconfirm
                  title={`Are you Delete this Comment`}
                  onConfirm={(e) => confirm1(item.ratingId)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No">
              
<DeleteOutlined />
</Popconfirm> 
               </div>
               </td>





















                              </tr>


                            );
                          })
                        )}
                      </tbody>
                    </table>

                  </div>
                </Modal>

              )}

            </div>



          </div>

        )}
<Pagination
          onChange={onChange}
          simple
          total={filteredGarageList.length}
          pageSize={itemsPerPage}
          current={currentPage}
          style={{ marginTop: 10, float: "right" }}
        />

      </Spin>
    </>
  );
};

export default RatingTable;
