import React, { useState, useEffect } from "react";
import axios from "axios";
import { CloudUploadOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select, Upload, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import ApiDomain from "../../../api/ApiDomain";

const AddSubCategory = ({ handleCloseModal, fetchData,open }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const { Option } = Select;
  const [error, setError] = useState("");
  const [garageList, setGarageList] = useState([]);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const handleChangeCar = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoadingCar(true);

      // Get the image URL from the API response
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("https://api.vecrep.com/uploadImage", formData);

      // Assuming the API response contains the image URL
      const imageUrl = response.data.imagePath1;
      // Update the state with the image URL
      setImageUrlCar(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoadingCar(false);
    }
  };
  const token = localStorage.getItem("admin");

  const fetchGarageList = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/CategoryAdminList`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data);
      setGarageList(response?.data?.categories);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageList();
  }, []);

  const handleSubmit = async (values) => {
    console.log(values);
    // Handle form submission logic here
    console.log("Form submitted!");

    const token = localStorage.getItem("admin");
    const requestData = {
      categoryId: values.categoryName,
      serviceName: values.subCategoryName,
      serviceDescription: "",
      serviceImage: imageUrlCar,
    };
    try {
      const response = await fetch(`${ApiDomain.Domain}/addServiceNameAdmin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();

      if (response.ok) {
        fetchData();
        console.log("API response:", data);
        message.success(data.message);
        handleCloseModal();
      }
      else if (data.responseCode===400){
        message.warning(data.message)

      } 
      else {
        console.error("Error fetching data from the API");
        setError("Error fetching data from the API");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError(error);
    }
  };

  const handleCancel = () => {
    // Handle cancel logic here
    console.log("Form cancelled!");
    handleCloseModal();
  };

  const handleChange = (value) => {
    // You can perform additional actions based on the selected value
  };
  const handleVehicleChange = (value) => {
    // console.log(Selected: ${value});
    
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div
      style={{
        border: "1px solid #a4a4a4",
        borderRadius: "50%",
        padding: "1px 4px",
        backgroundColor: "lightgrey",
      }}>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </div>
  );
  
  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal} width={"auto"}>
      <div className=''>
        <div className=''>
          <div className='add-subcategory-modal-content-header'>
         
            <div>
              <h3 style={{color:"#b11226",fontWeight:"bold"}}>Add Service</h3>
            </div>
          </div>
          <div className='add-subcategory-modal-form'>
            <Form
              {...formItemLayout}
              form={form}
              name='register'
              style={{
                minWidth: 600,
              }}
              scrollToFirstError
              onFinish={handleSubmit}>
            
              <Form.Item
                name='categoryName'
                label='Category Name'
                placeholder='category Name'
                rules={[
                  {
                    required: true,
                    message: "Please Enter Category Name",
                  },
                ]}>
                <Select
                  showSearch
                  style={{ height: 40 }}
                  placeholder='Category Name'
                  optionFilterProp='children'
                  onChange={handleChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {garageList.map((garage) => (
                    <Option key={garage._id} value={garage._id}>
                      {garage.categoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='subCategoryName'
                label='Service Name'
                rules={[
                  {
                    required: true,
                    message: "Please Select Service Name",
                  },
                ]}>
                <Input
                  type='text'
                  value='Repairs'
                  placeholder='Service Name'
                  style={{ height: 40 }}
                />
              </Form.Item>
              <Form.Item
                name='carImage'
                label='Service Image : '
                rules={[
                  {
                    // required: true,
                    message: "Please upload Services Image",
                  },
                ]}>
   <div
                  name='categoryImage'
                  listType='picture-card'
                  className='avatar-uploader'
                  >
                  {imageUrlCar ? (
                    < div style={{display:"flex",gap:10}}>
                    <img
                      src={imageUrlCar}
                      alt='bike-avatar'
                      style={{ width: "20%" }}
                    />
                    <div class="upload">
                    <button type = "button" class = "btn-warning">
                    <CloudUploadOutlined /> Replace File
                      <input type="file" onChange={handleChangeCar}/>
                    </button>
              </div>
              </div>
                  ) : (
                    <>
                   

                    <div class="upload">
                    <button type = "button" class = "btn-warning">
                    <CloudUploadOutlined /> Upload File
                      <input type="file" onChange={handleChangeCar}/>
                    </button>
              </div>
              </>
                  )}
                </div>
              </Form.Item>
              <Form.Item name='whatsincluded' label=" What's Included ">
                <TextArea placeholder='Custom order' row={3} />

                <p>
                  <b>Note:</b> Use comma ( , ) for change the new line in
                  customer and garage app.
                </p>
              </Form.Item>
              <div className='add-subcategory-popup-footer'>
                <button
                  className='cancel-button'
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}>
                  Cancel
                </button>
                <button className='submit-button' htmlType='submit'>
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSubCategory;
