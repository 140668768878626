import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Main from "./main/index";

const accessToken = localStorage.getItem("admin");
const tab = localStorage.getItem("tab1");

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return accessToken ? (
            <Redirect to="/admin/dashboard" />
          ) : (
            <Redirect to="/login" />
          );
        }}
      />

      <Route
        path={tab ? `${match.url}admin` : `${match.url}vecrep`}
        render={(props) => <Main {...props} />}
      />
    </Switch>
  </div>
);

export default App;
