import React, { useEffect, useState } from "react";
import { message, Upload, Form, Col, Row, Button, Input, Select,Checkbox, Modal } from "antd";
import "./role.css";
import ApiDomain from "../../../api/ApiDomain";


const AddCategory = ({ handleCloseModal, fetchGarageList,open }) => {
  const [form] = Form.useForm();

const [data,setData]=useState([])
const[filterData,setFilterData]=useState([])
 const {Option}=Select;
 const [selectedSource, setSelectedSource] = useState(null);

  


    const handleSubmit = async (values) => {
      console.log(values);
      const featuresList = data
  .filter((item) => form.getFieldValue(item.featureName))
  .map((item) => ({
    featureId: item._id,
    privileges: item.privileges.map((privilege) => ({ privilegesType:"Read" })),
  }));

const payload = {
  roleName: values.roleName,
  source:values.source,
  features: featuresList,
};

console.log('API call payload:', payload);

    

    const token = localStorage.getItem("admin");
    
    try {
      const response = await fetch(
        `${ApiDomain.Domain}/adminRoles`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        fetchGarageList();
        console.log("API response:", data);
        message.success("Roles Added Successfully");
        handleCloseModal();
      } else {
        console.error("Error fetching data from the API");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleCancel = () => {
    // Handle cancel logic here
    console.log("Form cancelled!");
    handleCloseModal();
    
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };



  const Features = () => {
    const apiUrl = `${ApiDomain.Domain}/adminFeatures`;
    const authToken = localStorage.getItem("admin"); // Replace with your actual authentication token

    // Make a GET request using the Fetch API with the token in the headers
    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        // Other headers if needed
      },
    })
      .then((response) => {
        // Check if the request was successful (status code 200)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON in the response
        return response.json();
      })
      .then((data) => {
        // Handle the data from the API
        setData(data.data);
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        // setError(error.message);
      });
  };

  useEffect(() => {
    Features();
  }, []);


  const handleCheckboxChange = (featureName, featureId, checked) => {
    form.setFieldsValue({
      [featureName]: checked ? featureId : undefined,
    });
  };
  const handleVehicleChange = (value) => {
    console.log(`Selected: ${value}`);
    // You can perform additional actions based on the selected value
      setSelectedSource(value);
    
  };
  const filteredDataVecrep = data.filter(item => item.source === "vecrep");
  const filteredDataVrgarage = data?.filter(item => item.source === "vrgarage");


console.log(filteredDataVecrep)
console.log(filteredDataVrgarage)


  return (
    <Modal footer={null} centered visible={open} onCancel={handleCloseModal} width={650}>
      <div className="">
        <div className=''>
          <div className=''>
           
            <div>
              <h3 style={{ color: "#b11226",textAlign:"center",fontWeight:"bold" }}>Add Role</h3>
            </div>
          </div>
          <div className='add-role-modal-form'>
            <Form
              {...formItemLayout}
              form={form}
              name='register'
              style={{
                minWidth: 600,
              }}
              scrollToFirstError
              onFinish={handleSubmit}
              initialValues={true}
              >
                
                <div style={{display:"flex"}}>
              <Form.Item
                name='roleName'
                label='Role Name'
                rules={[
                  {
                    message: "Please Enter Role Name",
                  },
                ]}>
                <Input  />

              </Form.Item>

              <Form.Item
  name='source'
  label='Portal'
  rules={[
    {
      message: "Please Enter Role Name",
    },
  ]}
>
{(selectedSource === 'vrgarage' || selectedSource === 'vecrep') ? (
  <Select
    showSearch
    style={{ height: 40, width: 150 }}
    placeholder="Select Type"
    optionFilterProp="children"
    onChange={handleVehicleChange}
    defaultValue={selectedSource} // Set the default value to the selected source
    disabled

  >
    <Option value="vrgarage">Vr Garage</Option>
    <Option value="vecrep">Vecrep.com</Option>
  </Select>
) : (
  <Select
    showSearch
    style={{ height: 40, width: 150 }}
    placeholder="Select Type"
    optionFilterProp="children"
    onChange={handleVehicleChange}
    defaultValue={selectedSource} // Set the default value to the selected source
  >
    <Option value="vrgarage">Vr Garage</Option>
    <Option value="vecrep">Vecrep.com</Option>
  </Select>
)}

</Form.Item>

              </div>
              <h2 style={{textAlign:"center",color:"black",fontWeight:"bold",padding:20}}>Select Features</h2>
              <Row style={{width:750}}>

                
              {selectedSource === 'vrgarage' && (
  filteredDataVrgarage.map((item, index) => (
    <Col key={index} span={8}>
      <Form.Item
        name={item.featureName}
        valuePropName="checked"
      >
        <Checkbox
          onChange={(e) =>
            handleCheckboxChange(item.featureName, item._id, e.target.checked)
          }
        >
          {item.featureName}
        </Checkbox>
      </Form.Item>
    </Col>
  ))
) 
        } 

{selectedSource === 'vecrep' && (
 
 filteredDataVecrep.map((item, index) => (
    <Col key={index} span={8}>
      <Form.Item
        name={item.featureName}
        valuePropName="checked"
      >
        <Checkbox
          onChange={(e) =>
            handleCheckboxChange(item.featureName, item._id, e.target.checked)
          }
        >
          {item.featureName}
        </Checkbox>
      </Form.Item>
    </Col>
  ))

       ) }

      </Row>

  


             
              <div className='add-role-popup-footer'>
                <button
                  className='cancel-button'
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}>
                  Cancel
                </button>
                <button className='submit-button' htmlType='submit'>
                  Submit
                </button>
              </div>
            </Form>
          </div>

          {/* <Upload
            name='avatar'
            listType='picture-card'
            className='avatar-uploader'
            showUploadList={false}
            action='https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188'
            beforeUpload={beforeUpload}
            onChange={handleChange}>
            {imageUrl ? (
              <img
                src={imageUrl}
                alt='avatar'
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload> */}
        </div>
      </div>
    </Modal>
  );
};

export default AddCategory;
