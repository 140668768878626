import React, { useState } from "react";
import { Form, Input, Select, Checkbox, Radio } from "antd";
import "./Notifications.css";
import TextArea from "antd/lib/input/TextArea";

const SendNotification = ({ handleCloseModal, fetchData }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState("customer");

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 20,
      },
      sm: {
        span: 12,
      },
    },
  };

  const handleSubmit = async (values) => {
    console.log(values);
    // Handle form submission logic here
    console.log("Form submitted!");
  };

  const handleCancel = () => {
    // Handle cancel logic here
    console.log("Form cancelled!");
    handleCloseModal();
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onRadioChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const { Option } = Select;

  const options = Array.from({ length: 20 }, (_, index) => ({
    name: `Person ${index + 1}`,
    email: `person${index + 1}@example.com`,
  }));

  return (
    <>
      <div className='add-notifications-modal-overlay'>
        <div className='add-notifications-modal-content'>
          <div className='add-notifications-modal-content-header'>
            <span
              className='add-notifications-close-button'
              onClick={handleCloseModal}>
              &times;
            </span>
            <div>
              <h3 styele={{ color: "white" }}>Send Notification</h3>
            </div>
          </div>
          <div className='add-notifications-modal-form'>
            <Form
              {...formItemLayout}
              form={form}
              name='register'
              style={{
                minWidth: 800,
              }}
              onFinish={handleSubmit}>
              <Form.Item
                name='notificationTitle'
                label='Notification Title'
                rules={[
                  {
                    required: true,
                    message: "Please Enter Notification Title",
                  },
                ]}>
                <Input
                  type='text'
                  placeholder='Enter Notification Title'
                  style={{ height: 40, width: "390px" }}
                />
              </Form.Item>
              <Form.Item
                name='notificationDesc'
                label='Notification Description'
                rules={[
                  {
                    required: false,
                    message: "Please Enter Notification Description",
                  },
                ]}>
                <TextArea
                  rows={4}
                  placeholder='Enter Notification Description'
                />
              </Form.Item>
              <Form.Item
                name='Sendto'
                label='Send To'
                rules={[
                  {
                    required: false,
                    message: "Please Select option",
                  },
                ]}>
                <Radio.Group onChange={onRadioChange} value={value}>
                  <div
                  //   style={{ display: "flex" }}
                  >
                    <Radio value='customer'>Message Send to Customer</Radio>
                    <Radio value='Garage'>Message Send to Garage</Radio>
                    <Radio value='Multiple'>Message Send to Multiple</Radio>
                  </div>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='NotificationTo'
                label='Send Notification To'
                rules={[
                  {
                    required: true,
                    message: "Please Enter contact number",
                  },
                ]}>
                <Select
                  style={{ width: 390 }}
                  dropdownMatchSelectWidth={false}
                  className='person-select'
                  placeholder='Customer Name'>
                  {options.map((person, index) => (
                    <Option
                      key={index}
                      value={`${person.name} | ${person.email}`}>
                      {`${person.name} | ${person.email}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className='add-notifications-popup-footer'>
                <button
                  className='cancel-button'
                  onClick={handleCancel}
                  style={{ marginRight: 8 }}>
                  Cancel
                </button>
                <button className='submit-button' htmlType='submit'>
                  Send
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendNotification;
