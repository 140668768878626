import React, { useEffect, useState } from "react";
import "./booking.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import moment from "moment";
import {
  Avatar,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from "xlsx";
import BookingCustomerForm from "./BookingCustomerForm";

const BookingCustomerTable = ({ garageList, load, fetchGarageList }) => {
  console.log(garageList)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [active, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState("");
  const [data, setData] = useState([]);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [modalXV, setModalXV] = useState(false);
  const [modalXV1, setModalXV1] = useState(false);
  const [form] = Form.useForm();
  const [category, setCategory] = useState([]);
  const [services, setServices] = useState([]);
  const { Option } = Select;
  const [initialSelectedServiceIds, setInitialSelectedServiceIds] = useState(
    []
  );
  const [initialCategoryNames, setInitialCategoryNames] = useState([]);

  const [garageIdData, setGarageIdData] = useState("");
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });

  const fetchServiceLists = async (id) => {
    console.log(id);
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/category_service_list`,
        {
          categoryId: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data?.data);
      setServices(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchServiceLists();
  }, []);

  const ServicesHit = (id) => {
    console.log("ddd");
    fetchServiceLists(id);
  };
  console.log(selectedGarage);
  useEffect(() => {
    // Assuming selectedGarage is populated with data containing categories
    const categories = selectedGarage?.categories?.map(
      (category) => category.services
    );
    setInitialCategoryNames(categories);
  }, [selectedGarage]);

  console.log(initialCategoryNames);

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList?.filter((item) => {
        const formattedDate = moment(item?.date).format(
          "DD-MM-YYYY"
        );

        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          new Date(item.formattedDate) >= new Date(filterCriteria.fromDate);

        const toDateMatch =
          !filterCriteria.toDate ||
          new Date(item.formattedDate) <= new Date(filterCriteria.toDate);

        const searchMatch =
          !filterCriteria.search ||
          item.customerName
            .toLowerCase()
            .includes(filterCriteria.search.toLowerCase());
        setDate(formattedDate);

        return fromDateMatch && toDateMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        // For example, sorting by createdAt in descending order
        return new Date(b.date) - new Date(a.date);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };
  useEffect(() => {
    if (selectedGarage) {
      fetchServiceLists(selectedGarage?.categoryId);
    }
  }, [selectedGarage]);

  const handleChange = (value) => {
    const selectedCategory = category.categories.find(
      (cat) => cat.categoryId === value
    );
    setSelectedGarage(selectedCategory);
  };

  const handleVehicleChange = (value) => {
    // console.log(Selected: ${value});
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList?.slice(startIndex, endIndex);
  console.log(visibleRows);
  const handleOpenModal = (index) => {
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  };

  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
    form.resetFields(); // Reset form fields to their initial values

    setModalXV1(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const modalX = (index, id) => {
    setModalXV(true);
    const selectedGarage = visibleRows[index]; // Get the selected garage

    setSelectedGarage(selectedGarage); // Updated to use visibleRows

    setGarageIdData(id);
    fetchGarageListSingle(id);
  };

  const modalX1 = (index, id) => {
    setModalXV1(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage.inspectionSummary);
    console.log(selectedGarage)
    setGarageIdData(id);
    // fetchGarageLists(id);
  };
  // Update initial selected service IDs when selectedGarage changes
  // useEffect(() => {
  //   if (selectedGarage) {
  //     const services = selectedGarage?.categories.map(category =>
  //       category?.services.map(service => service?.serviceName)
  //     ).flat();
  //     setInitialSelectedServiceIds(services);
  //   }
  // }, [selectedGarage]);
  const fact = () => {
    const services = selectedGarage.categories
      ?.map((category) =>
        category.services.map((service) => service.serviceName)
      )
      .flat();
    // Set initial selected service IDs
    setInitialSelectedServiceIds(services);
  };
  console.log(initialSelectedServiceIds);
  const cancelx = () => {
    setModalXV(false);
    setModalXV1(false);
  };

  async function handleSubmit2(values) {
    console.log(values);

    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/manageGarageProfile`,
        { garageId: id, ...values, status: active },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response?.data?.responseCode === 200) {
        fetchGarageList();
        message.success("Status Updated Successfully");
        handleCancel();
      }

      // Update the garage list after successful approval
    } catch (error) {
      console.log(error.message);
    }
  }

  const fetchGarageListSingle = async (id) => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/getListOfBankDetails`,
        {
          garageId: id ? id : "",

          // Use the current value of the 'page' state
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.status === 200) {
        console.log(response.data);

        setData(response.data.data);

        // setCount(response.data.totalCount);
        console.log(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleCancel = () => {
    setModalXV1(false);
    setModal(false);
    form.resetFields(); // Reset form fields to their initial values
  };
  console.log(garageList?.length);

  const activeStatus = (item) => {
    setId(item?.garageId);
    setActive(!item?.status);
    setModal(true);
  };
  const handleExportExcel = () => {
    // Define the table headings
    const tableHeadings = [
      "S.No",
      "Booking Date",
      "Booking Time",
      "Booking ID",
      "Order Placed at",
      "Customer Name",
      "Vehicle Number",
      "Modal Name",
      "Variant",
      "Fuel Type",
      "Year",
      "Garage Name",
      "City",
      "Booking Status",
      "Insepection Summary",
      "Invoice",
      "Rating",
      "Order Status",
    ];
  
    // Create formatted data
    const formattedData = [tableHeadings]; // Initialize with table headings
  
    // Add data rows
    filteredGarageList.forEach((item, index) => {
      const formattedDate = moment(item?.bookingDate).format("DD-MM-YYYY");
      formattedData.push([
        index + 1,
        formattedDate || "-",
        item?.time || "-",
        item?.bookingId || "-",
        item?.orderPlacedAt || "-",
        item?.customerName || "-",
        item?.vehicleNumber || "-",
        item?.modelName || "-",
        item?.varjant || "-",
        item?.fuelType || "-",
        item?.year || "-",
        item?.garageName || "-",
        item?.city || "-",
        item.bookingStatus || "-",
        item?.rating || "-",
        item.orderStatus ? "Active" : "InActive",
      ]);
    });
  
    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);
  
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
  };
  

  console.log(date);
  console.log(garageIdData);

  const handleSubmit1 = async (values) => {
    console.log(garageIdData);

    const d = {
      garageId: garageIdData,
      categoryId: values.categoryId,
      services: values.serviceId?.map((serviceId) => ({
        serviceId: serviceId,
        vehicleType: 1, // Assuming you have a function to get vehicleType based on serviceName
        quantity: 1,
        rate: 0,
      })),
    };
    console.log(d);
    // Extract only the date part
    // const startDate = values.startDate.format("YYYY-MM-DD");
    // const expiryDate = values.expiryDate.format("YYYY-MM-DD");

    try {
      const response = await fetch(
        `${ApiDomain.Domain}/add_category_services`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
          body: JSON.stringify({
            garageId: garageIdData,
            categoryId: values.categoryId,
            services: values.serviceId?.map((serviceId) => ({
              serviceId: serviceId,
              vehicleType: 1, // Assuming you have a function to get vehicleType based on serviceName
              quantity: 1,
              rate: 0,
            })),

            // startDate,
            // expiryDate,
          }),
        }
      );

      if (response.ok) {
        // Handle successful API response
        console.log("API call successful");
        fetchGarageList();
        message.success("Sevices added successfully");
        handleCloseModal();
      } else {
        // Handle API error
        console.error("API call failed");
        message.error("Error adding  Service Add");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
      message.error("Error addingService add");
    } finally {
    }
  };

  const a = selectedGarage?.categories?.map((category) =>
    category?.services?.map((service) => service.serviceName)
  );

  console.log(a?.flat());
  const handleClick = (url) => {
    // Open the PDF file in a new tab
    window.open(url, '_blank');
  };
  return (
    <>
      {modal && (
        <div className="add-booking-manage-modal-overlay">
          <div className="add-booking-manage-modal-contents">
            <div className="add-booking-manage-modal-content-header">
              <div>
                <h3 style={{ color: "white" }}>Garage Status</h3>
              </div>
            </div>
            <div className="add-booking-modal-form">
              <Spin spinning={load}>
                <Form
                  // form={form}
                  layout="vertical"
                  style={{ minWidth: "600px" }}
                  onFinish={handleSubmit2}
                >
                  <div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "80%", marginTop: 10 }}>
                        <Col xl={24} lg={24}>
                          <Form.Item label="Reason : " name="reason">
                            <Input
                              placeholder="Enter Your Reason Name"
                              style={{ height: "50px" }}
                            />
                          </Form.Item>
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div className="add-booking-manage-popup-footer">
                    <button
                      className="cancel-button"
                      onClick={handleCancel}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </button>

                    <button className="submit-button" htmlType="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
      )}

      <Spin spinning={load}>
        <BookingCustomerForm
          onSearch={handleFilterChange}
          ExportCsv={handleExportExcel}
        />
        {!load && (
          <div className="booking-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Booking Date</th>
                  <th className="th3">Booking Time</th>
                  <th className="th3">Booking ID</th>

                  <th className="th4">Order Placed at</th>
                  <th className="th5">Customer Name</th>
                  <th className="th6">VehicleNumber</th>
                  <th className="th7">Modal Name</th>
                  <th className="th9">Variant</th>
                  <th className="th12">Fuel Type</th>
                  <th className="th13">Year</th>
                  <th className="th13">Garage Name</th>
                  <th className="th13">City</th>
                  <th className="th13">Booking Status</th>
                  <th className="th13">Insepection summary</th>

                  <th className="th13">Invoice</th>

                  <th className="th13">Rating</th>
                  <th className="th13">Order Status</th>


                </tr>
              </thead>

              <tbody>
                {filteredGarageList?.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No Booking Management found.
                    </td>
                  </tr>
                ) : (
                  visibleRows?.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.bookingDate).format(
                      "DD-MM-YYYY"
                    );

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                       
                        <td>{formattedDate}</td>
                        <td>{item?.time || "-"}</td>
                        <td>{item?.bookingId || "-"}</td>

                        <td>{item?.orderPlacedAt || "-"}</td>
                        <td>{item?.customerName || "-"}</td>

                        <td>{item?.vehicleNumber || "-"}</td>
                        <td>{item?.modelName || "-"}</td>
                        <td>{item?.varjant || "-"}</td>
                        <td>{item?.fuelType || "-"}</td>
                        <td>{item?.year || "-"}</td>
                        <td>{item?.garageName || "-"}</td>
                        <td>{item?.city || "-"}</td>
                        <td>
                          <span
                            className={`badge badge-${
                              item.bookingStatus==="completed" && "success" || item.bookingStatus==="cancel"&& "danger" || item.bookingStatus==="inProgress" && "inprogress" ||item.bookingStatus==="readyForDelivery" && "readyForDelivery"	
                            }`}
                            // onClick={() => activeStatus(item)}
                          >
                            {item.bookingStatus }
                          </span>
                        </td>                        







                        <td onClick={(e) => modalX1(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                        <td onClick={(e) => handleClick(item.invoice)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                        <td>{item?.rating}</td>
                        <td>
                          <span
                            className={`badge badge-${item.orderStatus?"success":"danger"}`}
                            // onClick={() => activeStatus(item)}
                          >
                            {item.orderStatus?"Active":"InActive"}
                          </span>
                        </td>

                      

                      
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div>
              {modalXV && (
                <div className="add-subscription-manage-modal-overlay">
                  <div className="add-subscription-manage-modal-contents">
                    <div className="add-subscription-manage-modal-content-header">
                      <span
                        className="add-subscription-manage-close-button"
                        onClick={cancelx}
                      >
                        &times;
                      </span>
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "white" }}>
                          Bank Details
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Holder Name</th>
                          <th className="th4">Bank Name</th>
                          <th className="th4">Branch</th>

                          <th className="th5">IFSC Code</th>
                          <th className="th5">AC Number</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data?.findBankData?.length != "0" ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Bank Details History.
                            </td>
                          </tr>
                        ) : (
                          data?.findBankData?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(
                              item?.startDate
                            ).format("DD-MM-YYYY");
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );

                            console.log(formattedDate2);
                            return (
                              <tr key={item.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.nameAsPerBankAccount}</td>
                                <td>{item.bankName}</td>
                                <td>{item.branch}</td>
                                <td>{item.ifscCode}</td>

                                <td>{item.accountNumber}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            <div>
              {modalXV1 && (
    <Modal footer={null} centered visible={modalXV1} onCancel={cancelx} width={"auto"}>
    <div className="booking-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Inspection Summary
                        </h3>
                      </div>
                    </div>

                    <table>
  <thead>
    <tr>
      <th className="th1">S.No</th>
      <th className="th4">Vehicle Condition</th>
      <th className="th4">Image</th>
      <th className="th4">Mechanic</th>



    </tr>
  </thead>
  <tbody>
    {selectedGarage?.inspectionData?.length !== 0 ? (
      selectedGarage?.inspectionData?.map((item, index) => {
        console.log("Item:", item); // Debugging
        return (
          <tr key={item._id}>
            <td>{index + 1}</td>
            <td>{item.vechileCondition}</td>
            <td>{item.image}</td>
            <td>{selectedGarage.mechanic}</td>


          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5" style={{ textAlign: "center", color: "black" }}>
          No Inspection History.
        </td>
      </tr>
    )}
  </tbody>
</table>
                  </div>
                </Modal>
              )}
            </div>

        

          
          </div>
        )}
        <Pagination
          simple
          onChange={onChange}
          current={currentPage}
          total={filteredGarageList?.length}
          pageSize={itemsPerPage}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default BookingCustomerTable;
